import { useCallback, useMemo } from "react";
import { Box, Button, DataTable, DataTableProps, FormField, Text } from "grommet";
import { LinkNext } from "grommet-icons";
import { useHistory, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { BillingState } from "api";
import { MainContent, MonthYearPicker, Spinner, TableSortingHeader } from "components";
import { billingStatesTranslations, getBillingStateColor, projectTypeToHumanReadable } from "helpers";
import { BillingsProjectDetailParams, getRoute } from "routes";
import { dataTableBorder } from "styles";
import { toDateStr } from "utils";
import { ProjectBillingDetail } from "./ProjectBillingDetail";
import { useBillings } from "./useBillings";

export const Billings = () => {
  useTitle("Fakturace");

  const { data, state, handlers } = useBillings();

  const history = useHistory();
  const { project: projectId } = useParams<BillingsProjectDetailParams>();

  const isFormVisible = !!projectId;
  const activeProjectId = projectId;

  const isRowActive = useCallback((id) => activeProjectId === id && isFormVisible, [activeProjectId, isFormVisible]);

  const tableColumns = useMemo<DataTableProps["columns"]>(() => {
    return [
      {
        property: "responsiblePerson",
        header: (
          <TableSortingHeader
            text={"Odpovědná osoba"}
            registerProperty={"responsiblePerson"}
            handler={handlers.sortHandler}
          />
        ),
        align: "start",
        render: (row) => (
          <Text
            size={isFormVisible ? "xsmall" : "small"}
            style={{ whiteSpace: "nowrap" }}
            weight={isRowActive(row.id) ? "bold" : "normal"}
            color={getBillingStateColor(row.billing?.state, "normal")}
          >
            {row.billing?.responsiblePerson ?? "-"}
          </Text>
        ),
      },
      {
        property: "client",
        header: <TableSortingHeader text={"Klient"} registerProperty={"client"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text
            size={isFormVisible ? "xsmall" : "small"}
            style={{ whiteSpace: "nowrap" }}
            weight={isRowActive(row.id) ? "bold" : "normal"}
            color={getBillingStateColor(row.billing?.state, "normal")}
          >
            {row.client ? `${row.client.name}` : "-"}
          </Text>
        ),
      },
      {
        property: "type",
        header: <TableSortingHeader text={"Typ Projektu"} registerProperty={"type"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text
            size={isFormVisible ? "xsmall" : "small"}
            weight={isRowActive(row.id) ? "bold" : "normal"}
            color={getBillingStateColor(row.billing?.state, "normal")}
          >
            {row.type ? `${projectTypeToHumanReadable(row.type)}` : "-"}
          </Text>
        ),
      },
      {
        property: "code",
        size: "xsmall",
        header: <TableSortingHeader text={"Kód"} registerProperty={"code"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text
            size={isFormVisible ? "xsmall" : "small"}
            style={{ whiteSpace: "nowrap" }}
            weight={isRowActive(row.id) ? "bold" : "normal"}
            color={getBillingStateColor(row.billing?.state, "normal")}
          >
            {row.code}
          </Text>
        ),
      },
      {
        property: "name",
        header: <TableSortingHeader text={"Projekt"} registerProperty={"name"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text
            size={isFormVisible ? "xsmall" : "small"}
            weight={isRowActive(row.id) ? "bold" : "normal"}
            color={getBillingStateColor(row.billing?.state, "normal")}
          >
            {row.name}
          </Text>
        ),
      },
      {
        property: "billing",
        header: (
          <TableSortingHeader text={"Stav Fakturace"} registerProperty={"billing"} handler={handlers.sortHandler} />
        ),
        align: "start",
        render: (row) => (
          <Text
            size={isFormVisible ? "xsmall" : "small"}
            style={{ whiteSpace: "nowrap" }}
            weight={isRowActive(row.id) ? "bold" : "normal"}
            color={getBillingStateColor(row.billing?.state, "normal")}
          >
            {row.billing?.state ? billingStatesTranslations[row.billing.state as BillingState] : "-"}
          </Text>
        ),
      },
      {
        property: "actionEdit",
        header: "",
        align: "center",
        render: (row) => (
          <Button
            icon={<LinkNext size={"20px"} color={isRowActive(row.id) ? "text" : undefined} />}
            onClick={() => {
              history.push(getRoute.billingsProjectDetail({ project: row.id }, { period: state.period }));
            }}
          />
        ),
      },
    ];
  }, [handlers.sortHandler, history, isFormVisible, isRowActive, state.period]);

  return (
    <MainContent>
      <Box direction={"row-responsive"} gap={"small"} fill>
        <Box width={"full"} height={"100%"} overflow={"auto"}>
          {state.loading ? (
            <Spinner />
          ) : (
            <Box>
              <Box direction={"row"} align={"center"} justify={"between"}>
                <Text size={"large"}>Nemají zkontrolovaný Harvest</Text>
                <FormField>
                  <MonthYearPicker
                    defaultPeriod={state.period}
                    onChange={(period) => {
                      handlers.setPeriod(toDateStr(period));
                      history.push(
                        getRoute.billings({
                          period: toDateStr(period),
                        })
                      );
                    }}
                  />
                </FormField>
              </Box>
              <Box flex>
                {data.blockingProjects.length > 0 ? (
                  <DataTable
                    columns={tableColumns}
                    primaryKey={"code"}
                    data={[...data.blockingProjects]}
                    border={dataTableBorder}
                    step={99999}
                    pad={"1px"}
                  />
                ) : (
                  <Text>Žádné Projekty</Text>
                )}

                <Text size={"large"} margin={{ vertical: "medium" }}>
                  K Fakturaci
                </Text>
                {data.unresolvedProjects.length > 0 ? (
                  <DataTable
                    columns={tableColumns}
                    primaryKey={"code"}
                    data={[...data.unresolvedProjects]}
                    border={dataTableBorder}
                    step={99999}
                    pad={"1px"}
                  />
                ) : (
                  <Text>Žádné Projekty</Text>
                )}

                <Text size={"large"} margin={{ vertical: "medium" }}>
                  Hotové
                </Text>
                {data.resolvedProjects.length > 0 ? (
                  <DataTable
                    columns={tableColumns}
                    primaryKey={"code"}
                    data={[...data.resolvedProjects]}
                    border={dataTableBorder}
                    step={99999}
                    pad={"1px"}
                  />
                ) : (
                  <Text>Žádné Projekty</Text>
                )}
              </Box>
            </Box>
          )}
        </Box>
        {isFormVisible && !!activeProjectId && (
          <Box width={"full"} pad={{ horizontal: "medium" }} height={"100%"} overflow={"auto"}>
            <ProjectBillingDetail />
          </Box>
        )}
      </Box>
    </MainContent>
  );
};
