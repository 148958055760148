import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import { useLocalStorage } from "react-use";
import * as storage from "api/storage";
import { routes } from "routes";
import { useTokenAuthMutation } from "./generated";

export const useAuth = () => {
  const apolloClient = useApolloClient();
  const [mutate, { loading }] = useTokenAuthMutation();

  const [, setAuthToken] = useLocalStorage<string>(storage.LS_AUTH_TOKEN, "");

  const login = useCallback(
    async (username: string, password: string, onSuccess: () => any, onError: (e: ApolloError | any) => any) => {
      try {
        const { data } = await mutate({ variables: { input: { username, password } } });

        if (!data?.tokenAuth?.token) return;

        setAuthToken(data?.tokenAuth.token);
        onSuccess();
      } catch (e) {
        onError(e);
      }
    },
    [mutate, setAuthToken]
  );

  const logout = useCallback(async () => {
    await apolloClient.resetStore();

    storage.clearAuthToken();

    window.location.replace(routes.login);
  }, [apolloClient]);

  const isLoggedIn = useCallback((): boolean => !!storage.getAuthToken(), []);

  return { loading, login, logout, isLoggedIn };
};
