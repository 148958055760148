import * as React from "react";
import { Box, Collapsible } from "grommet";
import { Menu as IconMenu } from "grommet-icons/icons";
import { useToggle } from "react-use";

interface Props extends React.PropsWithChildren<any> {
  defaultState: "open" | "close";
  footer?: JSX.Element | null;
}

export const Sidebar = ({ children, defaultState, footer }: Props) => {
  const [showSidebar, handleClickSidebarToggle] = useToggle(defaultState === "open");

  return (
    <Box fill={"vertical"} flex={{ shrink: 0 }} background={"light-1"}>
      <Box align={"start"} pad={"small"}>
        <Box onClick={handleClickSidebarToggle}>
          <IconMenu />
        </Box>
      </Box>
      <Box flex direction={"row"}>
        <Collapsible direction="horizontal" open={showSidebar}>
          <Box width={{ min: "300px", max: "100%" }} pad={{ vertical: "small" }} overflow={{ vertical: "auto" }}>
            <Box flex direction="column">
              {children}
            </Box>
          </Box>

          <Box
            direction="column"
            flex={{ shrink: 0 }}
            margin={{ top: "auto" }}
            pad={{ vertical: "small" }}
            fill={"horizontal"}
          >
            {footer}
          </Box>
        </Collapsible>
      </Box>
    </Box>
  );
};
