import { Box, Diagram, Stack } from "grommet";
import { BillingStateChange, BillingTimelineEvent } from "api";
import { BillingStateInfoRow } from "components";
import { getBillingStateColor } from "helpers";

type Props = {
  stateChanges: Partial<BillingStateChange | BillingTimelineEvent>[];
  connectionPrefix?: string; // prefix for ids for diagram connections purposes
};

export const BillingStateChangesDiagram = ({ stateChanges, connectionPrefix = "billing-state" }: Props) => {
  return stateChanges ? (
    <Stack>
      {/* I can't get this working. Spent so much time... and gave up. */}
      <Box direction={"column"}>
        {stateChanges.map((stateChange, index) => (
          <BillingStateInfoRow
            key={stateChange.id}
            id={`${connectionPrefix}-${stateChanges.length - index}`}
            billingState={stateChange.toState}
            userName={stateChange.user ? `${stateChange.user?.firstName} ${stateChange.user?.lastName}` : "ERP"}
            datetimeString={`${new Date(stateChange.createdAt).toLocaleTimeString()} / ${new Date(
              stateChange.createdAt
            ).toLocaleDateString()}`}
            note={stateChange.note ?? ""}
          />
        ))}
      </Box>
      <Diagram
        connections={stateChanges.map((_, index) => ({
          fromTarget: `${connectionPrefix}-${stateChanges.length - index}`,
          toTarget: `${connectionPrefix}-${stateChanges.length - index + 1}`,
          thickness: "xsmall",
          color: getBillingStateColor(_.toState),
          anchor: "vertical",
        }))}
      />
    </Stack>
  ) : null;
};
