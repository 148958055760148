import { Box, Text } from "grommet";
import { BillingState, BillingTimelineEventState } from "api";
import { billingStatesTranslations, getBillingStateColor } from "helpers";

type BillingStateInfoRowProps = {
  id: string;
  billingState?: BillingState | BillingTimelineEventState;
  userName: string;
  datetimeString: string;
  note: string;
};

export const BillingStateInfoRow = (props: BillingStateInfoRowProps) => {
  return (
    <Box direction={"row"} align={"baseline"} pad={"small"} gap={"medium"} fill={"horizontal"}>
      <Box
        width={"xxsmall"}
        height={"xxsmall"}
        background={"white"}
        border={{
          color: getBillingStateColor(props.billingState),
          size: "medium",
        }}
        round={"full"}
        id={props.id}
        style={{ position: "relative", top: 20 }}
      />
      <Box width={"small"}>
        {props.billingState ? <Text weight={"bold"}>{billingStatesTranslations[props.billingState]}</Text> : " - "}
      </Box>
      <Box flex={true} direction={"column"} gap={"small"}>
        <Box direction={"row"} justify={"between"} gap={"small"}>
          <Text weight={"bold"}>{props.userName}</Text>
          <Text size={"small"}>{props.datetimeString}</Text>
        </Box>
        {props.note}
      </Box>
    </Box>
  );
};
