import { useCallback, useMemo, useState } from "react";
import { Box, Button, FormField, Select, Text, TextArea } from "grommet";
import { LinkPrevious } from "grommet-icons";
import { BillingState, ProjectType, Triggers } from "api";
import { BillingStateChangesDiagram, Link, Spinner } from "components";
import { ProjectBillingReport } from "containers";
import { billingTriggersTranslations } from "helpers";
import { getRoute } from "routes";
import { useProjectBillingDetail } from "./useProjectBillingDetail";

export const ProjectBillingDetail = () => {
  const {
    data: {
      project,
      projectBillingStatesChanges,
      projectBillingTimelineEvents,
      projectPreviousBillingStatesChanges,
      billingTriggers,
    },
    state,
    handlers,
  } = useProjectBillingDetail();

  const [billingTrigger, setBillingTrigger] = useState<Triggers>();
  const [note, setNote] = useState<string>();
  const [reportViewActive, setReportViewActive] = useState(false);

  const shouldShowBillingReportLink = useMemo(() => {
    if (!projectBillingStatesChanges) {
      return false;
    }

    return !!projectBillingStatesChanges.find((stateChange) => stateChange.toState === BillingState.ApAccepted);
  }, [projectBillingStatesChanges]);

  const handleOnNewBillingState = useCallback(async () => {
    if (!billingTrigger) {
      return null;
    }
    await handlers.addBillingEntry(billingTrigger, note);
    setNote("");

    if (reportViewActive) {
      hideReportView();
    }
  }, [billingTrigger, handlers, note, reportViewActive]);

  const showReportView = () => {
    setReportViewActive(true);
  };

  const hideReportView = () => {
    setReportViewActive(false);
  };

  const handleSaveNewBillingState = useCallback(() => {
    // report view should appear only for TM and FPCHR projects
    const shouldShowReportView =
      billingTrigger === Triggers.ApAccepted &&
      (project?.type === ProjectType.TimeAndMaterial || project?.type === ProjectType.FixedPriceChangeRequest);

    return shouldShowReportView ? showReportView() : handleOnNewBillingState();
  }, [billingTrigger, handleOnNewBillingState, project]);

  return (
    <Box>
      {state.loading ? (
        <Spinner />
      ) : project ? (
        <Box>
          <Box direction={"row"} align={"center"}>
            <Link icon={<LinkPrevious />} route={getRoute.billings()} />
            <Text size={"xlarge"}>{`${project.client?.name ?? "-"} / ${project.code ?? "-"} / ${project.name}`}</Text>
          </Box>

          {/* Inputs */}
          {billingTriggers && billingTriggers?.length > 0 ? (
            <Box direction={"row"} margin={{ vertical: "small" }} gap={"small"}>
              <FormField width={"medium"}>
                <Select
                  placeholder={"Vyber stav"}
                  options={billingTriggers as Triggers[]}
                  value={billingTrigger}
                  onChange={({ option }) => setBillingTrigger(option)}
                  labelKey={(item) => {
                    return billingTriggersTranslations[item as Triggers];
                  }}
                  id={`billing-state-${(projectBillingTimelineEvents?.length ?? 0) + 1}`} //for diagram connection
                />
              </FormField>
              <FormField width={"medium"}>
                <TextArea
                  placeholder="Volitelný komentář"
                  value={note}
                  fill={true}
                  resize={"vertical"}
                  onChange={(event) => setNote(event.target.value)}
                />
              </FormField>
              <FormField width={"small"}>
                <Button
                  secondary
                  icon={state.triggerBillingLoading ? <Spinner /> : undefined}
                  disabled={!billingTrigger}
                  label="Uložit"
                  onClick={handleSaveNewBillingState}
                />
              </FormField>
            </Box>
          ) : null}
          <Box justify={"end"} direction={"row"}>
            {shouldShowBillingReportLink ? (
              <Link
                secondary
                label={"Přehled položek k fakturaci"}
                route={getRoute.billingsProjectDetailReport({ project: project.id }, { period: state.period })}
              />
            ) : null}
          </Box>
          {/*Diagrams*/}
          <Box flex>
            {/* Current Billing States */}
            <BillingStateChangesDiagram
              stateChanges={projectBillingTimelineEvents as []}
              connectionPrefix={"billing-state"}
            />

            {/* Previous Billing States */}
            {projectPreviousBillingStatesChanges.length > 0 && (
              <Box
                margin={{
                  vertical: "small",
                }}
                fill={"horizontal"}
                border={{
                  side: "top",
                }}
              />
            )}
            <BillingStateChangesDiagram
              stateChanges={projectPreviousBillingStatesChanges as []}
              connectionPrefix={"previous-billing-state"}
            />
          </Box>
        </Box>
      ) : (
        <Text>No Data</Text>
      )}
      {reportViewActive ? (
        <ProjectBillingReport onExit={hideReportView} onConfirm={handleOnNewBillingState} onCancel={hideReportView} />
      ) : null}
    </Box>
  );
};
