import { useMemo } from "react";
import { mapConnection, TimeEntry } from "api";

/*
 * Time Entry Pings Info
 *
 * informs about newest ping state
 * */
export const useTimeEntryPingsInfo = (pings: TimeEntry["pings"]) => {
  const Pings = useMemo(() => mapConnection(pings), [pings]);
  const NewestPing = useMemo(() => Pings?.pop(), [Pings]);
  const State = useMemo(() => NewestPing?.state, [NewestPing]);
  const IsChanged = useMemo(() => !!NewestPing?.teChangedAfterResolved, [NewestPing]);
  return {
    state: { State, IsChanged },
  };
};
