import { useCallback } from "react";
import { resolveError, useProjectForProjectSettingsQuery } from "api";
import { Spinner } from "components";
import { ProjectSettingsForm } from "containers/ProjectSettingsForm/ProjectSettingsForm";
import { useFormSettingsOptions } from "containers/ProjectSettingsForm/useFormSettingsOptions";
import {
  ProjectSettingsFormData,
  useProjectSettingsFormDataTransformer,
} from "containers/ProjectSettingsForm/useProjectSettingsFormDataTransformer";

type Props = {
  projectId: string;
  onSubmit: (data: ProjectSettingsFormData) => void;
  onCancel: () => void;
};

export const ProjectSettingsDataProvider = ({ projectId, onSubmit, ...rest }: Props) => {
  const { data, loading } = useProjectForProjectSettingsQuery({
    variables: {
      id: projectId,
    },
    onError: resolveError,
  });

  const {
    data: { FormData },
    handlers: { transformFormDataToApiPayload },
  } = useProjectSettingsFormDataTransformer(data?.project);

  const {
    data: options,
    state: { loading: optionsLoading },
  } = useFormSettingsOptions();

  const handleOnSubmit = useCallback(
    (data: ProjectSettingsFormData) => {
      const dataToSubmit = transformFormDataToApiPayload(data, options.taskTrackingProjects);

      if (!dataToSubmit) {
        throw new Error("cannot convert data to api payload");
      }
      onSubmit(dataToSubmit);
    },
    [onSubmit, options.taskTrackingProjects, transformFormDataToApiPayload]
  );

  if (loading || !FormData || optionsLoading) {
    return <Spinner />;
  }

  return <ProjectSettingsForm {...rest} data={FormData} options={options} onSubmit={handleOnSubmit} />;
};
