import { Box, Text } from "grommet";
import { Spinner } from "components";
import { DashboardCard } from "components/Dashboard/DashboardCard";
import { Workload } from "components/Dashboard/Workload/Workload";
import { WorkloadTrend } from "components/Dashboard/Workload/WorkloadTrend";
import { ProjectWithMetrics } from "pages/Dashboard";
import { MetricWrapper } from "./MetricWrapper";

type Props = {
  projectMetrics: ProjectWithMetrics[];
  loading?: boolean;
};
export const Unassigned = ({ projectMetrics, loading }: Props) => {
  return (
    <Box>
      <Text size={"medium"} margin={{ vertical: "medium" }}>
        Nezařazené projekty
      </Text>
      {loading && <Spinner />}
      <Box direction={"row"} wrap={true} justify={"evenly"} gap={"medium"}>
        {projectMetrics &&
          projectMetrics.map((project) => (
            <DashboardCard
              key={project.id}
              label={project.name}
              margin={{ bottom: "small" }}
              direction={"column"}
              width={"medium"}
              height={"medium"}
              border={false}
              justify={"center"}
              labelWrapperProps={{
                flex: "grow",
                width: "100%",
                align: "center",
                margin: { bottom: "small" },
              }}
              labelProps={{
                textAlign: "center",
              }}
            >
              <MetricWrapper label={"Workload"}>
                <Workload fte={project.workload?.index} deviation={project.workload?.percentageChange} />
              </MetricWrapper>
              <MetricWrapper label={"Workload Trend"} width={"medium"}>
                <WorkloadTrend data={project.workload?.trend} />
              </MetricWrapper>
            </DashboardCard>
          ))}
      </Box>
    </Box>
  );
};
