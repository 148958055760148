import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import {
  mapConnection,
  ProjectForProjectSettingsDocument,
  ProjectsForProjectSettingsDocument,
  ProjectsForProjectSettingsQuery,
  ProjectUpdateInput,
  resolveError,
  useProjectForProjectSettingsLazyQuery,
  useProjectsForProjectSettingsQuery,
  useUpdateProjectMutation,
} from "api";
import { SortDirection, useSorting } from "hooks";

export type Project = NonNullable<ProjectsForProjectSettingsQuery["projects"]>["edges"][0]["node"];

export const useProjectsSettings = () => {
  const sortHandler = useSorting<keyof Project>("code");

  const { data: projectsData, loading: loadingProjects } = useProjectsForProjectSettingsQuery({
    onError: resolveError,
  });

  const [queryProject, { data: projectDetailData, loading: loadingProject }] = useProjectForProjectSettingsLazyQuery({
    onError: (e) => {
      resolveError(e);
    },
  });

  const [updateProjectMutation, { loading: loadingUpdate }] = useUpdateProjectMutation({
    onError: resolveError,
    onCompleted: () => toast.info(`🥳 Projekt úspěšně aktualizován.`),
  });
  const projects = useMemo(() => mapConnection(projectsData?.projects), [projectsData]);

  const sortedProjects = useMemo(
    () =>
      projects.sort((a, b) => {
        if (!a || !b) {
          return 1;
        }
        const isPm = sortHandler.sortBy === "projectManager";
        const x = isPm ? `${a.projectManager?.lastName}` : a[sortHandler.sortBy] ?? "";
        const y = isPm ? `${b.projectManager?.lastName}` : b[sortHandler.sortBy] ?? "";
        const dir = sortHandler.direction === SortDirection.ASC ? 1 : -1;
        return x > y ? dir : -1 * dir;
      }),
    [sortHandler.direction, projects, sortHandler.sortBy]
  );

  const loadProject = useCallback(
    (projectId: string) => {
      queryProject({
        variables: {
          id: projectId,
        },
      });
    },
    [queryProject]
  );

  const updateProject = useCallback(
    (updatedData: ProjectUpdateInput) => {
      if (!updatedData.project) {
        return;
      }
      try {
        updateProjectMutation({
          variables: {
            input: updatedData,
          },
          refetchQueries: [
            { query: ProjectsForProjectSettingsDocument },
            {
              query: ProjectForProjectSettingsDocument,
              variables: {
                id: updatedData.project,
              },
            },
          ],
        });
      } catch (e) {
        console.error(e);
      }
    },
    [updateProjectMutation]
  );

  return {
    state: {
      loading: loadingProjects,
      loadingDetail: loadingProject,
      loadingUpdate,
    },
    data: {
      projects,
      sortedProjects,
      projectsData,
      projectDetailData,
    },
    handlers: {
      loadProject,
      updateProject,
      sortHandler,
    },
  };
};
