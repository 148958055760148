import { useState, useCallback } from "react";

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export type SortingHandler<T> = {
  sortBy: T;
  direction: SortDirection;
  setSorting: (option: T) => void;
  resetSorting: () => void;
};

export const useSorting = <T>(
  initialValue: T,
  defaultDirection: SortDirection = SortDirection.ASC
): SortingHandler<T> => {
  const [sortBy, setSortBy] = useState(initialValue);
  const [direction, setDirection] = useState<SortDirection>(defaultDirection);

  const setSorting = useCallback(
    (option: T) => {
      setDirection(
        sortBy === option
          ? direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC
          : defaultDirection
      );
      setSortBy(option);
    },
    [defaultDirection, direction, sortBy]
  );

  const resetSorting = useCallback(() => {
    setSortBy(initialValue);
  }, [initialValue]);

  return {
    sortBy,
    direction,
    setSorting,
    resetSorting,
  };
};
