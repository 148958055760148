import * as React from "react";
import { useCallback, useMemo } from "react";
import { DateInputProps } from "grommet";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  mapConnection,
  ProjectsForReportsQuery,
  resolveError,
  useProjectForInvoiceQuery,
  useProjectsForReportsQuery,
} from "api";
import { getDateFromDateInputChangeEvent } from "helpers";
import { useDebouncedValue, useStateQueryParam } from "hooks";
import { getRoute, ReportsQueries } from "routes";
import {
  DateStr,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  isFirstDayOfMonth,
  isValidDate,
  localizeDate,
  toDateStr,
} from "utils";

type ProjectNode = NonNullable<ProjectsForReportsQuery["projects"]>["edges"][0]["node"];

const defaultFromDate: Date = getFirstDayOfMonth();
const defaultTillDate: Date = getLastDayOfMonth();

export const useReports = (projectId: string = "") => {
  const history = useHistory();

  const [fromDate, setFromDate] = useStateQueryParam<DateStr>("fromDate", toDateStr(defaultFromDate), isValidDate);
  const [tillDate, setTillDate] = useStateQueryParam<DateStr>("tillDate", toDateStr(defaultTillDate), isValidDate);
  const unbilledTillDate = toDateStr(moment(fromDate).subtract(1, "days"));
  const [onlyUnbilled, setOnlyUnbilled] = useStateQueryParam<boolean>("onlyUnbilled", false);

  const localizedFromDate = useMemo<string | undefined>(() => fromDate && localizeDate(new Date(fromDate)), [fromDate]);
  const localizedTillDate = useMemo<string | undefined>(() => tillDate && localizeDate(new Date(tillDate)), [tillDate]);

  const searchProjectQuery = useDebouncedValue("", 300);

  const { data: projectsData, loading: projectsLoading } = useProjectsForReportsQuery({
    onError: resolveError,
  });

  const { data: projectInvoiceData, loading: projectInvoiceLoading } = useProjectForInvoiceQuery({
    variables: {
      id: projectId,
      fromDate: fromDate,
      tillDate: tillDate,
      unbilledTillDate: unbilledTillDate,
      onlyUnbilled: onlyUnbilled,
    },
    fetchPolicy: "network-only",
    skip: !projectId || !fromDate || !tillDate,
    onError: resolveError,
  });

  const projects = React.useMemo<ProjectNode[]>(() => mapConnection(projectsData?.projects), [projectsData]);
  const project = React.useMemo(() => projectInvoiceData?.project, [projectInvoiceData]);
  const projectEntries = React.useMemo(() => mapConnection(project?.timeEntries), [project]);
  const unbilledProjectEntries = React.useMemo(() => mapConnection(project?.unbilledTimeEntries), [project]);
  const routeQueries = useMemo<ReportsQueries>(() => ({ fromDate, tillDate, onlyUnbilled }), [
    fromDate,
    tillDate,
    onlyUnbilled,
  ]);

  const selectOptions = useMemo(
    () =>
      [
        ...projects
          .sort((a, b) => (a.code > b.code ? 1 : -1))
          .map((p) => ({
            value: p.id,
            label: `[${p.code}] ${p.name}`,
          })),
      ].filter((opt) => opt.label.toLowerCase().includes(searchProjectQuery.debounced.toLowerCase())),
    [projects, searchProjectQuery.debounced]
  );

  const setSelectedProject = useCallback(
    (id: string) => {
      const route = id ? getRoute.reportsProject({ project: id }, routeQueries) : getRoute.reports(routeQueries);
      history.push(route);
    },
    [history, routeQueries]
  );

  const handleClickPrintReport = useCallback(() => {
    const route = getRoute.projectInvoiceReport({ project: projectId }, routeQueries);
    window.open(route);
  }, [projectId, routeQueries]);

  const handleChangeInputFromDate: DateInputProps["onChange"] = useCallback(
    (event) => {
      const date = getDateFromDateInputChangeEvent(event);
      if (!date) return;

      setFromDate(toDateStr(date));
      // update tillDate to the last day of the month when fromDate is first day of the month
      isFirstDayOfMonth(date) && setTillDate(toDateStr(getLastDayOfMonth(date)));
    },
    [setFromDate, setTillDate]
  );

  const handleChangeInputTillDate: DateInputProps["onChange"] = useCallback(
    (event) => {
      const date = getDateFromDateInputChangeEvent(event);
      date && setTillDate(toDateStr(date));
    },
    [setTillDate]
  );

  const handleChangeInputOnlyUnbilled: JSX.IntrinsicElements["input"]["onChange"] = useCallback((event) => {
    setOnlyUnbilled(event.target.checked);
  }, []);

  return {
    state: {
      projectsLoading,
      projectInvoiceLoading,

      selectOptions,

      tillDate,
      fromDate,
      onlyUnbilled,

      localizedFromDate,
      localizedTillDate,
    },
    data: { projects, project, projectEntries, unbilledProjectEntries },
    handlers: {
      setSelectedProject,
      handleClickPrintReport,

      handleSearchSelect: searchProjectQuery.set,
      handleCloseSelect: searchProjectQuery.reset,

      handleChangeInputFromDate,
      handleChangeInputTillDate,
      handleChangeInputOnlyUnbilled,
    },
  };
};
