import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "api";
import { routes } from "routes";

const inputs = { email: "email", password: "password" };

type Value = typeof inputs;

const defaultValue = { [inputs.email]: "", [inputs.password]: "" } as Value;

export const useLogin = () => {
  const history = useHistory();

  const { isLoggedIn, login, loading } = useAuth();

  const [value, setValue] = useState<Value>(defaultValue);
  const [error, setError] = useState(false);

  const handleSuccess = useCallback(() => history.push(routes.internal), [history]);
  const handleError = useCallback(() => setError(true), []);

  const handleSubmit = useCallback(async () => {
    if (loading) return;

    await login(value.email, value.password, handleSuccess, handleError);
  }, [loading, login, value.email, value.password, handleSuccess, handleError]);

  const handleChange = useCallback((_value: any) => setValue(_value), []);

  useEffect(() => (isLoggedIn() ? history.push(routes.internal) : undefined), [isLoggedIn, history]);

  return {
    inputs,
    state: {
      loading,
      value,
      error,
    },
    handlers: {
      handleSubmit,
      handleChange,
    },
  };
};
