import * as React from "react";
import { Text } from "grommet";
import type { TextProps } from "grommet";

type Props = TextProps & {
  children: React.ReactText;
};
export const FormErrorText = ({ children, ...props }: Props) => (
  <Text color={"status-error"} {...props}>
    {children}
  </Text>
);
