import { Box, ResponsiveContext } from "grommet";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { Navigation } from "components";
import { Dashboard, Deviations, ProjectSettings, Reports, TimeEntries } from "pages";
import { Billings } from "pages/Billings";
import { ProjectBillingReportPage } from "pages/Billings/ProjectBillingDetailReport/ProjectBillingReportPage";
import { PingOverview } from "pages/PingOverview";
import { routes } from "routes";
import { useInternal } from "./useInternal";

interface Props extends RouteComponentProps {}

export const Internal = (props: Props) => {
  const { render } = useInternal(props);

  if (!render) return null;

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box height={"100vh"} width={"100vw"}>
          <Box as={"header"} flex={"grow"} background={"brand"} elevation={"small"} style={{ zIndex: 2 }}>
            <Navigation />
          </Box>

          <Box fill>
            <Switch>
              <Route path={routes.dashboard} component={Dashboard} exact />
              <Route path={routes.timeEntries} component={TimeEntries} exact />
              <Route path={routes.timeEntriesProject} component={TimeEntries} exact />
              <Route path={routes.deviations} component={Deviations} exact />
              <Route path={routes.deviationsProject} component={Deviations} exact />
              <Route path={routes.reports} component={Reports} exact />
              <Route path={routes.reportsProject} component={Reports} exact />
              <Route path={routes.projectsSettings} component={ProjectSettings} exact />
              <Route path={routes.pingOverview} component={PingOverview} exact />
              <Route path={routes.billings} component={Billings} exact />
              <Route path={routes.billingsProjectDetail} component={Billings} exact />
              <Route path={routes.billingsProjectDetailReport} component={ProjectBillingReportPage} exact />

              {/* 404 for Internal */}
              <Route path="*">
                <Redirect from={routes.root} to={routes.dashboard} exact />
              </Route>
            </Switch>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};
