import { Text } from "grommet";
import { useTitle } from "react-use";
import { ProjectBillingFieldsFragment } from "api";
import { Spinner } from "components";
import { ProjectInvoiceReportView } from "containers";
import { useProjectInvoiceReport } from "./useProjectInvoiceReport";

export const ProjectInvoiceReport = () => {
  const { data, state } = useProjectInvoiceReport();

  useTitle(
    data.project ? `report-${data.project?.code}-${state.fromDate}-${state.tillDate}` : "Report - Fakturované práce"
  );

  if (state.loading) {
    return <Spinner />;
  }

  if (!data.entries) {
    return <Text>Žádná data</Text>;
  }

  return (
    <ProjectInvoiceReportView
      project={data.project as ProjectBillingFieldsFragment}
      timeEntries={data.entries}
      tillDate={state.tillDate}
      fromDate={state.fromDate}
      preview={false}
      editable={false}
      showControlButtons={false}
    />
  );
};
