import { dashboardTheme } from "styles";

const PerformanceIndexLowerThreshold = 0.95;
const PerformanceIndexHigherThreshold = 1.05;
const BudgetUtilizationHigherThreshold = 1.05;
const BudgetUtilizationLowerThreshold = 0.95;

export const getColorByPerformanceIndex = (index: number | null | undefined) => {
  const { colors } = dashboardTheme;
  return !index
    ? colors.statusNeutral
    : index < PerformanceIndexLowerThreshold
    ? colors.statusOk
    : index > PerformanceIndexHigherThreshold
    ? colors.statusDanger
    : colors.statusWarning;
};

export const getColorByRatio = (index: number | null | undefined) => {
  const { colors } = dashboardTheme;
  return !index
    ? colors.statusNeutral
    : index < PerformanceIndexLowerThreshold
    ? colors.statusOk
    : index > PerformanceIndexHigherThreshold
    ? colors.statusDanger
    : colors.statusWarning;
};

export const getColorByBudgetUtilization = (
  spent: number | null | undefined,
  min: number | null | undefined,
  max: number | null | undefined
) => {
  const { colors } = dashboardTheme;
  if (!spent) {
    return colors.statusNeutral;
  }
  if (max && min) {
    const divergenceMax = spent / max;
    const divergenceMin = spent / min;
    return divergenceMax > BudgetUtilizationHigherThreshold || divergenceMin < BudgetUtilizationLowerThreshold
      ? colors.statusDanger
      : divergenceMax < BudgetUtilizationLowerThreshold || divergenceMin > BudgetUtilizationHigherThreshold
      ? colors.statusOk
      : colors.statusWarning;
  }
  if (max) {
    const divergence = spent / max;
    return divergence > BudgetUtilizationHigherThreshold
      ? colors.statusDanger
      : divergence < BudgetUtilizationLowerThreshold
      ? colors.statusOk
      : colors.statusWarning;
  }
  if (min) {
    const divergence = spent / min;
    return divergence > BudgetUtilizationHigherThreshold
      ? colors.statusOk
      : divergence < BudgetUtilizationLowerThreshold
      ? colors.statusDanger
      : colors.statusWarning;
  }
};
