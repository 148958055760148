import { Box, Heading, Text } from "grommet";
import { Spinner } from "components";
import { TimeEntryPingItem } from "components/TimeEntryPingItem";
import { getPingColor, getPingLabel } from "helpers";
import { usePingOverview } from "pages";

export const PingOverview = () => {
  const { data, state, handlers } = usePingOverview();
  return (
    <div>
      <Box align={"center"} pad={"small"}>
        {state.loading ? (
          <Spinner />
        ) : !data.GroupedPings ? (
          <Text>Nejsou pingované žádné záznamy.</Text>
        ) : (
          <Heading size={"xsmall"}>Pingované záznamy</Heading>
        )}
      </Box>
      <Box>
        {!!data.GroupedPings &&
          Object.entries(data.GroupedPings)
            .sort()
            .map(([workerName, timeEntries]) => {
              return (
                <Box key={workerName} pad={"small"}>
                  <Box direction={"row"} gap={"small"}>
                    <Text weight={"bold"}>{workerName}</Text>
                  </Box>
                  <Box pad={"small"}>
                    {Object.entries(timeEntries).map(([timeEntryId, pings]) => {
                      const timeEntry = handlers.getTimeEntryById(timeEntryId);
                      const mostRecentPing = handlers.getMostRecentPing(pings);
                      const pingColor = getPingColor(mostRecentPing?.state, mostRecentPing?.teChangedAfterResolved);
                      const pingLabel = getPingLabel(mostRecentPing?.state, mostRecentPing?.teChangedAfterResolved);
                      const updatedAt = mostRecentPing?.updatedAt;

                      return (
                        <TimeEntryPingItem
                          key={mostRecentPing?.id}
                          pingLabel={pingLabel}
                          color={pingColor}
                          updatedAt={updatedAt}
                          name={timeEntry?.project.name ?? "-"}
                          code={timeEntry?.project.code ?? "-"}
                          note={timeEntry?.notes ?? "-"}
                          task={timeEntry?.task}
                        />
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
      </Box>
    </div>
  );
};
