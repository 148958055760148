import Number from "big.js";
import { Box, Button, Text } from "grommet";
import { Revert } from "grommet-icons";
import styled from "styled-components";
import { Billable, Currency, Language } from "api";
import { Tooltip } from "components";
import { BillableIndicator } from "components/BillableIndicator";
import { TableEditableCell } from "containers/InvoiceReportTemplate/TableEditableCell";
import { SortedInvoiceData, useProjectInvoiceData } from "hooks";
import { formatDate, formatPrice, secondsToHoursWith2DP } from "utils";
import { Row, TableBodyCell, TableBodyRow } from "./InvoiceReportTemplate";
import { COLORS, getColorByType } from "./templateTheme";

type BreakdownTableRowProps = {
  task: SortedInvoiceData["workload"][0]["data"]["tasks"][0];
  language: Language;
  currency: Currency;
  editable?: boolean;
  showPrices?: boolean;
  showControlButtons?: boolean;
  onTimeEntryUpdate?: ReturnType<typeof useProjectInvoiceData>["handlers"]["updateTimeEntry"];
  onTimeEntryReset?: ReturnType<typeof useProjectInvoiceData>["handlers"]["resetTimeEntry"];
};

export const BreakdownTableRow = ({
  task,
  editable,
  currency,
  language,
  showPrices,
  showControlButtons,
  onTimeEntryUpdate,
  onTimeEntryReset,
}: BreakdownTableRowProps) => {
  return (
    <TableBodyRow
      key={task.id}
      withControlButtons={showControlButtons}
      disabled={task.billable === Billable.No}
      scheduled={task.billable === Billable.NextTime}
    >
      {showControlButtons && onTimeEntryUpdate && (
        <TableBodyCell>
          <BillableIndicator
            disabled={!editable}
            state={task.billable}
            onChange={(newState) => onTimeEntryUpdate(task.id, newState)}
          />
        </TableBodyCell>
      )}
      <TableBodyCell>{formatDate({ date: new Date(task.spentAt), language: language })}</TableBodyCell>
      <TableBodyCell wrapText>
        <Row>{task.reportNotes}</Row>
        <Row disablePadding>
          <Badge type={task.task ?? "operational"}>{task.task ?? "Operational"}</Badge>
          <SmallText>{`${task.worker?.firstName} ${task.worker?.lastName}`}</SmallText>
        </Row>
      </TableBodyCell>
      {showPrices && <TableBodyCell alignRight>{formatPrice(task.billableRate ?? 0, currency)}</TableBodyCell>}
      {editable && onTimeEntryUpdate ? (
        <Tooltip
          active={!!task.secondsBillable}
          content={<PreviousTimeVersion label={secondsToHoursWith2DP(task.seconds)} />}
        >
          <TableEditableCell
            alignRight
            bold={!!task.secondsBillable}
            onEdit={(editedHours) => onTimeEntryUpdate(task.id, task.billable, editedHours)}
          >
            {secondsToHoursWith2DP(task.secondsBillable ?? task.seconds)}
          </TableEditableCell>
        </Tooltip>
      ) : (
        <TableBodyCell alignRight>{secondsToHoursWith2DP(task.secondsBillable ?? task.seconds)}</TableBodyCell>
      )}
      {showPrices && (
        <TableBodyCell alignRight>
          {formatPrice(
            parseFloat(
              Number(task.secondsBillable ?? task.seconds)
                .div(Number(3600))
                .times(Number(task.billableRate ?? 0))
                .toString()
            ),
            currency
          )}
        </TableBodyCell>
      )}
      {showControlButtons && onTimeEntryReset && (
        <TableBodyCell alignRight>
          <Button
            icon={<Revert size={"small"} />}
            plain={false}
            onClick={() => onTimeEntryReset(task.id)}
            disabled={!editable}
          />
        </TableBodyCell>
      )}
    </TableBodyRow>
  );
};

const PreviousTimeVersion = ({ label }: { label: string }) => (
  <Box gap={"xsmall"} align={"center"}>
    <Text size={"xsmall"} color={"text"}>
      původně
    </Text>
    <Text weight={"bold"}>{label} h</Text>
  </Box>
);

/**
 * Styled components
 */
type BadgeProps = { type: string };
export const Badge = styled.div<BadgeProps>`
  border: ${(props) => `1px solid ${getColorByType(props.type)}`};
  color: ${(props) => getColorByType(props.type)};
  padding: 0.05cm 0.16cm;
  margin-right: 0.16cm;
  font-size: 0.26cm;
`;

const SmallText = styled.span`
  color: ${COLORS.grayDark};
  padding: 0.05cm 0.16cm;
  font-size: 0.26cm;
`;
