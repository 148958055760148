import { useCallback } from "react";
import { Box } from "grommet";
import { FormDown, FormUp } from "grommet-icons";
import { TableHeaderText } from "components/TableHeaderText";
import { SortDirection, SortingHandler } from "hooks/useSorting";

type Props = {
  text: string;
  registerProperty: any;
  handler: SortingHandler<any>;
};

export const TableSortingHeader = (props: Props) => {
  const handleSetSorting = useCallback(() => props.handler.setSorting(props.registerProperty), [
    props.handler,
    props.registerProperty,
  ]);
  return (
    <Box direction={"row"} align={"center"} onClick={handleSetSorting}>
      <TableHeaderText>{props.text}</TableHeaderText>
      {props.handler.sortBy !== props.registerProperty ? null : props.handler.direction === SortDirection.ASC ? (
        <FormUp />
      ) : (
        <FormDown />
      )}
    </Box>
  );
};
