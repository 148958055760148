import { setContext } from "@apollo/client/link/context";
import * as storage from "../storage";

export const authLink = setContext(async (_, { headers }) => {
  const token = await storage.getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});
