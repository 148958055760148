import { Box, Text } from "grommet";

type Props = {
  fte?: number;
  deviation?: number;
};
export const Workload = ({ fte = 0.0, deviation }: Props) => {
  return (
    <Box gap={"xxsmall"} align={"center"}>
      <Text size={"xlarge"} color={fte ? "text" : "status-unknown"}>{`${fte.toFixed(2)} FTE`}</Text>
      <Box round={"xsmall"} background={"light-2"} align={"center"} pad={"xsmall"}>
        <Text size={"small"}>{deviation ? `${deviation.toFixed(2)} %` : "N/A"}</Text>
      </Box>
    </Box>
  );
};
