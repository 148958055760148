import { Button, Select } from "grommet";
import { Box, Text } from "grommet";
import { Add } from "grommet-icons";
import { Control, Controller, useFieldArray, useWatch } from "react-hook-form";
import { Spinner } from "components";
import { useFormSettingsOptions } from "containers/ProjectSettingsForm/useFormSettingsOptions";
import { useSearch } from "hooks/useSearch";

type Props = {
  groupedTrackingProvidersProjects: ReturnType<typeof useFormSettingsOptions>["data"]["groupedTaskTrackingProjects"];
  taskTrackingProviders: ReturnType<typeof useFormSettingsOptions>["data"]["taskTrackingProviders"];
  taskTrackingProjects: ReturnType<typeof useFormSettingsOptions>["data"]["taskTrackingProjects"];
  control: Control;
};

export const TaskTrackingMultiSelector = ({
  taskTrackingProviders,
  taskTrackingProjects,
  groupedTrackingProvidersProjects,
  control,
}: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "taskTrackingProviderProjects",
  });
  const watcher = useWatch<{ taskTrackingProvider: string; taskTrackingProviderProjectId: string }[]>({
    control,
    name: "taskTrackingProviderProjects",
  });
  const { search, clear, query } = useSearch();

  return (
    <Box fill={"horizontal"} pad={"small"}>
      <Box direction={"row"} justify={"between"}>
        <Text>Task tracking provider & project</Text>
      </Box>
      {fields.map((item, index) => (
        <Box direction={"row"} key={index} pad={{ vertical: "xsmall" }} fill={"horizontal"} justify={"center"}>
          <Box fill={"horizontal"}>
            <Controller
              control={control}
              defaultValue={item.taskTrackingProvider}
              name={`taskTrackingProviderProjects[${index}].taskTrackingProvider`}
              rules={{ required: true }}
              render={({ value, onChange }) => {
                const provider = taskTrackingProviders.find((prov: any) => prov.id === value);
                return (
                  <Select
                    value={provider}
                    onChange={(e) => {
                      onChange(e.value.id);
                    }}
                    options={taskTrackingProviders}
                    labelKey={(item: typeof taskTrackingProviders[0]) => `${item.name}`}
                  />
                );
              }}
            />
          </Box>
          <Box fill={"horizontal"}>
            <Controller
              control={control}
              name={`taskTrackingProviderProjects[${index}].taskTrackingProviderProjectId`}
              rules={{ required: true }}
              defaultValue={item.taskTrackingProviderProjectId}
              render={({ value, onChange }) => {
                let options =
                  watcher && watcher?.[index]?.taskTrackingProvider
                    ? groupedTrackingProvidersProjects[watcher[index].taskTrackingProvider]
                    : taskTrackingProjects;
                if (query) {
                  options = options.filter(
                    (opt: { code: string; name: string }) =>
                      opt.code.toLowerCase().includes(query) || opt.name.toLowerCase().includes(query)
                  );
                }
                if (!options) {
                  return <Spinner />;
                }
                const project = options.find((proj: { externalId: string }) => proj.externalId === value);
                return (
                  <Select
                    margin={{ horizontal: "small" }}
                    value={project}
                    onChange={(e) => onChange(e.value.externalId)}
                    options={options}
                    labelKey={(item) => `${item.name}`}
                    onSearch={search}
                    onClose={clear}
                  />
                );
              }}
            />
          </Box>
          <Button
            label={"-"}
            hoverIndicator
            onClick={() => remove(index)}
            plain={false}
            disabled={fields.length === 1}
          />
        </Box>
      ))}
      <Box direction={"row"} justify={"center"} pad={{ top: "small" }}>
        <Button
          icon={<Add size={"small"} />}
          label={"Přidat"}
          onClick={() => append({ taskTrackingProvider: "", taskTrackingProviderProject: "" })}
        />
      </Box>
    </Box>
  );
};
