import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Maybe } from "api";
import * as serviceWorker from "serviceWorkerRegistration";

/*
 * original source: https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a
 * */

type SWContextValue = {
  isUpdateAvailable: boolean;
  updateAssets: () => void;
};

const defaultSWContextValue: SWContextValue = {
  isUpdateAvailable: false,
  updateAssets: () => {},
};

const ServiceWorkerContext = createContext<SWContextValue>(defaultSWContextValue);
export const ServiceWorkerProvider: React.FC = ({ children }) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<Maybe<ServiceWorker>>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(defaultSWContextValue.isUpdateAvailable);
  const updateInterval = useRef(0);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);

        updateInterval.current = window.setInterval(() => registration.update(), 1000 * 60 * 5);
      },
      onWaiting: (registration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
    });

    // clear interval on component unmount
    return window.clearInterval(updateInterval.current);
  }, []);

  useEffect(() => {
    if (!waitingServiceWorker) return;
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an inconsistent state
    waitingServiceWorker.addEventListener("statechange", () => {
      if (waitingServiceWorker.state === "activated") {
        window.location.reload();
      }
    });
  }, [waitingServiceWorker]);

  const value = useMemo<SWContextValue>(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (!waitingServiceWorker) return;
        // We send the SKIP_WAITING message to tell the Service Worker
        // to update its cache and flush the old one
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      },
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => useContext(ServiceWorkerContext);
