import { Big } from "big.js";

/**
 * Big.js used for successful work with decimal numbers
 * */
export const percentage = (source: number, target: number): number => {
  if (source === 0) return 0; // prevent division by zero
  return Number(new Big(target).div(new Big(source)).mul(100));
};

/**
 * Check if number or string value contains a number
 * Return number from value or defaultValue
 * */
export const guardedNumber = <T extends number | string | undefined | null, K>(
  value: T,
  defaultValue: K
): number | K => {
  if (typeof value === "number") return value;
  if (!value) return defaultValue; // handle empty string, null or undefined value

  if (typeof value === "string") {
    const int = parseInt(value);
    return isNaN(int) ? defaultValue : int;
  }

  return defaultValue;
};
