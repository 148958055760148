import * as React from "react";
import Number from "big.js";
import { Button } from "grommet";
import { Revert } from "grommet-icons";
import styled from "styled-components";
import { Billable, Currency, GroupBy, Language } from "api";
import { ReactComponent as InvoiceIconCZK } from "assets/invoiceCZK.svg";
import { ReactComponent as InvoiceIconEUR } from "assets/invoiceEUR.svg";
import { ReactComponent as InvoiceIconGBP } from "assets/invoiceGBP.svg";
import { ReactComponent as InvoiceIconUSD } from "assets/invoiceUSD.svg";
import { ReactComponent as Logo } from "assets/logo_rich_dark.svg";
import { BillableSelector } from "components/BillableIndicator";
import { projectInvoiceTranslations } from "helpers";
import { SortedInvoiceData, useProjectInvoiceData } from "hooks";
import { DateStr, formatDate, formatPrice, hoursToHoursWith2DP } from "utils";
import { BreakdownTableRow } from "./BreakdownTableRow";
import { COLORS, HeaderHeight } from "./templateTheme";

const getAnchorString = (string: string) => {
  let hash = 0,
    i,
    chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const InvoiceIconTransformMap: Record<Currency, React.ReactElement> = {
  [Currency.Czk]: <InvoiceIconCZK />,
  [Currency.Eur]: <InvoiceIconEUR />,
  [Currency.Gbp]: <InvoiceIconGBP />,
  [Currency.Usd]: <InvoiceIconUSD />,
};

type InvoiceReportTemplateProps = {
  data: SortedInvoiceData;
  language: Language;
  groupedBy: GroupBy;
  showPrices: boolean;
  showVatInfo: boolean;
  currency: Currency;
  projectName?: string;
  pmPercent?: number;
  fromDate?: DateStr;
  tillDate?: DateStr;
  editable?: boolean;
  showControlButtons?: boolean;
  showDate?: boolean;
  onTimeEntryUpdate?: ReturnType<typeof useProjectInvoiceData>["handlers"]["updateTimeEntry"];
  onTimeEntriesUpdate?: ReturnType<typeof useProjectInvoiceData>["handlers"]["updateTimeEntries"];
  onTimeEntryReset?: ReturnType<typeof useProjectInvoiceData>["handlers"]["resetTimeEntry"];
  onTimeEntriesReset?: ReturnType<typeof useProjectInvoiceData>["handlers"]["resetTimeEntries"];
};

export const InvoiceReportTemplate = (props: InvoiceReportTemplateProps) => {
  const t = React.useMemo(() => projectInvoiceTranslations[props.language], [props.language]);
  const date = React.useMemo(() => {
    return props.showDate
      ? `(${formatDate({
          date: props.fromDate,
          language: props.language,
        })} - ${formatDate({ date: props.tillDate, language: props.language })})`
      : "";
  }, [props]);
  return (
    <Content>
      {/*Header*/}
      <Header>
        <Logo height={HeaderHeight} />
        <Row disablePadding>
          <HeaderInfo>
            <HeaderInfoMain>{t.project.title}</HeaderInfoMain>
            <HeaderInfoSecond>{`${props.projectName ?? ""} ${date}`}</HeaderInfoSecond>
          </HeaderInfo>
          {React.cloneElement(InvoiceIconTransformMap[props.currency], { height: HeaderHeight })}
        </Row>
      </Header>
      {/* Cenovy rozpad */}
      <BreakableTableWrapper>
        <Title>{t.section[props.groupedBy].workload.title}</Title>
        <WorkloadTable
          data={props.data}
          t={t.section[props.groupedBy].workload}
          showPrices={props.showPrices}
          summaryText={t.section[props.groupedBy].workload.tableSummary}
          pmPercent={props.pmPercent}
          currency={props.currency}
        />
      </BreakableTableWrapper>
      {/* Pracovni rozpad */}
      <NonBreakableTableWrapper break={props.groupedBy === GroupBy.Worker}>
        <Title>{t.section[props.groupedBy].breakdown.title}</Title>
        <BreakdownTable
          t={t}
          data={props.data}
          showPrices={props.showPrices}
          summaryText={t.section[props.groupedBy].breakdown.tableSummary}
          language={props.language}
          currency={props.currency}
          editable={props.editable}
          showControlButtons={props.showControlButtons}
          onTimeEntryUpdate={props.onTimeEntryUpdate}
          onTimeEntriesUpdate={props.onTimeEntriesUpdate}
          onTimeEntryReset={props.onTimeEntryReset}
          onTimeEntriesReset={props.onTimeEntriesReset}
        />
      </NonBreakableTableWrapper>
      {/* Info pod carou o DPH */}
      {props.showVatInfo && <Note>{t.project.vatInfo}</Note>}
    </Content>
  );
};

type WorkloadTableProps = {
  data: SortedInvoiceData;
  showPrices: boolean;
  summaryText: string;
  currency: Currency;
  t: any;
  pmPercent?: number;
};
const WorkloadTable = ({ data, showPrices, summaryText, t, pmPercent, currency }: WorkloadTableProps) => {
  const visibleTotalPrice = React.useMemo<number>(
    () => data.workload.reduce((total, { data: workloadData }) => total + Math.round(workloadData.summary.price), 0),
    [data.workload]
  );
  const visibleProjectManagerFee = React.useMemo<number>(() => Math.round(data.breakdown.summary.pmsFee?.price || 0), [
    data.breakdown.summary.pmsFee,
  ]);
  const visibleTotalPriceWithFee = React.useMemo(() => visibleTotalPrice + visibleProjectManagerFee, [
    visibleProjectManagerFee,
    visibleTotalPrice,
  ]);

  return (
    <Table>
      {data.workload.map(({ title, data: workloadData }, i) => {
        const visibleTaskPrice: number = Math.round(workloadData.summary.price);

        return (
          <TableWorkloadBodyRow key={i}>
            <TableBodyCell>
              <a href={`#${getAnchorString(title)}`}>{title}</a>
            </TableBodyCell>
            <TableBodyCell alignRight>
              {hoursToHoursWith2DP(workloadData.summary.hours)} {t.hoursUnitText}
            </TableBodyCell>
            {showPrices && <TableBodyCell alignRight>{formatPrice(visibleTaskPrice, currency)}</TableBodyCell>}
            <TableBodyCell>
              <Bar width={`${(workloadData.summary.hours / data.breakdown.summary.maxEpicValueHours) * 100}`} />
            </TableBodyCell>
          </TableWorkloadBodyRow>
        );
      })}
      <TableWorkloadBodyRow offsetTop>
        <TableBodyCell bold>{summaryText}</TableBodyCell>
        <TableBodyCell bold alignRight>
          {hoursToHoursWith2DP(data.breakdown.summary.hours)} {t.hoursUnitText}
        </TableBodyCell>
        {showPrices && (
          <TableBodyCell bold alignRight>
            {formatPrice(visibleTotalPrice, currency)}
          </TableBodyCell>
        )}
        <TableBodyCell />
      </TableWorkloadBodyRow>
      {data.breakdown.summary.pmsFee && (
        <>
          <TableWorkloadBodyRow offsetTop>
            <TableBodyCell>{`${t.pmFee} (${pmPercent} %)`}</TableBodyCell>
            <TableBodyCell alignRight>
              {hoursToHoursWith2DP(data.breakdown.summary.pmsFee.hours)} {t.hoursUnitText}
            </TableBodyCell>
            {showPrices && <TableBodyCell alignRight>{formatPrice(visibleProjectManagerFee, currency)}</TableBodyCell>}
            <TableBodyCell />
          </TableWorkloadBodyRow>
          <TableWorkloadBodyRow offsetTop>
            <TableBodyCell bold>{t.totalPmIncluded}</TableBodyCell>
            <TableBodyCell bold alignRight>
              {hoursToHoursWith2DP(data.breakdown.summary.pmsFee.hours + data.breakdown.summary.hours)}{" "}
              {t.hoursUnitText}
            </TableBodyCell>
            {showPrices && (
              <TableBodyCell bold alignRight>
                {formatPrice(visibleTotalPriceWithFee, currency)}
              </TableBodyCell>
            )}
            <TableBodyCell />
          </TableWorkloadBodyRow>
        </>
      )}
    </Table>
  );
};

type BreakdownTableProps = {
  t: any;
  data: SortedInvoiceData;
  showPrices: boolean;
  summaryText: string;
  currency: Currency;
  language: Language;
  editable?: boolean;
  showControlButtons?: boolean;
  onTimeEntryUpdate?: InvoiceReportTemplateProps["onTimeEntryUpdate"];
  onTimeEntriesUpdate?: InvoiceReportTemplateProps["onTimeEntriesUpdate"];
  onTimeEntryReset?: InvoiceReportTemplateProps["onTimeEntryReset"];
  onTimeEntriesReset?: InvoiceReportTemplateProps["onTimeEntriesReset"];
};
const BreakdownTable = ({
  t,
  data,
  showPrices,
  summaryText,
  language,
  currency,
  editable,
  showControlButtons,
  onTimeEntryUpdate,
  onTimeEntriesUpdate,
  onTimeEntryReset,
  onTimeEntriesReset,
}: BreakdownTableProps) => (
  <>
    {data.workload.map(({ title, data }, i) => {
      const visibleTotalPrice = data.tasks
        .filter((timeEntry) => timeEntry.billable === Billable.Yes)
        .reduce((acc, val) => {
          const hourlyRate: number = val.billableRate || 0;
          const seconds: number = val.secondsBillable || val.seconds;
          const hours: number = parseFloat(Number(seconds).div(Number(3600)).toString());

          const price: number = Math.round(parseFloat(Number(hourlyRate).times(hours).toString()));

          return acc + price;
        }, 0);

      const handleBulkChangeBillableState = async (newState: Billable) => {
        if (!onTimeEntriesUpdate) return;
        const input = data.tasks.map((task) => ({
          id: task.id,
          billable: newState,
        }));
        await onTimeEntriesUpdate(input);
      };

      const handleClickResetAllTasks = async () => {
        if (!onTimeEntriesReset) return;
        const input = data.tasks.map((task) => task.id);
        await onTimeEntriesReset(input);
      };

      return (
        <Table id={`${getAnchorString(title)}`} key={i}>
          <TableThead>
            <TableHeaderRow withControlButtons={showControlButtons}>
              {/* bulk toggle billable states for all tasks */}
              {showControlButtons && (
                <TableHeaderCell>
                  <BillableSelector onChange={handleBulkChangeBillableState} disabled={!editable} />
                </TableHeaderCell>
              )}

              <TableHeaderCell>{title}</TableHeaderCell>
              {showPrices && <TableHeaderCell alignRight>{t.project.rate}</TableHeaderCell>}
              <TableHeaderCell alignRight>{t.project.hours}</TableHeaderCell>
              {showPrices && <TableHeaderCell alignRight>{t.project.price}</TableHeaderCell>}
              {showControlButtons && (
                <TableHeaderCell>
                  <Button
                    style={{ display: "block", marginLeft: "auto" }} // align to the right
                    icon={<Revert size={"small"} />}
                    plain={false}
                    onClick={handleClickResetAllTasks}
                    disabled={!editable}
                  />
                </TableHeaderCell>
              )}
            </TableHeaderRow>
          </TableThead>

          {data.tasks.length > 0 && (
            <div>
              {data.tasks.map((task) => (
                <BreakdownTableRow
                  key={task.id}
                  task={task}
                  language={language}
                  currency={currency}
                  editable={editable}
                  showPrices={showPrices}
                  showControlButtons={showControlButtons}
                  onTimeEntryUpdate={onTimeEntryUpdate}
                  onTimeEntryReset={onTimeEntryReset}
                />
              ))}
            </div>
          )}
          <TableBodyRow offsetTop withControlButtons={showControlButtons}>
            {showControlButtons && <TableBodyCell />}
            <TableBodyCell />
            <TableBodyCell bold>{summaryText}</TableBodyCell>
            {showPrices && <TableBodyCell />}
            <TableBodyCell alignRight bold>
              {hoursToHoursWith2DP(data.summary.hours)}
            </TableBodyCell>
            {showPrices && (
              <TableBodyCell alignRight bold>
                {formatPrice(visibleTotalPrice, currency)}
              </TableBodyCell>
            )}
            {showControlButtons && <TableBodyCell />}
          </TableBodyRow>
        </Table>
      );
    })}
  </>
);

/**
 * Styled Components
 */
const Content = styled.div`
  font-family: "MuseoSans", "Roboto Light", Helvetica, sans-serif;
  font-weight: 300;
  font-size: 0.3175cm;
  color: ${COLORS.text};
  line-height: 1.3;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Title = styled.div`
  font-family: "Brandon", "Roboto Light", Helvetica, sans-serif;
  font-size: 0.42cm;
  font-weight: 900;
  text-transform: uppercase;

  background-color: ${COLORS.brand};

  padding: 0.25cm 0.2cm 0.2cm 0.2cm;
`;

/* table */
const cssRowChildSizes = `
  /* all cells */
  > * {
    padding: 0.25cm 0.2cm 0.05cm 0.2cm;
  }
  /* Date */
  > *:nth-child(1) {
    width: 2.55cm;
  }
  /* Name */
  > *:nth-child(2) {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Rate, Hours, Price */
  > *:nth-child(3),
  > *:nth-child(4),
  > *:nth-child(5) {
    width: 2cm;
  }
`;

const cssEditableRowChildSizes = `
  /* all cells */
  > * {
    padding: 0.25cm 0.2cm 0.05cm 0.2cm;
  }
  /* checkbox */
  > *:nth-child(1) {
    width: 2.5cm;
  }
  /* Date */
  > *:nth-child(2) {
    width: 2.55cm;
  }
  /* Name */
  > *:nth-child(3) {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Rate, Hours, Price */
  > *:nth-child(4),
  > *:nth-child(5),
  > *:nth-child(6) {
    width: 2cm;
  }
  /* Reset button */
  > *:nth-child(7) {
    padding:  0.1cm 0.1cm 0.1cm 0.1cm ;
    width: 2cm;
  }
`;

const cssHeaderRowChildSizes = `
  > * {
    padding: 0.25cm 0.2cm 0.05cm 0.2cm;
  }
  
  /* Name */
  > *:nth-child(1) {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Brandon", "Roboto Light", Helvetica, sans-serif;
    font-size: 0.37cm;
    color: ${COLORS.text};
    font-weight: normal;
    text-transform: uppercase;
  }
  /* Rate, Hours, Price */
  > *:nth-child(2),
  > *:nth-child(3),
  > *:nth-child(4) {
    width: 2cm;
}`;

const cssEditableHeaderRowChildSizes = `
  > * {
    padding: 0.25cm 0.2cm 0.05cm 0.2cm;
  }
  
  > *:nth-child(1) {
    width: 2.5cm;
    color: ${COLORS.text};
  }
  
  /* Name */
  > *:nth-child(2) {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Brandon", "Roboto Light", Helvetica, sans-serif;
    font-size: 0.37cm;
    color: ${COLORS.text};
    font-weight: normal;
    text-transform: uppercase;
  }
  /* Rate, Hours, Price */
  > *:nth-child(3),
  > *:nth-child(4),
  > *:nth-child(5) {
    width: 2cm;
  }
  /* Reset button */
  > *:nth-child(6) {
    width: 2cm;
}
`;

const cssWorkloadChildSizes = `
  > * {
    padding: 0.25cm 0.2cm 0.05cm 0.2cm;
  }
  /* Name */
  > *:first-child {
    flex: 1;
    white-space: normal;
    max-width: 15cm;
    overflow: unset;
    text-overflow: unset;
  }
  
  /* Bar */
  > *:last-child {
    width: 25%;
    overflow: hidden;
  }

  /* Hours, Price */
  > *:nth-child(2),
  > *:nth-child(3){
    width: 2cm;
  }`;

const BreakableTableWrapper = styled.div`
  margin-top: 0.8cm;
`;
type NonBreakableTableWrapperProps = {
  break?: boolean;
};
const NonBreakableTableWrapper = styled.div<NonBreakableTableWrapperProps>`
  margin-top: 0.8cm;
  page-break-inside: ${(props) => (props.break ? "auto" : "avoid")};
  @media print {
    margin-top: 0;
    page-break-before: always;
  }
`;

const Table = styled.div`
  width: 100%;
  padding-top: 0.5cm;
`;

const TableThead = styled.div`
  width: 100%;
`;

type TableHeaderRowProps = {
  withControlButtons?: boolean;
};
const TableHeaderRow = styled.div<TableHeaderRowProps>`
  background-color: ${COLORS.grayLight};
  display: flex;
  align-items: center;
  padding-bottom: 0.1cm;
  color: ${COLORS.grayDark};
  font-weight: normal;
  page-break-inside: avoid;
  ${(props) => (props.withControlButtons ? cssEditableHeaderRowChildSizes : cssHeaderRowChildSizes)}
`;

type TableHeaderCellProps = {
  alignRight?: boolean;
};
const TableHeaderCell = styled.div<TableHeaderCellProps>`
  text-align: ${(props) => (props.alignRight ? "right" : "left")};
  white-space: nowrap;
`;

type TableBodyRowProps = {
  withControlButtons?: boolean;
  disabled?: boolean;
  scheduled?: boolean;
  highlight?: boolean;
  offsetTop?: boolean;
};
export const TableBodyRow = styled.div<TableBodyRowProps>`
  display: flex;

  background-color: ${(props) => (props.highlight ? COLORS.grayLight : "unset")};
  padding-top: ${(props) => (props.offsetTop ? "0.3cm" : "unset")};
  page-break-inside: avoid;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  background-color: ${(props) =>
    props.disabled ? "rgba(0, 0, 0, 0.1)" : props.scheduled ? "rgba(238,218,140, 0.3)" : "unset"};
  ${(props) => (props.withControlButtons ? cssEditableRowChildSizes : cssRowChildSizes)}
`;

const TableWorkloadBodyRow = styled.div<TableBodyRowProps>`
  display: flex;

  background-color: ${(props) => (props.highlight ? COLORS.grayLight : "unset")};
  padding-top: ${(props) => (props.offsetTop ? "0.3cm" : "unset")};
  page-break-inside: avoid;
  ${cssWorkloadChildSizes}
`;

export type TableBodyCellProps = {
  wrapText?: boolean;
  alignRight?: boolean;
  bold?: boolean;
  cursorPointer?: boolean;
};
export const TableBodyCell = styled.div<TableBodyCellProps>`
  white-space: ${(props) => (props.wrapText ? "normal" : "nowrap")};
  text-align: ${(props) => (props.alignRight ? "right" : "left")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  cursor: ${(props) => (props.cursorPointer ? "pointer" : "normal")};
`;

type BarProps = { width: string };
const Bar = styled.div<BarProps>`
  width: ${(props) => props.width}%;
  height: 16px;
  background: ${COLORS.brand};
  overflow: hidden;
  margin-left: 16px;
`;

const Note = styled.div`
  border-top: 1px solid ${COLORS.grayDark};
  color: ${COLORS.grayDark};
  margin-top: 20px;
  padding: 12px 20px 0 16px;
  font-style: italic;
`;

type RowProps = { disablePadding?: boolean };
export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:last-child {
    padding-top: ${(props) => (!!props.disablePadding ? 0 : 0.2)}cm;
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  height: ${HeaderHeight}px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding-right: 0.2cm;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const HeaderInfoMain = styled.div`
  font-family: "Brandon", sans-serif;
  font-weight: 900;
  font-size: 0.5cm;
  text-transform: uppercase;
`;
const HeaderInfoSecond = styled.div`
  font-family: "Brandon", sans-serif;
  font-size: 0.4cm;
  font-weight: 400;
`;
