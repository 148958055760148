import { TimeEntryPingState } from "api";

const PingStatusColorMap: Record<TimeEntryPingState | "default", string | undefined> = {
  [TimeEntryPingState.WontFix]: "status-ok",
  [TimeEntryPingState.New]: "status-unknown",
  [TimeEntryPingState.Fixed]: "status-error",
  default: undefined,
};
const PingStatusLabelMap: Record<TimeEntryPingState | "default", string | undefined> = {
  [TimeEntryPingState.WontFix]: "Ponecháno",
  [TimeEntryPingState.New]: "Pingnuto",
  [TimeEntryPingState.Fixed]: "Opraveno",
  default: undefined,
};

export const getPingColor = (pingState?: TimeEntryPingState, changedAfterResolved?: boolean) => {
  if (!pingState) {
    return;
  }
  return PingStatusColorMap[changedAfterResolved ? TimeEntryPingState.Fixed : pingState];
};

export const getPingLabel = (pingState?: TimeEntryPingState, changedAfterResolved?: boolean) => {
  if (!pingState) {
    return;
  }
  return PingStatusLabelMap[changedAfterResolved ? TimeEntryPingState.Fixed : pingState];
};
