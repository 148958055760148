import * as React from "react";
import type { BoxProps } from "grommet";
import { Box, Text } from "grommet";

type Props = BoxProps & {
  label: string;
  children: React.ReactNode;
};

export const MetricWrapper = ({ label, children, ...rest }: Props) => {
  return (
    <Box fill={"vertical"}>
      <Box justify={"center"} align={"center"} pad={{ bottom: "xsmall" }}>
        <Text size={"xsmall"} color={"dark-4"}>
          {label}
        </Text>
      </Box>
      <Box fill={"vertical"} flex={"grow"} width={"small"} justify={"center"} {...rest}>
        {children}
      </Box>
    </Box>
  );
};
