import { memo } from "react";
import { Box, Button, Text } from "grommet";
import {
  Alert as IconAlert,
  Revert as IconRevert,
  Share as IconShare,
  StatusGood as IconStatusGood,
  Update as IconUpdate,
  Validate as IconValidated,
} from "grommet-icons";
import { TimeEntry } from "api";
import { PingButton, Tooltip } from "components";
import { useTimeEntryPingsInfo, useTimeEntryVersions, VersionDiffTable } from "containers";
import { DateStr, secondsToHoursAndMinutes } from "utils";
import { useTimeEntryItem } from "./useTimeEntryItem";

export type TimeEntriesTableItemProps = Pick<
  TimeEntry,
  "id" | "task" | "notes" | "seconds" | "forApproval" | "permalink" | "approved" | "versions" | "pings"
> & {
  projectId: string;
  projectName: string;
  tillDate: DateStr;
  workerName: string;
};

/* TODO: rename to the TimeEntriesTableItem*/
export const TimeEntryItem = memo((_: TimeEntriesTableItemProps) => {
  const { state, handlers } = useTimeEntryItem(_.id, _.projectId, _.tillDate);
  const { hasDiff, diff } = useTimeEntryVersions(_.versions);
  const { state: pingState } = useTimeEntryPingsInfo(_.pings);

  if (!state.render) return null;
  return (
    <Tooltip
      content={hasDiff && <VersionDiffTable versions={_.versions} />}
      active={hasDiff}
      direction={"row"}
      align={"center"}
      gap={"small"}
      height={{ min: "40px" }}
      pad={{ vertical: "xsmall" }}
      border={[{ color: "light-3", side: "bottom", size: "1px" }]}
      style={{ opacity: _.approved || !_.forApproval ? 0.7 : 1 }}
    >
      <Box width={{ min: "auto" }}>
        <Text size={"small"} color={"dark-6"} style={{ whiteSpace: "nowrap" }}>
          {_.projectName}
        </Text>
      </Box>

      <Box width={{ min: "90px", max: "90px" }}>
        <Text size={"small"} weight={diff.task ? "bold" : "normal"}>
          {_.task}
        </Text>
      </Box>

      <Box
        flex
        width={{ min: "120px" }}
        direction={"row"}
        align={"center"}
        gap={"xsmall"}
        style={{ overflowWrap: "anywhere" }}
      >
        <Text size={"small"} weight={diff.notes ? "bold" : "normal"}>
          {_.notes}
        </Text>
      </Box>

      <Box width={{ min: "30px", max: "30px" }}>
        <Text size={"small"} textAlign={"end"} weight={diff.time ? "bold" : "normal"}>
          {secondsToHoursAndMinutes(_.seconds)}
        </Text>
      </Box>

      <Box width={{ min: "120px", max: "120px" }}>
        <Text size={"small"} style={{ whiteSpace: "nowrap" }} truncate>
          {_.workerName}
        </Text>
      </Box>

      <Box width={{ min: "120px", max: "120px" }}>
        {_.permalink && (
          <Button
            icon={<IconShare size={"small"} />}
            size={"small"}
            title={"Zobrazit Harvest záznam"}
            label={"Harvest"}
            href={_.permalink}
            target="_blank"
          />
        )}
      </Box>
      <Box width={{ min: "330px", max: "330px" }}>
        {
          <Box direction={"row"} gap={"small"} align={"center"}>
            {!_.forApproval && !_.approved ? (
              <IconStatusGood color={"status-ok"} />
            ) : _.approved ? (
              <IconValidated color={"status-ok"} />
            ) : (
              <IconAlert color={"status-warning"} />
            )}
            <Button
              icon={<IconUpdate size={"small"} />}
              size={"small"}
              plain={false}
              onClick={handlers.handleClickRefresh}
              disabled={state.loading}
            />
            <PingButton
              state={pingState.State}
              changedAfterResolved={pingState.IsChanged}
              size={"small"}
              disabled={state.loading}
              onClick={handlers.handleClickPing}
            />
            {!_.forApproval && !_.approved ? null : _.approved ? (
              <Button
                secondary
                icon={<IconRevert size={"small"} />}
                size={"small"}
                label={"Zpět"}
                style={{ whiteSpace: "nowrap" }}
                onClick={handlers.handleClickUnapprove}
                disabled={state.loading}
              />
            ) : (
              <Button
                primary
                icon={<IconStatusGood size={"small"} />}
                size={"small"}
                label={"V poho"}
                style={{ whiteSpace: "nowrap" }}
                onClick={handlers.handleClickApprove}
                disabled={state.loading}
              />
            )}
          </Box>
        }
      </Box>
    </Tooltip>
  );
});
