import { useCallback, useMemo } from "react";
import { Box, Button, Text } from "grommet";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { getRoute } from "routes";

export const MainMenu = () =>
  useMemo(
    () => (
      <Box direction={"row"}>
        <MenuButton label={"Přehled"} route={getRoute.dashboard()} />
        <MenuButton label={"Kontrola Harvestu"} route={getRoute.timeEntries()} />
        <MenuButton label={"Sledovat Ping"} route={getRoute.pingOverview()} />
        <MenuButton label={"Reporty"} route={getRoute.reports()} />
        <MenuButton label={"Odchylky"} route={getRoute.deviations()} />
        <MenuButton label={"Fakturace"} route={getRoute.billings()} />
        <MenuButton label={"Nastavení Projektů"} route={getRoute.projectsSettings()} />
      </Box>
    ),
    []
  );

interface MenuButtonProps {
  label: string;
  route: string;
}

const MenuButton = ({ label, route }: MenuButtonProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const active = useMemo(() => !!matchPath(pathname, { path: route }), [pathname, route]);
  const a11yTitle = useMemo(() => (active ? `Zobrazená stránka, ${label}` : `Zobrazit stránku ${label}`), [
    active,
    label,
  ]);

  const handleClick = useCallback(() => push(route, { id: "" }), [push, route]);

  return (
    <Button active={active} hoverIndicator="background" onClick={handleClick} a11yTitle={a11yTitle}>
      <Box direction={"row"} align={"center"} pad={{ horizontal: "medium", vertical: "xsmall" }}>
        <Text>{label}</Text>
      </Box>
    </Button>
  );
};
