export type ValidationObj = {
  regexp: RegExp;
  message: string;
};

export const percentValidationObj: ValidationObj = {
  regexp: new RegExp("^[0-9][0-9]?$|^100$"),
  message: "povoleny jen hodnoty 0 - 100",
};

/* TODO: rename to the integerValidationObj */
export const numberValidationObj: ValidationObj = {
  regexp: new RegExp("^[0-9]+$"),
  message: "povolena pouze kladná čísla",
};

export const emailRegExp: RegExp = /^[^\s@]+@[^\s@-]+\.[^\s@]{2,3}$/;

export const isEmail = (value: string): boolean => emailRegExp.test(value);
