import { useState, useCallback } from "react";
import { useDebounce } from "react-use";

/**
 * hook for debouncing a generic value
 *
 * @param initial - generic initial value
 * @param debounceThreshold [optional] - ms threshold of debouncing value
 * @return {debounced, original, set, reset}
 */

type UseDebouncedValueReturn<T> = {
  debounced: T;
  original: T;
  set: (value: T) => void;
  reset: () => void;
};

export const useDebouncedValue = <T>(initial: T, debounceThreshold: number = 500): UseDebouncedValueReturn<T> => {
  const [original, setOriginal] = useState(initial);
  const [debounced, setDebounced] = useState(initial);

  useDebounce(() => setDebounced(original), debounceThreshold, [original]);

  const reset: () => void = useCallback(() => {
    setDebounced(initial);
    setOriginal(initial);
  }, [initial]);

  return {
    debounced,
    original,
    set: setOriginal,
    reset,
  };
};
