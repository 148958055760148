import { Task } from "api";
import { percentage } from "utils";

export type TaskStats = {
  totalEstimate: number;
  totalTimeSpend: number;
  totalDivergence: number;
};

export const getTasksStats = (tasks: Pick<Task, "estimateTotal" | "timeSpentTotal">[]): TaskStats => {
  const result: TaskStats = {
    totalEstimate: 0,
    totalTimeSpend: 0,
    totalDivergence: 0,
  };

  for (let task of tasks) {
    result.totalEstimate += task.estimateTotal || 0;
    result.totalTimeSpend += task.timeSpentTotal || 0;
  }

  /*
   * Task entity contains divergence value but TaskStats are computed on the frontend
   * more info: https://argo22.atlassian.net/browse/ARG014-188
   * */
  result.totalDivergence = Math.round(percentage(result.totalEstimate, result.totalTimeSpend));

  return result;
};
