import { Box, Button, Text } from "grommet";
import { Logout, Update, User, UserFemale } from "grommet-icons";
import { useAuth } from "api";
import { Version } from "containers";
import { useMe } from "hooks";
import { useServiceWorker } from "hooks/useServiceWorker";

export const UserMenu = () => {
  const { me } = useMe();
  const { logout } = useAuth();
  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  if (!me) return null;

  return (
    <Box direction={"row"} gap={"small"}>
      <Box direction={"row"} gap={"small"} align={"center"}>
        {me.lastName === "Cígl" ? <UserFemale size={"small"} /> : <User size={"small"} />}
        <Text size={"small"}>
          {me.firstName} {me.lastName}
        </Text>
      </Box>

      <hr style={{ boxShadow: "none", borderLeft: "1px solid", borderRight: "none" }} />

      <Version />

      {/*
      Don't wrap it to <></> cause Grofuckingmmet don't add correct spacing ("gap")
      */}
      {isUpdateAvailable && <hr style={{ boxShadow: "none", borderLeft: "1px solid", borderRight: "none" }} />}
      {isUpdateAvailable && (
        <Button
          icon={<Update size={"small"} color={"status-critical"} />}
          label={"Aktualizovat"}
          onClick={updateAssets}
          size={"small"}
          color={"status-critical"}
          margin={"none"}
          plain
          reverse
        />
      )}

      <hr style={{ boxShadow: "none", borderLeft: "1px solid", borderRight: "none" }} />

      <Button
        icon={<Logout size={"small"} />}
        label={"Odhlásit"}
        onClick={logout}
        size={"small"}
        margin={"none"}
        plain
        reverse
      />
    </Box>
  );
};
