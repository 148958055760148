import { Box } from "grommet";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { dashboardTheme } from "styles";

type Props = {
  data?: { name: string; value: number }[];
};
export const WorkloadTrend = ({ data }: Props) => {
  return (
    <Box fill={true}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <XAxis dataKey={"name"} hide />
          <YAxis dataKey={"value"} domain={["dataMin", "dataMax"]} hide />
          <Line
            type="linear"
            dataKey="value"
            strokeWidth={2}
            dot
            color={dashboardTheme.colors.text}
            stroke={dashboardTheme.colors.text}
          />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
