import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * useStateQueryParam
 *
 * Main features:
 * - set value to the query param
 * - read exist value from query param
 * - allow set value validator
 * - type-checked values (support boolean, string and values based on string)
 *
 * For better TS validation is used TS Overloads
 * doc: https://www.typescriptlang.org/docs/handbook/functions.html#overloads
 * */

/* extends string, no validator */
export function useStateQueryParam<S extends string = never>(
  name: string,
  initialValue: S
): [S | string, (value: S) => void];
/* extends string, has validator */
export function useStateQueryParam<S extends string = never>(
  name: string,
  initialValue: S,
  validator: (value: S | string) => boolean
): [S, (value: S) => void];

/* extends boolean, no validator */
export function useStateQueryParam<S extends boolean = never>(name: string, initialValue: S): [S, (value: S) => void];
/* extends boolean, has validator */
export function useStateQueryParam<S extends boolean = never>(
  name: string,
  initialValue: S,
  validator: (value: S) => boolean
): [S, (value: S) => void];

/* typed as extended string but has no initial value */
export function useStateQueryParam<S extends string | undefined = undefined>(
  name: string,
  initialValue?: S,
  validator?: (value?: S | string) => boolean
): [S | string | undefined, (value?: S) => void];

/* typed as boolean but has no initial value */
export function useStateQueryParam<S extends boolean | undefined = undefined>(
  name: string,
  initialValue?: S,
  validator?: (value?: S) => boolean
): [S | undefined, (value?: S) => void];

export function useStateQueryParam<S extends string | boolean | undefined>(
  name: string,
  initialValue?: S,
  validator?: (value?: S | string | boolean) => boolean
) {
  const paramRef = useRef(getTypedParam(name));

  const [value, setValue] = useState<S | string | boolean | undefined>(() => {
    const param = paramRef.current;
    if (param === undefined) return initialValue;

    if (initialValue === undefined) {
      if (!validator) return param;
      return validator(param) ? param : undefined;
    }

    if (typeof initialValue === "boolean") {
      if (typeof param !== "boolean") return initialValue;
      if (validator) return validator(param) ? param : initialValue;
      return param;
    }

    if (typeof initialValue === "string") {
      if (typeof param !== "string") return initialValue;
      if (validator) return validator(param) ? param : initialValue;
      return param;
    }
  });

  const history = useHistory();
  const setParam = useCallback(
    (newValue?: S | string | boolean) => {
      const searchParams = new URLSearchParams(window.location.search);
      if (newValue === undefined) searchParams.delete(name);
      if (typeof newValue === "boolean") searchParams.set(name, JSON.stringify(newValue));
      if (typeof newValue === "string") searchParams.set(name, newValue);

      paramRef.current = newValue;
      history.replace({ search: searchParams.toString() });
    },
    [history, name]
  );

  const updateValue = useCallback(
    (newValue?: S) => {
      setParam(newValue);
      setValue(newValue);
    },
    [setParam]
  );

  // Sync existed param with value
  useEffect(() => {
    if (paramRef.current === undefined || paramRef.current === value) return;
    console.log("Reset existed queryParam to the state value", { name, prevValue: paramRef.current, value });
    return setParam(value);
  }, [setParam, value, name]);

  return [value, updateValue];
}

function getTypedParam(name: string): string | boolean | undefined {
  const param = new URLSearchParams(window.location.search).get(name);
  if (!param) return undefined;
  if (param.toLowerCase() === "true" || param.toLowerCase() === "false") return JSON.parse(param);
  return param;
}
