import { useMemo } from "react";
import { Box } from "grommet";
import { Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getColorByRatio } from "helpers/dashboard";
import { dashboardTheme } from "styles";

type Props = {
  data?: { name: string; value: number | null | undefined }[];
  min?: number | null | undefined;
  max?: number | null | undefined;
};

export const BudgetUtilizationTrend = ({ data, min, max }: Props) => {
  const { colors } = dashboardTheme;

  const transformedData = useMemo(() => {
    return data?.map((row) => {
      const spent = row.value ?? 0;
      let baseBudget = min;
      if (max && (!min || spent > (baseBudget ?? 0))) {
        baseBudget = max;
      }
      const budgetUtilization = +(spent / (baseBudget ?? 0));
      return {
        ...row,
        value: budgetUtilization,
      };
    });
  }, [data, max, min]);

  const coupledData = useMemo(
    () =>
      transformedData?.map((budgetUtilization, index) =>
        index + 1 === transformedData.length ? [budgetUtilization] : [budgetUtilization, transformedData[index + 1]]
      ),
    [transformedData]
  );

  return (
    <Box fill={true}>
      <ResponsiveContainer>
        <LineChart>
          <XAxis dataKey={"name"} type={"category"} allowDuplicatedCategory={false} hide />
          <YAxis domain={["dataMin", "dataMax"]} hide />
          <ReferenceLine y={1.0} stroke={colors.text} strokeDasharray="3 3" strokeWidth={2} />
          {coupledData?.map((s, i) => {
            const color = getColorByRatio(s[0].value);
            return (
              <Line key={i} data={s} type="linear" dataKey="value" stroke={color} fill={color} strokeWidth={2} dot />
            );
          })}
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
