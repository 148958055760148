import * as React from "react";
import { useMemo } from "react";
import { CheckBox } from "grommet";
import { RouteComponentProps } from "react-router-dom";
import { useTitle } from "react-use";
import styled from "styled-components";
import { Language } from "api";
import { ReactComponent as Logo } from "assets/logo_rich_dark.svg";
import { Spinner } from "components";
import { getSpentTimeStatusByPercents, projectReportTranslations, SpentTimeStatus, TaskStats } from "helpers";
import { ProjectReportParams } from "routes";
import { secondsToHoursWith2DP } from "utils";
import { TaskForProjectReportNode, useProjectReport } from "./useProjectReport";

interface Props extends RouteComponentProps<ProjectReportParams> {}

export const ProjectReport = ({ match }: Props) => {
  const { state, data, handlers } = useProjectReport(match.params.project);
  useTitle("Report - Přehled odhadu a realizované práce");
  const language = useMemo(() => data.project?.language || Language.Cs, [data.project?.language]);
  const t = React.useMemo(() => projectReportTranslations[language], [language]);

  if (state.loading) return <Spinner />;

  return (
    <>
      <BillableSwitch>
        <CheckBox
          checked={state.billable}
          onChange={handlers.handleClickCheckboxBillable}
          label={"Fakturovatelný pohled"}
        />
      </BillableSwitch>
      <Content>
        <Logo width={190} />

        <Title>{t.title}</Title>

        <TableWrapper>
          <TableTitle>{t.inProgressTitle}</TableTitle>
          <TasksTable tasks={data.tasksInProgress} stats={data.statsInProgress} language={language} />
        </TableWrapper>

        <TableWrapper>
          <TableTitle>{t.todoTitle}</TableTitle>
          <TasksTable tasks={data.tasksPending} stats={data.statsPending} language={language} />
        </TableWrapper>

        <TableWrapper>
          <TableTitle>{t.doneTitle}</TableTitle>
          <TasksTable tasks={data.tasksDone} stats={data.statsDone} language={language} />
        </TableWrapper>
      </Content>
    </>
  );
};

type TasksTableProps = {
  tasks: TaskForProjectReportNode[];
  stats: TaskStats;
  language?: Language;
};

const TasksTable = ({ tasks, stats, language = Language.Cs }: TasksTableProps) => {
  const totalStatus = getSpentTimeStatusByPercents(stats.totalDivergence);
  const totalSpendTimeColor = _getSpendTimeColor(totalStatus);
  const t = React.useMemo(() => projectReportTranslations[language], [language]);

  return (
    <Table>
      <TableThead>
        <TableHeaderRow>
          <TableHeaderCell>{t.column.taskId}</TableHeaderCell>
          <TableHeaderCell>{t.column.taskTitle}</TableHeaderCell>
          <TableHeaderCell alignRight>{t.column.estimate}</TableHeaderCell>
          <TableHeaderCell alignRight>{t.column.timeSpent}</TableHeaderCell>
          <TableHeaderCell alignRight>{t.column.difference}</TableHeaderCell>
          <TableHeaderCell>{t.column.state}</TableHeaderCell>
          <TableHeaderCell>{t.column.type}</TableHeaderCell>
        </TableHeaderRow>
      </TableThead>

      {tasks.length > 0 && (
        <div>
          {tasks.map((task) => {
            return (
              <TableBodyRow key={task.id}>
                <TableBodyCell>{task.code}</TableBodyCell>
                <TableBodyCell wrapText>{task.title}</TableBodyCell>
                <TableBodyCell alignRight>
                  {task.estimateTotal ? secondsToHoursWith2DP(task.estimateTotal) + ` ${t.hoursUnitText}` : "-"}
                </TableBodyCell>
                <TableBodyCell alignRight>
                  {task.timeSpentTotal ? secondsToHoursWith2DP(task.timeSpentTotal) + ` ${t.hoursUnitText}` : "-"}
                </TableBodyCell>
                <TableBodyCell alignRight bold>
                  {task.divergence ? (
                    <span style={{ color: _getSpendTimeColor(getSpentTimeStatusByPercents(task.divergence)) }}>
                      {task.divergence} %
                    </span>
                  ) : (
                    "-"
                  )}
                </TableBodyCell>
                <TableBodyCell>{task.state}</TableBodyCell>
                <TableBodyCell>{task.type}</TableBodyCell>
              </TableBodyRow>
            );
          })}

          {/* table stats */}
          <TableBodyRow key={"stats"} highlight>
            <TableBodyCell />
            <TableBodyCell>{t.total}</TableBodyCell>
            <TableBodyCell alignRight>
              {stats.totalEstimate ? secondsToHoursWith2DP(stats.totalEstimate) + ` ${t.hoursUnitText}` : "-"}
            </TableBodyCell>
            <TableBodyCell alignRight>
              {stats.totalTimeSpend ? secondsToHoursWith2DP(stats.totalTimeSpend) + ` ${t.hoursUnitText}` : "-"}
            </TableBodyCell>
            <TableBodyCell alignRight bold>
              {stats.totalDivergence ? (
                <span style={{ color: totalSpendTimeColor }}>{stats.totalDivergence} %</span>
              ) : (
                "-"
              )}
            </TableBodyCell>
            <TableBodyCell />
            <TableBodyCell />
          </TableBodyRow>
        </div>
      )}
    </Table>
  );
};

/*
 * Theme
 * */
const COLORS = {
  brand: "#ffe014",

  text: "black",

  success: "rgb(111, 171, 114)",
  warning: "rgb(231, 150, 71)",

  grayLight: "rgb(239,239,239)",
  grayDark: "rgb(217,217,217)",
};

/*
 * Helpers
 * */
const _getSpendTimeColor = (status: SpentTimeStatus): string =>
  status === SpentTimeStatus.OK ? COLORS.success : COLORS.warning;

/*
 * Components
 * */
const Content = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 0.25cm;
  color: ${COLORS.text};
`;

const Title = styled.div`
  font-size: 0.32cm;
  font-weight: bold;
  text-transform: uppercase;

  background-color: ${COLORS.brand};

  padding: 0.25cm 0.2cm 0.2cm 0.2cm;
`;

/* table */
const cssRowChildSizes = `
  /* all cells */
  > * {
    padding: 0.25cm 0.2cm 0.05cm 0.2cm;
  }
  
  /* Jira ID */
  > *:nth-child(1) {
    width: 1.9cm;
  }
  
  /* Issue Name */
  > *:nth-child(2) {
    flex: 1;
  }
  
  /* Estimate, Spend Time */
  > *:nth-child(3),
  > *:nth-child(4) {
    width: 1.6cm;
  }
  
  /* Deviation */
  > *:nth-child(5) {
    width: 1.4cm;
  }
  
  /* State */
  > *:nth-child(6) {
    width: 1.45cm;
  }
  
  /* Issue Type */
  > *:nth-child(7) {
    width: 1.8cm;
  }
`;

const TableWrapper = styled.div`
  margin-top: 0.8cm;
`;

const TableTitle = styled.div`
  font-size: 0.3cm;
  font-weight: bold;

  padding-left: 0.2cm; // same as padding-left at cssRowChildSizes const

  margin-bottom: 0.05cm;
`;

const Table = styled.div`
  width: 100%;
`;

const TableThead = styled.div`
  width: 100%;
`;

const TableHeaderRow = styled.div`
  background-color: ${COLORS.grayDark};
  display: flex;

  ${cssRowChildSizes}
`;

type TableHeaderCellProps = {
  alignRight?: boolean;
};
const TableHeaderCell = styled.div<TableHeaderCellProps>`
  font-weight: bold;
  text-align: ${(props) => (props.alignRight ? "right" : "left")};

  white-space: nowrap;
`;

type TableBodyRowProps = {
  highlight?: boolean;
};
const TableBodyRow = styled.div<TableBodyRowProps>`
  display: flex;

  background-color: ${(props) => (props.highlight ? COLORS.grayLight : "unset")};

  ${cssRowChildSizes}
`;

type TableBodyCellProps = {
  wrapText?: boolean;
  alignRight?: boolean;
  bold?: boolean;
};
const TableBodyCell = styled.div<TableBodyCellProps>`
  white-space: ${(props) => (props.wrapText ? "normal" : "nowrap")};
  text-align: ${(props) => (props.alignRight ? "right" : "left")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const BillableSwitch = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  @media print {
    visibility: hidden;
  }
`;
