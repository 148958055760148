import { useCallback, useMemo } from "react";
import Number from "big.js";
import {
  Billable,
  GroupBy,
  Language,
  ProjectBillingFieldsFragment,
  resolveError,
  TimeEntriesBillingFieldsFragment,
  useResetTimeEntryMutation,
  useUpdateTimeEntryMutation,
} from "api";
import { projectInvoiceTranslations } from "helpers";

/**
 * Constants
 */
const emptyInvoiceData = { breakdown: { summary: { maxEpicValueHours: 0, maxEpicValuePrice: 0, hours: 0, price: 0 } }, workload: {} };

/**
 * Types
 */
export type TasksDataType = {
  tasks: TimeEntry[];
  summary: {
    hours: number;
    price: number;
  };
};
export type InvoiceData = {
  breakdown: {
    summary: {
      hours: number;
      price: number;
      maxEpicValueHours: number;
      maxEpicValuePrice: number;
      pmsFee?: {
        hours: number;
        price: number;
      };
    };
  };
  workload: {
    [key: string]: TasksDataType;
  };
};
export type SortedInvoiceData = Omit<InvoiceData, "workload"> & {
  workload: {
    title: string;
    data: TasksDataType;
  }[];
};
type GroupedEntries = {
  [key: string]: TimeEntry[];
};

type SummarizedEntries = {
  [key: string]: { tasks: TimeEntry[]; summary: { hours: number; price: number } };
};

export type ProjectInvoiceDataProps = {
  project: ProjectBillingFieldsFragment;
  projectTimeEntries: TimeEntriesBillingFieldsFragment[];
};
type TimeEntry = TimeEntriesBillingFieldsFragment;

export const useProjectInvoiceData = ({ project, projectTimeEntries }: ProjectInvoiceDataProps) => {
  const t = useMemo(() => projectInvoiceTranslations[project?.language ?? Language.Cs], [project]);
  const [updateTimeEntryMutation] = useUpdateTimeEntryMutation({ onError: resolveError });
  const [resetTimeEntryMutation] = useResetTimeEntryMutation({ onError: resolveError });

  const OPERATIONAL = project.pmFee ? t.project.operational_without_pm : t.project.operational; // name operational section without pm when pm fee is included
  const UNASSIGNED = t.project.unassigned;

  // count each group summaries
  const summarizeGroupedEntries = useCallback((entries: GroupedEntries) => {
    let summarized: SummarizedEntries = {};
    Object.entries(entries).forEach(([key, value]) => {
      summarized = {
        ...summarized,
        [key]: {
          tasks: value,
          summary: {
            hours: value
              .filter((timeEntry) => timeEntry.billable === Billable.Yes)
              .reduce(
                (acc, val) =>
                  parseFloat(
                    Number(acc)
                      .plus(Number(val.secondsBillable ?? val.seconds).div(Number(3600)))
                      .toString()
                  ),
                0
              ),
            price: value
              .filter((timeEntry) => timeEntry.billable === Billable.Yes)
              .reduce(
                (acc, val) =>
                  parseFloat(
                    Number(acc)
                      .plus(
                        Number(val.billableRate ?? 0).times(
                          Number(val.secondsBillable ?? val.seconds).div(Number(3600))
                        )
                      )
                      .toString()
                  ),
                0
              ),
          },
        },
      };
    });
    return summarized;
  }, []);

  // count project level summaries
  const summarizeReport = useCallback((entries: SummarizedEntries): InvoiceData => {
    return {
      breakdown: {
        summary: {
          hours: Object.values(entries).reduce<number>(
            (acc, item) => parseFloat(Number(acc).plus(Number(item.summary.hours)).toString()),
            0
          ),
          price: Object.values(entries).reduce<number>(
            (acc, item) => parseFloat(Number(acc).plus(Number(item.summary.price)).toString()),
            0
          ),
          maxEpicValueHours: Object.values(entries).reduce(
            (prev, current) => (prev.summary?.hours > current.summary.hours ? prev : current),
            { summary: { hours: 0 } }
          ).summary.hours,
          maxEpicValuePrice: Object.values(entries).reduce(
            (prev, current) => (prev.summary?.price > current.summary.price ? prev : current),
            { summary: { price: 0 } }
          ).summary.price,
        },
      },
      workload: {
        ...entries,
      },
    };
  }, []);

  const includePmsFee = useCallback((invoiceData: InvoiceData, pmPercent: number, pmPrice: number): InvoiceData => {
    const pmHours = Number(pmPercent).div(Number(100)).times(Number(invoiceData.breakdown.summary.hours));
    return {
      ...invoiceData,
      breakdown: {
        summary: {
          ...invoiceData.breakdown.summary,
          pmsFee: {
            hours: parseFloat(pmHours.toString()),
            price: parseFloat(Number(pmHours).times(Number(pmPrice)).toString()),
          },
        },
      },
    };
  }, []);

  // group timeEntries by their taskModels
  const groupedInvoiceDataByTask = useCallback(
    (entries: TimeEntry[]): GroupedEntries => {
      let grouped: GroupedEntries = {};
      entries.forEach((entry) => {
        const property = !entry.taskModel
          ? OPERATIONAL
          : entry.taskModel.parent
          ? entry.taskModel.parent.parent
            ? `${entry.taskModel.parent.parent.code}: ${entry.taskModel.parent.parent.title}`
            : `${entry.taskModel.parent.code}: ${entry.taskModel.parent.title}`
          : `${entry.taskModel.code}: ${entry.taskModel.title}`;
        if (!grouped.hasOwnProperty(property)) {
          grouped = {
            ...grouped,
            [property]: [],
          };
        }
        grouped[property].push(entry);
      });
      return grouped;
    },
    [OPERATIONAL]
  );

  // group timeEntries by workers
  const groupedInvoiceDataByWorker = useCallback(
    (entries: TimeEntry[]): GroupedEntries => {
      let grouped: GroupedEntries = {};
      entries.forEach((entry) => {
        const property = entry.worker ? `${entry.worker.firstName} ${entry.worker.lastName}` : UNASSIGNED;
        if (!grouped.hasOwnProperty(property)) {
          grouped = {
            ...grouped,
            [property]: [],
          };
        }
        grouped[property].push(entry);
      });
      return grouped;
    },
    [UNASSIGNED]
  );

  const sortWorkloadDataByCode = useCallback(
    (data: InvoiceData, forPreview?: boolean) => {
      const codeMather = new RegExp(/.*:/); //sort by project code eg ARG014-255:
      const sorted = Object.entries(data.workload).sort((a, b) => {
        const codeA = a[0].match(codeMather)?.pop()?.trim() ?? "";
        const codeB = b[0].match(codeMather)?.pop()?.trim() ?? "";
        // sort operational specifically
        if (a[0] === OPERATIONAL) {
          return forPreview ? -1 : 1;
        }
        if (b[0] === OPERATIONAL) {
          return forPreview ? 1 : -1;
        }
        return codeA > codeB ? 1 : -1;
      });

      const sortedInvoiceData: SortedInvoiceData = {
        ...data,
        workload: sorted.map((item) => ({
          title: item[0],
          data: item[1],
        })),
      };
      return sortedInvoiceData;
    },
    [OPERATIONAL]
  );

  // filter tasks by billable property
  const getBillableData = useCallback((data: InvoiceData) => {
    const billableData: InvoiceData = {
      breakdown: data.breakdown,
      workload: {},
    };
    for (let [key, tasksPayload] of Object.entries(data.workload)) {
      const billableTasks = tasksPayload.tasks.filter((task) => task.billable === Billable.Yes);
      if (billableTasks.length > 0) {
        billableData.workload[key] = {
          ...tasksPayload,
          tasks: tasksPayload.tasks.filter((task) => task.billable === Billable.Yes),
        };
      }
    }
    return billableData;
  }, []);

  const ProcessedData = useMemo((): InvoiceData => {
    if (!project || !projectTimeEntries) {
      return emptyInvoiceData;
    }
    const timeEntries: TimeEntry[] = projectTimeEntries;
    const groupedData =
      project.groupBy === GroupBy.Task
        ? groupedInvoiceDataByTask(timeEntries)
        : groupedInvoiceDataByWorker(timeEntries);
    const summarized = summarizeGroupedEntries(groupedData);
    const summarizedReport = summarizeReport(summarized);
    const invoiceData =
      project.pmFee && project.pmPercent && project.pmPrice
        ? includePmsFee(summarizedReport, project.pmPercent, project.pmPrice)
        : summarizedReport;
    return invoiceData;
  }, [
    groupedInvoiceDataByTask,
    groupedInvoiceDataByWorker,
    includePmsFee,
    project,
    projectTimeEntries,
    summarizeGroupedEntries,
    summarizeReport,
  ]);

  const InvoiceData = useMemo(() => sortWorkloadDataByCode(getBillableData(ProcessedData)), [
    ProcessedData,
    getBillableData,
    sortWorkloadDataByCode,
  ]);
  const InvoicePreviewData = useMemo(() => sortWorkloadDataByCode(ProcessedData, true), [
    ProcessedData,
    sortWorkloadDataByCode,
  ]);

  const getOldestTimeEntryDate = useCallback(() => {
    if (!projectTimeEntries.length) {
      return;
    }
    return projectTimeEntries?.reduce((c, n) => (n.spentAt < c.spentAt ? n : c)).spentAt;
  }, [projectTimeEntries]);

  const updateTimeEntry = useCallback(
    async (id: string, billable: Billable, hours?: string) => {
      await updateTimeEntryMutation({
        variables: {
          input: {
            timeEntries: [
              {
                timeEntry: id,
                billable: billable,
                secondsBillable: hours ? _hoursToSeconds(hours) : undefined,
              },
            ],
          },
        },
      });
    },
    [updateTimeEntryMutation]
  );

  const updateTimeEntries = useCallback(
    async (timeEntries: { id: string; billable: Billable; hours?: string }[]) => {
      const sanitized = timeEntries.map((timeEntry) => ({
        timeEntry: timeEntry.id,
        billable: timeEntry.billable,
        secondsBillable: timeEntry.hours ? _hoursToSeconds(timeEntry.hours) : undefined,
      }));
      await updateTimeEntryMutation({
        variables: {
          input: {
            timeEntries: sanitized,
          },
        },
      });
    },
    [updateTimeEntryMutation]
  );

  const resetTimeEntry = useCallback(
    async (id: string) => {
      await resetTimeEntryMutation({
        variables: {
          input: {
            timeEntries: [id],
          },
        },
      });
    },
    [resetTimeEntryMutation]
  );

  const resetTimeEntries = useCallback(
    async (ids: string[]) => {
      await resetTimeEntryMutation({
        variables: {
          input: {
            timeEntries: ids,
          },
        },
      });
    },
    [resetTimeEntryMutation]
  );

  return {
    data: {
      InvoiceData,
      InvoicePreviewData,
    },
    handlers: {
      updateTimeEntry,
      updateTimeEntries,
      resetTimeEntry,
      resetTimeEntries,
    },
    helpers: {
      getOldestTimeEntryDate,
    },
  };
};

/* example: "1,50" -> 5400 */
const _hoursToSeconds = (hours: string): number | undefined => {
  const hoursString = hours?.replace(",", ".");
  return hoursString ? parseFloat(hoursString) * 3600 : undefined;
};
