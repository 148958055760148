import { useEffect, useState } from "react";
import { matchPath, RouteComponentProps } from "react-router-dom";
import { useAuth } from "api";
import { routes } from "routes";

export const useInternal = ({ history, location }: Omit<RouteComponentProps, "match">) => {
  const [render, setRender] = useState(false);

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    /** if not logged in, redirect to login */
    if (!isLoggedIn()) return history.replace(routes.login);

    /** redirect to overview from dashboard root url (after login) */
    const isDashBoardRoot = !!matchPath(location.pathname, { path: routes.internal, exact: true });
    if (isDashBoardRoot) history.replace(routes.dashboard);
  }, [history, isLoggedIn, location.pathname]);

  useEffect(() => {
    setRender(true);
  }, []);

  return { render };
};
