import { useMemo, useState } from "react";
import { Accordion, AccordionPanel, Box, Button, DateInput, Text } from "grommet";
import { RouteComponentProps } from "react-router-dom";
import { useTitle } from "react-use";
import { MainContent, ProjectStatusIndicator, Sidebar, Spinner } from "components";
import { TimeEntriesTable } from "containers";
import { dateInputGlobalProps } from "helpers";
import { TimeEntriesParams } from "routes";
import { formatTime } from "utils";
import { useTimeEntries } from "./useTimeEntries";

interface Props extends RouteComponentProps<TimeEntriesParams> {}

export const TimeEntries = ({ match }: Props) => {
  const selectedProjectId = useMemo<string>(() => match.params.project || "", [match.params.project]);
  const { data, state, handlers } = useTimeEntries(selectedProjectId);
  useTitle("Kontrola Harvestu");

  const [activeAccordions, setActiveAccordions] = useState<number[]>([0]);

  return (
    <Box flex direction={"row"}>
      <Sidebar
        defaultState={"open"}
        footer={
          data.selectedProject ? (
            <Box pad={{ horizontal: "small" }} align={"center"} gap={"xsmall"}>
              {/* Last approved info */}
              {data.selectedProject.approvedAt && (
                <Text color={"dark-3"} size={"xsmall"} textAlign={"center"}>
                  Hozeno do pohody {formatTime(data.selectedProject.approvedAt, "D.M.Y HH:mm")}
                  <br />
                  pro datum <strong>{formatTime(data.selectedProject.approvedTill, "D.M.Y")}</strong>
                </Text>
              )}

              {state.loadingApproveProject ? (
                <Spinner />
              ) : (
                <Button
                  disabled={!state.canApproveSelectedProject}
                  primary
                  label={"Označit projekt v poho"}
                  onClick={handlers.handleClickApproveSelectedProject}
                />
              )}
            </Box>
          ) : undefined
        }
      >
        <Box pad={"small"}>
          <DateInput
            {...dateInputGlobalProps}
            value={state.tillDate}
            onChange={handlers.handleChangeInputTillDate}
            buttonProps={{
              label: `Do ${state.localizedTillDate}`,
            }}
          />
        </Box>

        {state.loadingProjects && (
          <Box pad={"small"}>
            <Spinner />
          </Box>
        )}
        <Accordion multiple gap={"small"} activeIndex={activeAccordions} onActive={setActiveAccordions}>
          {!state.loadingProjects &&
            data.projectsByPmAndClients?.map((pmSummary, index) => (
              <AccordionPanel
                key={`pm-${index}`}
                header={
                  <Box direction={"row"} justify={"between"} pad={"small"}>
                    <Box alignContent={"center"} justify={"center"}>
                      <Text weight={"bold"} size={"medium"}>
                        {pmSummary.projectManager ? (
                          <>
                            {pmSummary.projectManager?.firstName} {pmSummary.projectManager?.lastName}
                          </>
                        ) : (
                          "Nezařazené"
                        )}
                      </Text>
                    </Box>
                    <Box>
                      <ProjectStatusIndicator
                        isApproved={pmSummary.allResolved}
                        forApproval={pmSummary.timeEntriesForApproval}
                        iconSize={"28px"}
                      />
                    </Box>
                  </Box>
                }
              >
                <Box gap={"small"}>
                  {[...pmSummary.clients.entries()].map(([client, projects]) => (
                    <Box key={client} pad={{ horizontal: "small" }}>
                      <Box>
                        <Text size={"xsmall"} color={"dark-4"} weight={"bold"}>
                          {client}
                        </Text>
                      </Box>
                      {projects.map((project) => (
                        <Box
                          key={project.id}
                          direction={"row"}
                          gap={"small"}
                          pad={{ horizontal: "medium", vertical: "small" }}
                          justify={"between"}
                          hoverIndicator={{
                            color: "active",
                            opacity: "weak",
                          }}
                          background={{
                            color: data.selectedProject?.id === project?.id ? "active" : "",
                            opacity: "medium",
                          }}
                          onClick={() => handlers.handleSelectProject(project?.id ?? "")}
                        >
                          <Box>{project?.name}</Box>
                          <Box>
                            <ProjectStatusIndicator
                              isApproved={!project?.forApproval}
                              forApproval={project?.timeEntries?.forApprovalCount ?? 0}
                            />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </AccordionPanel>
            ))}
        </Accordion>
      </Sidebar>

      <MainContent>
        {state.loadingApproveProject && <Spinner />}
        {!state.loadingApproveProject && !selectedProjectId && (
          <Box pad={"medium"} align={"center"}>
            Vyber projekt ke kontrole.
          </Box>
        )}
        {!state.loadingApproveProject && selectedProjectId && (
          <TimeEntriesTable projectId={selectedProjectId} tillDate={state.tillDate} />
        )}
      </MainContent>
    </Box>
  );
};
