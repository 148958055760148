import { useCallback, useMemo } from "react";
import { BillingState, mapConnection, ProjectsForBillingsQuery, resolveError, useProjectsForBillingsQuery } from "api";
import { SortDirection, useSorting, useStateQueryParam } from "hooks";
import { DateStr, dateStrToDate, isValidDate, toDateStr } from "utils";

type Project = NonNullable<ProjectsForBillingsQuery["projects"]>["edges"][0]["node"] & { responsiblePerson?: string };
// states of billing tagged as done
const resolvedStates = [BillingState.Invoice, BillingState.InvoiceNextTime, BillingState.NoInvoice];

export const useBillings = () => {
  const [period, setPeriod] = useStateQueryParam<DateStr>(
    "period",
    toDateStr(new Date(new Date().setMonth(new Date().getMonth() - 1))),
    isValidDate
  );

  const periodDate = useMemo(() => {
    const periodDateObj = dateStrToDate(period);

    return {
      year: periodDateObj.get("year"),
      month: periodDateObj.get("month"),
    };
  }, [period]);

  const { data: projectsData, loading: projectsLoading, error: projectsError } = useProjectsForBillingsQuery({
    variables: {
      year: periodDate.year,
      month: periodDate.month + 1, // BE works with 1-12 month number format -- we need to add +1 for JS months
    },
    onError: resolveError,
  });

  const sortHandler = useSorting<keyof Project>("responsiblePerson");

  const projects = useMemo(() => mapConnection(projectsData?.projects), [projectsData]);

  const sortProjectFn = useCallback(
    (a: Project, b: Project) => {
      if (!a || !b) {
        return 1;
      }
      const aSortingProp = a[sortHandler.sortBy] ?? "";
      const bSortingProp = b[sortHandler.sortBy] ?? "";

      const isBillingState = sortHandler.sortBy === "billing";
      const isResponsiblePerson = sortHandler.sortBy === "responsiblePerson";
      const isClient = sortHandler.sortBy === "client";

      const x = isResponsiblePerson
        ? `${a.billing?.responsiblePerson}`
        : isBillingState
        ? `${a.billing?.state ?? ""}`
        : isClient
        ? `${a.client?.name ?? ""}`
        : aSortingProp;
      const y = isResponsiblePerson
        ? `${b.billing?.responsiblePerson}`
        : isBillingState
        ? `${b.billing?.state ?? ""}`
        : isClient
        ? `${b.client?.name ?? ""}`
        : bSortingProp;

      const dir = sortHandler.direction === SortDirection.ASC ? 1 : -1;
      return x > y ? dir : -1 * dir;
    },
    [sortHandler.direction, sortHandler.sortBy]
  );

  const blockingProjects = useMemo(() => {
    const filtered = projects.filter((project) => project.billing && project.billing.state === BillingState.Todo);
    return filtered.sort(sortProjectFn);
  }, [projects, sortProjectFn]);

  const unresolvedProjects = useMemo(() => {
    const filtered = projects.filter(
      (project) =>
        project.billing && !resolvedStates.includes(project.billing.state) && !blockingProjects.includes(project)
    );
    return filtered.sort(sortProjectFn);
  }, [projects, sortProjectFn]);

  const resolvedProjects = useMemo(() => {
    const filtered = projects.filter((project) => project.billing && resolvedStates.includes(project.billing.state));
    return filtered.sort(sortProjectFn);
  }, [projects, sortProjectFn]);

  return {
    data: {
      blockingProjects,
      unresolvedProjects,
      resolvedProjects,
    },
    state: {
      loading: projectsLoading,
      projectsError,
      period,
    },
    handlers: {
      sortHandler,
      setPeriod,
    },
  };
};
