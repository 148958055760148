import { useCallback } from "react";
import { Button, ButtonProps } from "grommet";
import { useHistory } from "react-router-dom";

type LinkProps = ButtonProps & {
  route: string;
  backLink?: boolean;
};

export const Link = ({ label, route, backLink, ...rest }: LinkProps) => {
  const { push, goBack } = useHistory();
  const handleClick = useCallback(() => (backLink ? goBack() : push(route, { foo: "ahoj" })), [
    backLink,
    goBack,
    push,
    route,
  ]);

  return <Button onClick={handleClick} label={label} {...rest} />;
};
