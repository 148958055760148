import Number from "big.js";
import moment from "moment";
import { formatNumberToTwoDecimalPlaces } from "utils/format";
export const secondsToHoursAndMinutes = (seconds: number): string => {
  const isNegative: boolean = Math.sign(seconds) === -1;
  const absSeconds: number = Math.abs(seconds);

  const hours = Math.floor(absSeconds / 3600);
  const minutes = Math.round(absSeconds / 60) % 60;

  // output format - H:mm
  return `${isNegative ? "-" : ""}${hours}:${minutes.toString().padStart(2, "0")}`;
};

/**
 * seconds to hours with 2 decimal places
 */
export const secondsToHoursWith2DP = (seconds: number): string =>
  formatNumberToTwoDecimalPlaces(parseFloat(Number(seconds).div(Number(3600)).toString()));

/**
 * format hours to hours with 2 decimal places
 */
export const hoursToHoursWith2DP = (hours: number): string => formatNumberToTwoDecimalPlaces(hours);

/*
 * Reformat date to the new format
 * - local timezone is used
 * */
export const formatTime = (time: string, newFormat: string): string | undefined => {
  const result = moment.utc(time).local().format(newFormat);

  if (!moment(result, newFormat, true).isValid()) {
    console.warn("Invalid date or format", time, newFormat);
    return;
  }

  return result;
};

export const getDateTimeString = (date: Date) =>
  `${new Date(date).toLocaleTimeString()} / ${new Date(date).toLocaleDateString()} (${moment(date).fromNow()})`;
