type EnvKey =
  | "NODE_ENV"
  | "REACT_APP_API_URL"
  | "REACT_APP_MAP_API_KEY"
  | "REACT_APP_SENTRY_DSN"
  | "REACT_APP_SENTRY_RELEASE"
  | "REACT_APP_VERSION"
  | "REACT_APP_CHANGELOG_URL";

export const getEnv = (key: EnvKey): string => {
  const value = process.env[key];

  if (value !== undefined) return value;

  throw new Error(`ENV variable "${key}" is not defined.`);
};
