/*
 * Theme
 * */
export const COLORS = {
  brand: "#F2DC00",

  text: "black",

  bug: "#b22222",
  design: "#EBBC00",
  programming: "#0358A8",
  consultations: "#84BD00",
  analysis: "#B90078",
  pm: "#ce008d",
  maintenance: "#9400d3",
  task: "#808080",
  grayLight: "#EDEDED",
  grayDark: "#999999",
};

export const HeaderHeight = 50;

/**
 * Helpers
 */
export const getColorByType = (type: string) => {
  switch (type.toLowerCase()) {
    case "programming":
      return COLORS.programming;
    case "graphic design & ux":
      return COLORS.design;
    case "pm":
      return COLORS.pm;
    case "maintenance":
    case "deployment":
    case "cr & qa":
      return COLORS.maintenance;
    case "bug":
      return COLORS.bug;
    case "consultations":
      return COLORS.consultations;
    case "analysis":
      return COLORS.analysis;
    default:
      return COLORS.task;
  }
};
