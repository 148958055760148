import * as React from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ProjectBillingReport } from "containers";
import { Modal } from "layouts";

export const ProjectBillingReportPage = () => {
  const history = useHistory();

  const handleScreenExit = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Modal>
      <ProjectBillingReport onExit={handleScreenExit} />
    </Modal>
  );
};
