import { Box, Text } from "grommet";
import { Calendar } from "grommet-icons";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { TimeEntry } from "api";
import { Spinner } from "components";
import { TimeEntryItem } from "containers";
import { DateStr, formatTime } from "utils";
import { useTimeEntriesTable } from "./useTimeEntriesTable";

interface TimeEntriesTableProps {
  projectId: string;
  tillDate: DateStr;
}

export const TimeEntriesTable = ({ projectId, tillDate }: TimeEntriesTableProps) => {
  const { state, data, handlers } = useTimeEntriesTable(projectId, tillDate);

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading: state.loadingMoreTasks,
    hasNextPage: data.hasNextPage,
    onLoadMore: handlers.handleLoadMoreTimeEntries,
    threshold: 500,
    checkInterval: 100,
  });

  if (state.loading) return <Spinner />;

  /* Project has no entries for review */
  if (!data.totalTimeEntries) {
    return (
      <Box pad={"medium"} align={"center"}>
        <Text size={"small"}>Všechny záznamy jsou v pohodě.</Text>
        {data.project?.forApproval && <Text size={"small"}>Zbývá jen označit projekt jako v poho.</Text>}
      </Box>
    );
  }

  return (
    <Box flex={"grow"} direction={"column"} ref={infiniteRef}>
      {data.trackedDates.map((day, index) => {
        return (
          <Box key={day} margin={{ top: index > 0 ? "large" : "0px" }}>
            <Box direction="row" align="center" gap={"small"}>
              <Calendar size="medium" />
              <Text size={"small"} weight={"bold"}>
                {formatTime(day, "D.M.Y")}
              </Text>
            </Box>

            <Box>
              {data.timeEntriesMap.get(day)?.map((timeEntry) => {
                return (
                  <TimeEntryItem
                    key={timeEntry.id}
                    id={timeEntry.id}
                    projectName={`[${data.project?.code}] ${data.project?.name}`}
                    projectId={projectId}
                    tillDate={tillDate}
                    seconds={timeEntry.seconds}
                    task={timeEntry.task}
                    forApproval={timeEntry.forApproval}
                    approved={timeEntry.approved}
                    notes={timeEntry.notes}
                    workerName={`${timeEntry.worker?.firstName} ${timeEntry.worker?.lastName}`}
                    permalink={timeEntry.permalink}
                    versions={timeEntry.versions as TimeEntry["versions"]}
                    pings={timeEntry.pings}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}

      {!!data.totalTimeEntries && (
        <Box pad={"small"}>
          {/*Page info*/}
          <Text>
            Načteno záznamů: {data.loadedTimeEntries} Celkem záznamů: {data.totalTimeEntries}
          </Text>
          {/*Fetch more indicator*/}
          {data.hasNextPage && (
            <div style={{ visibility: state.loadingMoreTasks ? "visible" : "hidden" }}>
              <Spinner />
            </div>
          )}
        </Box>
      )}
    </Box>
  );
};
