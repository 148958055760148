import { DataTableProps } from "grommet";

export const dataTableBorder: DataTableProps["border"] = {
  header: {
    side: "bottom",
    color: "light-6",
  },
  body: {
    side: "horizontal",
    color: "light-3",
  },
};
