import { Currency, Language } from "api";
import { DateStr } from "utils/dateStr";

/**
 * price with currency formatted by currency locales
 * @param price
 * @param currency
 */
export const formatPrice = (price: number, currency: Currency = Currency.Czk) => {
  const localeByCurrency: Record<Currency, string> = {
    [Currency.Czk]: "cs-CZ",
    [Currency.Usd]: "en-US",
    [Currency.Eur]: "de-DE",
    [Currency.Gbp]: "en-GB",
  };
  const options = { style: "currency", currency, minimumFractionDigits: 0, maximumFractionDigits: 0 };
  const priceFormat = new Intl.NumberFormat(localeByCurrency[currency], options);
  return priceFormat.format(price);
};

/**
 * format number to Two decimal places with czech localization
 * @param number
 */
export const formatNumberToTwoDecimalPlaces = (number: number) => {
  const numberFormat = new Intl.NumberFormat("cs-CZ", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return numberFormat.format(number);
};

interface FormatDateParams {
  date?: Date | DateStr | string;
  language?: Language;
}
/**
 * format date to Two decimal places with czech localization
 * @param date
 * @param language
 */
export const formatDate = ({ date = new Date(), language = Language.Cs }: FormatDateParams) => {
  const localeByLanguage: Record<Language, string> = {
    [Language.Cs]: "cs-CZ",
    [Language.En]: "en-US",
  };
  const dateFormat = new Intl.DateTimeFormat(localeByLanguage[language]);

  const dateToParse = typeof date === "string" ? new Date(date) : date;

  return dateFormat.format(dateToParse);
};
