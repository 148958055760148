/**
 * Common types
 */
import { useState, useMemo } from "react";
import { mapConnection, MetricsTimeFrame, useProjectsForDashboardQuery } from "api";

export enum DashboardStatus {
  OK = "OK",
  WARNING = "WARNING",
  DANGER = "DANGER",
  NEUTRAL = "NEUTRAL",
}

export type ProjectWithMetrics = {
  id: string;
  name: string;
  workload?: {
    index?: number;
    percentageChange?: number;
    trend?: { value: number; name: string }[];
  };
  performance?: {
    index?: number;
    budgetRatio?: number;
    workRatio?: number;
    trend?: { value: number | undefined | null; name: string }[];
  };
  budgetUtilization?: {
    spent?: number | null;
    min?: number | null;
    max?: number | null;
    trend?: { value: number | undefined | null; name: string }[];
  };
  estimate?: {
    index?: number;
    percentageChange?: number;
  };
  shareOfOperational?: {
    share?: number;
    limit?: number;
  };
};

export type ProjectsWithMetrics = {
  [projectType: string]: ProjectWithMetrics[];
};

export const useDashboard = () => {
  const [timeFrame, setTimeFrame] = useState(MetricsTimeFrame.ThirtyDays);
  const { data, loading } = useProjectsForDashboardQuery({
    variables: {
      timeFrame: timeFrame,
    },
  });
  const projects = useMemo(() => mapConnection(data?.projects), [data]);

  const projectsMetrics = useMemo(() => {
    let acc: ProjectsWithMetrics = {};
    projects.forEach((project) => {
      const metrics = mapConnection(project.metrics);
      const newestMetric = !!metrics.length ? metrics[0] : undefined;
      const projectWithMetrics: ProjectWithMetrics = {
        id: project.id,
        name: project.name,
        workload: {
          index: newestMetric?.workload ?? undefined,
          percentageChange: newestMetric?.workloadPercentageChange ?? undefined,
          trend: metrics
            .map((metric) => ({
              value: Number(metric?.workload?.toFixed(2)) ?? 0,
              name: new Date(metric?.toDate).toLocaleDateString(),
            }))
            .reverse(),
        },
        performance: {
          index: newestMetric?.performanceIndex ?? 0,
          budgetRatio: newestMetric?.performanceIndexBudgetRatio ?? 0,
          workRatio: newestMetric?.performanceIndexWorkRatio ?? 0,
          trend: metrics
            .map((metric, i) => ({
              value: metric?.performanceIndex,
              name: i.toString(),
            }))
            .reverse(),
        },
        budgetUtilization: {
          spent: newestMetric?.budgetUtilizationSpent,
          min: newestMetric?.budgetUtilizationMinBudget,
          max: newestMetric?.budgetUtilizationMaxBudget,
          trend: metrics
            .map((metric, i) => ({
              value: metric?.budgetUtilizationSpent,
              name: i.toString(),
            }))
            .reverse(),
        },
        estimate: {
          index: newestMetric?.estimateIndex ?? undefined,
          percentageChange: newestMetric?.estimateIndexPercentageChange ?? undefined,
        },
        shareOfOperational: {
          share: newestMetric?.shareOfOperational ?? undefined,
          limit: project.shareOfOperationalLimit ?? undefined,
        },
      };

      const projectType = project.type ?? "unassigned";
      if (!acc.hasOwnProperty(projectType)) {
        acc = {
          ...acc,
          [projectType]: [],
        };
      }
      acc[projectType].push(projectWithMetrics);
    });
    return acc;
  }, [projects]);

  const isLoading = useMemo(() => loading || !projectsMetrics, [loading, projectsMetrics]);

  return {
    data: {
      projectsMetrics,
    },
    state: {
      timeFrame,
      setTimeFrame,
      isLoading,
    },
  };
};
