import { useMemo } from "react";
import { Text } from "grommet";
import { Money } from "grommet-icons";
import { ProgressBar } from "components/ProgressBar";
import { getColorByPerformanceIndex } from "helpers/dashboard";

type Props = {
  index: number | null | undefined;
  budgetRatio: number | null | undefined;
  workRatio: number | null | undefined;
};

export const PerformanceIndex = ({ index, budgetRatio, workRatio }: Props) => {
  const color = useMemo(() => {
    return getColorByPerformanceIndex(index);
  }, [index]);

  return (
    <ProgressBar
      indicators={budgetRatio ? [{ value: Math.min(budgetRatio, 1) * 100, color, icon: <Money /> }] : []}
      before={
        <Text color={color} style={{ whiteSpace: "nowrap" }}>
          {index?.toFixed(2)}
        </Text>
      }
      value={(workRatio || 0) * 100}
    />
  );
};
