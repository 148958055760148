import { useCallback, useMemo } from "react";
import { mapConnection, ProjectForProjectSettingsQuery, ProjectUpdateInput } from "api";
import { guardedNumber, stringToArray } from "utils";

export type ProjectSettingsFormData = ProjectUpdateInput;

export const useProjectSettingsFormDataTransformer = (
  projectDetailData?: ProjectForProjectSettingsQuery["project"]
) => {
  const transformDataToForm = useCallback(
    (data: ProjectForProjectSettingsQuery["project"]): ProjectSettingsFormData | null => {
      if (!data?.id) {
        return null;
      }

      return {
        ...data,
        project: data?.id!,
        projectManager: data?.projectManager?.id,
        taskTrackingProviderProjects: mapConnection(data?.taskTrackingProviderProjects).map((providerProject) => ({
          taskTrackingProvider: providerProject.taskTrackingProvider.id,
          taskTrackingProviderProjectId: providerProject.taskTrackingProviderProjectId,
          code: "",
        })),
        taskTrackingProvider: undefined,
      };
    },
    []
  );

  const transformFormDataToApiPayload = useCallback(
    (data: Partial<ProjectSettingsFormData>, taskTrackingProjects: any): ProjectUpdateInput | null => {
      if (!data.project) {
        return null;
      }

      /*
       * Data from form are not property type-checked so for some type of values like
       * number or array is used manual type check and conversion to the property type
       *
       * TODO
       * issue: https://argo22.atlassian.net/browse/ARG014-273
       * */
      return {
        ...data,
        project: data.project,

        /*
         * Grommet returns empty string for empty TextInput
         * To prevent sending unwanted values to the API is value from the form sanitized
         * */
        pmPrice: guardedNumber(data.pmPrice, null),
        pmPercent: guardedNumber(data.pmPercent, null),
        timeAllocated: guardedNumber(data.timeAllocated, null),
        shareOfOperationalLimit: guardedNumber(data.shareOfOperationalLimit, null),
        budgetSpentMin: guardedNumber(data.budgetSpentMin, null),
        budgetSpentMax: guardedNumber(data.budgetSpentMax, null),

        taskFinalStates:
          typeof data.taskFinalStates === "string" ? stringToArray(data.taskFinalStates, ",") : data.taskFinalStates,

        taskBacklogStates:
          typeof data.taskBacklogStates === "string"
            ? stringToArray(data.taskBacklogStates, ",")
            : data.taskBacklogStates,

        taskTrackingProviderProjects: data.taskTrackingProviderProjects?.map((project) => {
          if (!project?.taskTrackingProvider || !project?.taskTrackingProviderProjectId) {
            return null;
          }
          return {
            taskTrackingProvider: project!.taskTrackingProvider,
            taskTrackingProviderProjectId: project!.taskTrackingProviderProjectId,
            code: taskTrackingProjects.find((item: any) => item?.externalId === project!.taskTrackingProviderProjectId)
              ?.code,
          };
        }),
      };
    },
    []
  );

  const FormData = useMemo(() => transformDataToForm(projectDetailData), [
    projectDetailData,
    transformDataToForm,
  ]);

  return {
    data: {
      FormData,
    },
    handlers: {
      transformDataToForm,
      transformFormDataToApiPayload,
    },
  };
};
