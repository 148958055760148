import { useMemo } from "react";
import { Box } from "grommet";
import { Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { getColorByPerformanceIndex } from "helpers/dashboard";
import { dashboardTheme } from "styles";

type Props = {
  data?: { name: string; value: number | null | undefined }[];
};

export const PerformanceIndexTrend = ({ data }: Props) => {
  const { colors } = dashboardTheme;

  const coupledData = useMemo(
    () => data?.map((perfIndex, index) => (index + 1 === data.length ? [perfIndex] : [perfIndex, data[index + 1]])),
    [data]
  );

  return (
    <Box fill={true}>
      <ResponsiveContainer>
        <LineChart>
          <XAxis dataKey={"name"} type={"category"} allowDuplicatedCategory={false} hide />
          <YAxis domain={["dataMin", "dataMax"]} hide />
          <ReferenceLine y={1.0} stroke={colors.text} strokeDasharray="3 3" strokeWidth={2} />
          {coupledData?.map((s, i) => {
            const color = getColorByPerformanceIndex(s[0].value ?? 1);
            return (
              <Line key={i} data={s} type="linear" dataKey="value" stroke={color} fill={color} strokeWidth={2} dot />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
