import { Route, RouteComponentProps, Switch } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { ProjectInvoiceReport, ProjectReport } from "pages";
import { routes } from "routes";
import BrandonB from "../../assets/fonts/BrandonB.woff";
import BrandonR from "../../assets/fonts/BrandonR.woff";
import MuseoSans300 from "../../assets/fonts/MuseoSans300.woff";
import MuseoSans700 from "../../assets/fonts/MuseoSans700.woff";
import MuseoSans900 from "../../assets/fonts/MuseoSans900.woff";

interface Props extends RouteComponentProps {}

/*
 * TODO: implement access check
 * */
export const Print = (props: Props) => {
  return (
    <>
      <GlobalStyle />
      <PaperWrapper>
        <Paper>
          <Switch>
            <Route path={routes.projectReport} component={ProjectReport} exact />
            <Route path={routes.projectInvoiceReport} component={ProjectInvoiceReport} exact />
          </Switch>
        </Paper>
      </PaperWrapper>
    </>
  );
};

export const Paper = styled.div`
  background: white;
  display: block;
  margin: 0 auto;
  -webkit-print-color-adjust: exact;

  @media not print {
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);

    /* simulate print margin */
    padding: 1.5cm 1.2cm;

    /* A4 size */
    width: 21cm;
    min-height: 29.7cm;
  }
`;

const PaperWrapper = styled.div`
  @media not print {
    background: rgb(204, 204, 204);

    padding: 0.5cm 0;
  }
`;

const GlobalStyle = createGlobalStyle`
  @page {
      size: auto; /* auto is the initial value */
  }

  html, body {
    margin: 0;
  }
  
  @font-face {
font-family: "Brandon";
src: url(${BrandonB}) format('woff');
font-weight: 900;
font-style: normal;
}
@font-face {
font-family: "Brandon";
src: url(${BrandonR}) format('woff');
font-weight: 400;
font-style: normal;
}
@font-face {
font-family: "MuseoSans";
src: url(${MuseoSans300}) format('woff');
font-weight: 300;
font-style: normal;
}
@font-face {
font-family: "MuseoSans";
src: url(${MuseoSans700}) format('woff');
font-weight: 700;
font-style: normal;
}
@font-face {
font-family: "MuseoSans";
src: url(${MuseoSans900}) format('woff');
font-weight: 900;
font-style: normal;
}
`;
