import { useCallback, useMemo, useState } from "react";
import { DateInputProps } from "grommet";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { mapConnection, resolveError, useProjectsForDeviationsQuery, useTasksForDeviationsQuery } from "api";
import { getDateFromDateInputChangeEvent, getDefaultLabelForProjectsSelect } from "helpers";
import { SortDirection, useDebouncedValue, useMe, useSorting, useStateQueryParam } from "hooks";
import { DeviationsQueries, getRoute } from "routes";
import { DateStr, getFirstDayOfMonth, getLastDayOfMonth, isValidDate, localizeDate, toDateStr } from "utils";

// todo: replace by generated type from BE once ready
export enum OrderTasksBy {
  CODE = "code",
  CODE_DESC = "code_desc",
  TYPE = "type",
  TYPE_DESC = "type_desc",
  TITLE = "title",
  TITLE_DESC = "title_desc",
  STATE = "state",
  STATE_DESC = "state_desc",
  ASSIGNEE = "assignee",
  ASSIGNEE_DESC = "assignee_desc",
  ESTIMATE = "estimate",
  ESTIMATE_DESC = "estimate_desc",
  TIME_SPENT = "time_spent_total",
  TIME_SPENT_DESC = "time_spent_total_desc",
  DIVERGENCE = "divergence",
  DIVERGENCE_DESC = "divergence_desc",
}

export enum DateRange {
  Enabled = "enabled",
  Disabled = "disabled",
  FromDate = "fromDate",
  TillDate = "tillDate",
}

const transformSortProperty = (option: OrderTasksBy, direction: SortDirection) =>
  direction === SortDirection.DESC ? (option.concat("_desc") as OrderTasksBy) : option;

const defaultDate: Date = moment().subtract(1, "months").toDate(); // previous month from today
const defaultFromDate: Date = getFirstDayOfMonth(defaultDate);
const defaultTillDate: Date = getLastDayOfMonth(defaultDate);
const defaultBillable: boolean = false;
const defaultDateRangeSettings: DateRange = DateRange.TillDate;

export const useDeviations = (projectId: string = "") => {
  const { me } = useMe();
  const history = useHistory();

  const [dateRangeSettings, setDatesSetting] = useStateQueryParam<DateRange>("dateRange", defaultDateRangeSettings);
  const [fromDate, setFromDate] = useStateQueryParam<DateStr>("fromDate", toDateStr(defaultFromDate), isValidDate);
  const [tillDate, setTillDate] = useStateQueryParam<DateStr>("tillDate", toDateStr(defaultTillDate), isValidDate);
  const [billable, setBillable] = useStateQueryParam<boolean>("billable", defaultBillable);

  const localizedFromDate = useMemo<string | undefined>(() => localizeDate(new Date(fromDate)), [fromDate]);
  const localizedTillDate = useMemo<string | undefined>(() => localizeDate(new Date(tillDate)), [tillDate]);

  const enableFromDate = useMemo(
    () => dateRangeSettings === DateRange.Enabled || dateRangeSettings === DateRange.FromDate,
    [dateRangeSettings]
  );
  const enableTillDate = useMemo(
    () => dateRangeSettings === DateRange.Enabled || dateRangeSettings === DateRange.TillDate,
    [dateRangeSettings]
  );

  const searchTaskQuery = useDebouncedValue("", 500);
  const searchProjectQuery = useDebouncedValue("", 300);

  const [loadingMoreTasks, setLoadingMoreTasks] = useState(false);

  const { data: projectsData, loading: loadingProjects } = useProjectsForDeviationsQuery({
    onError: resolveError,
  });

  const sortHandler = useSorting<OrderTasksBy>(OrderTasksBy.CODE);

  const { data: tasksData, loading: loadingTasks, fetchMore: fetchMoreTasks } = useTasksForDeviationsQuery({
    variables: {
      project: projectId,
      searchQuery: searchTaskQuery.debounced,
      fromDate: enableFromDate ? fromDate : undefined,
      tillDate: enableTillDate ? tillDate : undefined,
      billable,
      orderBy: transformSortProperty(sortHandler.sortBy, sortHandler.direction),
      first: 60,
      cursor: null,
    },
    onError: resolveError,
  });

  const tasks = useMemo(() => mapConnection(tasksData?.tasks), [tasksData]);
  const projects = useMemo(() => mapConnection(projectsData?.projects), [projectsData]);

  const hasNextPage = useMemo(() => !!tasksData?.tasks.pageInfo?.hasNextPage, [tasksData]);
  const endCursor = useMemo(() => tasksData?.tasks.pageInfo?.endCursor, [tasksData]);

  const routeQueries = useMemo<DeviationsQueries>(
    () => ({
      fromDate: enableFromDate ? fromDate : undefined,
      tillDate: enableTillDate ? tillDate : undefined,
      billable: billable,
    }),
    [enableFromDate, fromDate, enableTillDate, tillDate, billable]
  );

  const defaultSelectOptions = useMemo(() => ({ value: "", label: getDefaultLabelForProjectsSelect(me?.role) }), [me]);
  const selectOptions = useMemo(
    () =>
      [
        defaultSelectOptions,
        ...projects
          .sort((a, b) => (a.code > b.code ? 1 : -1))
          .map((p) => ({
            value: p.id,
            label: `${p.code} ${p.name} (${p.projectManager?.firstName} ${p.projectManager?.lastName})`,
          })),
      ].filter((opt) => opt.label.toLowerCase().includes(searchProjectQuery.debounced.toLowerCase())),
    [projects, defaultSelectOptions, searchProjectQuery.debounced]
  );

  const setSelectedProject = useCallback(
    (id: string) => {
      const route = id ? getRoute.deviationsProject({ project: id }, routeQueries) : getRoute.deviations(routeQueries);
      history.push(route);
    },
    [history, routeQueries]
  );

  const handleClickPrintReport = useCallback(() => {
    const route = getRoute.projectReport({ project: projectId }, routeQueries);
    window.open(route);
  }, [projectId, routeQueries]);

  const handleLoadMoreTasks = useCallback(async () => {
    if (!hasNextPage) return;

    setLoadingMoreTasks(true);
    try {
      await fetchMoreTasks({
        variables: {
          cursor: endCursor,
        },
      });
    } catch {
      // handled by Query onError
    }
    setLoadingMoreTasks(false);
  }, [endCursor, fetchMoreTasks, hasNextPage]);

  const handleChangeInputFromDate: DateInputProps["onChange"] = useCallback(
    (event) => {
      const date = getDateFromDateInputChangeEvent(event);
      date && setFromDate(toDateStr(date));
    },
    [setFromDate]
  );

  const handleChangeInputTillDate: DateInputProps["onChange"] = useCallback(
    (event) => {
      const date = getDateFromDateInputChangeEvent(event);
      date && setTillDate(toDateStr(date));
    },
    [setTillDate]
  );

  const handleClickCheckboxBillable = useCallback(() => setBillable(!billable), [billable, setBillable]);

  return {
    state: {
      loadingTasks,
      loadingMoreTasks,
      loadingProjects,

      selectOptions,

      searchTaskQueryValue: searchTaskQuery.original,

      dateRangeSettings,
      enableFromDate,
      enableTillDate,

      tillDate,
      fromDate,
      billable,

      localizedFromDate: localizedFromDate,
      localizedTillDate: localizedTillDate,
    },
    data: { tasks, projects },
    handlers: {
      setSelectedProject,
      handleClickPrintReport,
      handleLoadMoreTasks,
      sortHandler,

      searchTask: searchTaskQuery.set,
      resetSearchTaskQuery: searchTaskQuery.reset,

      handleSearchSelect: searchProjectQuery.set,
      handleCloseSelect: searchProjectQuery.reset,

      handleChangeInputFromDate,
      handleChangeInputTillDate,
      handleClickCheckboxBillable,
      handleChangeDateRangeSettings: setDatesSetting,
    },
  };
};
