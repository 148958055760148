import { useMemo } from "react";
import { Button, ButtonType } from "grommet";
import { Announce as IconAnnounce } from "grommet-icons";
import { TimeEntryPingState } from "api";
import { getPingColor } from "helpers";

type PingButtonProps = ButtonType & {
  state?: TimeEntryPingState;
  changedAfterResolved?: boolean;
};

export const PingButton = ({ state, changedAfterResolved, ...buttonProps }: PingButtonProps) => {
  const StatusColor = useMemo(() => getPingColor(state), [state]);

  return (
    <Button icon={<IconAnnounce size={"small"} />} size={"small"} label={"Ping"} color={StatusColor} {...buttonProps} />
  );
};
