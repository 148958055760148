import { useCallback, useMemo, useState } from "react";
import { ApolloError } from "@apollo/client";
import { toast } from "react-toastify";
import {
  resolveError,
  TimeTrackingPingsDocument,
  useApproveTimeEntryMutation,
  usePingTimeEntryMutation,
  useProjectTimeEntriesInfoLazyQuery,
  useResyncTimeEntryMutation,
  useUnapproveTimeEntryMutation,
} from "api";
import { DateStr } from "utils";

/*
 * Time Entry
 *
 * refresh, approve, un-approve and error "not_found" call ProjectTimeEntriesInfo query
 * which update counts at Sidebar on TimeEntry page
 * */
export const useTimeEntryItem = (timeEntryId: string, projectId: string, tillDate: DateStr) => {
  // Re-fetch Project contains TimeEntryItem
  // It will provide update of the cached data and sidebar component will receive a new data from queries
  const [refetchCurrentProject, { loading: loadingProjectInfo }] = useProjectTimeEntriesInfoLazyQuery({
    variables: { id: projectId, tillDate: tillDate },
    fetchPolicy: "network-only",
    onError: resolveError,
  });
  const [refetchNewProjectInfo] = useProjectTimeEntriesInfoLazyQuery({
    fetchPolicy: "network-only",
    onError: resolveError,
  });

  // Hide item from view.
  // Second option is re-render the parent table with all time entries.
  const [render, setRender] = useState<boolean>(true);
  const hideItem = useCallback(() => setRender(false), []);

  const refetchNewProject = useCallback(
    async (newProjectId: string) => {
      hideItem();
      toast.info(`Záznam už není součástí tohoto projektu.`);
      await refetchNewProjectInfo({ variables: { id: newProjectId, tillDate: tillDate } });
    },
    [tillDate, refetchNewProjectInfo, hideItem]
  );

  const handleMutationError = useCallback(
    (error: ApolloError) => {
      if (error.message !== "not_found") return resolveError(error);

      hideItem();
      toast.info(`Záznam nenalezen. Nejspíš ho někdo smazal.`);
      return refetchCurrentProject();
    },
    [hideItem, refetchCurrentProject]
  );

  const [resyncMutation, { loading: loadingResync }] = useResyncTimeEntryMutation({
    variables: { input: { timeEntry: timeEntryId } },
    onError: handleMutationError,
    onCompleted: (result) => {
      refetchCurrentProject();
      const newProjectId = result.timeEntryResync.timeEntry?.project?.id;
      if (newProjectId && newProjectId !== projectId) refetchNewProject(newProjectId);
    },
  });
  const [approveMutation, { loading: loadingApprove }] = useApproveTimeEntryMutation({
    variables: { input: { timeEntry: timeEntryId }, tillDate: tillDate },
    onError: handleMutationError,
    onCompleted: (result) => {
      refetchCurrentProject();
      const newProjectId = result.timeEntryApprove.timeEntry?.project?.id;
      if (newProjectId && newProjectId !== projectId) refetchNewProject(newProjectId);
    },
  });
  const [unapproveMutation, { loading: loadingUnapprove }] = useUnapproveTimeEntryMutation({
    variables: { input: { timeEntry: timeEntryId }, tillDate: tillDate },
    onError: handleMutationError,
    onCompleted: (result) => {
      refetchCurrentProject();
      const newProjectId = result.timeEntryUnapprove.timeEntry?.project?.id;
      if (newProjectId && newProjectId !== projectId) refetchNewProject(newProjectId);
    },
  });

  const [pingMutation, { loading: loadingPing }] = usePingTimeEntryMutation({
    variables: { input: { timeEntry: timeEntryId } },
    onError: handleMutationError,
    refetchQueries: [
      {
        query: TimeTrackingPingsDocument,
      },
    ],
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          timeEntries(existingTimeEntries = []) {
            return existingTimeEntries;
          },
        },
      });
    },
  });

  const loading = useMemo(
    () => loadingResync || loadingApprove || loadingUnapprove || loadingProjectInfo || loadingPing,
    [loadingResync, loadingApprove, loadingUnapprove, loadingProjectInfo, loadingPing]
  );

  /* mutations */
  const handleClickRefresh = useCallback(() => resyncMutation(), [resyncMutation]);
  const handleClickApprove = useCallback(() => approveMutation(), [approveMutation]);
  const handleClickUnapprove = useCallback(() => unapproveMutation(), [unapproveMutation]);
  const handleClickPing = useCallback(() => pingMutation(), [pingMutation]);

  return {
    state: {
      loading,
      render,
    },
    handlers: {
      handleClickApprove,
      handleClickRefresh,
      handleClickUnapprove,
      handleClickPing,
    },
  };
};
