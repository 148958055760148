import { useCallback, useMemo } from "react";
import { DateInputProps } from "grommet";
import { useHistory } from "react-router-dom";
import {
  mapConnection,
  Maybe,
  ProjectsForTimeEntriesQuery,
  resolveError,
  useApproveProjectMutation,
  useProjectsForTimeEntriesQuery,
} from "api";
import { getDateFromDateInputChangeEvent } from "helpers";
import { useMe, useStateQueryParam } from "hooks";
import { getRoute } from "routes";
import { DateStr, getUnique, isValidDate, localizeDate, toDateStr } from "utils";

type ProjectNode = NonNullable<ProjectsForTimeEntriesQuery["projects"]>["edges"][0]["node"];

type ProjectsByPm = {
  projectManager: ProjectNode["projectManager"];
  timeEntriesTotal: number;
  timeEntriesForApproval: number;
  projects: Maybe<ProjectNode[]>;
  allResolved?: boolean;
};

const defaultTillDate: Date = new Date();

export const useTimeEntries = (selectedProjectId: string) => {
  const history = useHistory();
  const { me } = useMe();

  const [tillDate, setTillDate] = useStateQueryParam<DateStr>("tillDate", toDateStr(defaultTillDate), isValidDate);
  const localizedTillDate = useMemo<string | undefined>(() => tillDate && localizeDate(new Date(tillDate)), [tillDate]);

  const { data: projectsData, loading: loadingProjects } = useProjectsForTimeEntriesQuery({
    variables: { tillDate },
    onError: resolveError,
  });
  const [approveProjectMutation, { loading: loadingApproveProject }] = useApproveProjectMutation({
    onError: resolveError,
  });

  const projects = useMemo<ProjectNode[]>(() => mapConnection(projectsData?.projects), [projectsData]);
  const projectsManagers = useMemo(
    () => getUnique(projects.map((project) => project.projectManager)).sort((a, b) => (!!a ? -1 : 1)),
    [projects]
  );
  const selectedProject = useMemo(() => projects.find(({ id }) => selectedProjectId === id), [
    selectedProjectId,
    projects,
  ]);

  const canApproveSelectedProject = useMemo<boolean>(
    () => !loadingApproveProject && !!selectedProject?.forApproval && !selectedProject?.timeEntries?.forApprovalCount,
    [loadingApproveProject, selectedProject]
  );

  /** Group projects by project manager and count time entries summary */
  const projectsByPm = useMemo<ProjectsByPm[]>(
    () =>
      projectsManagers
        .flatMap((manager) => {
          const managerProjects: ProjectNode[] = projects.filter(
            (project) => project.projectManager?.id === manager?.id
          );

          const result: ProjectsByPm = {
            projectManager: manager || null,
            projects: managerProjects,
            timeEntriesTotal: 0,
            timeEntriesForApproval: 0,
          };

          // get projects stats
          for (let project of managerProjects) {
            result.timeEntriesTotal += project.timeEntries?.totalCount || 0;
            result.timeEntriesForApproval += project.timeEntries?.forApprovalCount || 0;
          }

          // check whether all pms projects are approved
          result.allResolved = !managerProjects.find((project) => project.forApproval);

          return [result];
        })
        .sort((a) => (a.projectManager?.id === me?.userId ? -1 : 1)),
    [projects, projectsManagers, me]
  );

  /** Group manager projects by clients */
  const projectsByPmAndClients = useMemo(() => {
    return projectsByPm.map((item) => {
      const groupedMap = item.projects?.reduce((entryMap, e) => {
        const property = e.client?.name ?? "";
        return entryMap.set(property, [
          ...(entryMap.get(property)?.sort((a, b) => (a.name > b.name ? 1 : -1)) || []),
          e,
        ]);
      }, new Map<string, ProjectNode[]>());

      const sortedMap = new Map([...(groupedMap?.entries() || [])].sort());
      return {
        ...item,
        clients: sortedMap,
      };
    });
  }, [projectsByPm]);

  const handleChangeInputTillDate: DateInputProps["onChange"] = useCallback(
    (event) => {
      const date = getDateFromDateInputChangeEvent(event);
      date && setTillDate(toDateStr(date));
    },
    [setTillDate]
  );

  const handleClickApproveSelectedProject = useCallback(async () => {
    await approveProjectMutation({
      variables: { input: { project: selectedProjectId, approvedTill: tillDate }, tillDate },
    });
  }, [tillDate, selectedProjectId, approveProjectMutation]);

  const handleSelectProject = useCallback(
    (id: string) => {
      const route = getRoute.timeEntriesProject({ project: id }, { tillDate });
      history.push(route);
    },
    [tillDate, history]
  );

  return {
    data: {
      projectsByPmAndClients,
      selectedProject,
    },
    state: {
      loadingProjects,
      loadingApproveProject,

      canApproveSelectedProject,
      tillDate,
      localizedTillDate,
    },
    handlers: { handleChangeInputTillDate, handleSelectProject, handleClickApproveSelectedProject },
  };
};
