/** Local Storage keys */
export const LS_AUTH_TOKEN = "@erp-webapp/auth-token";

/*
 * User
 * */
/* Authorization Token */
export const getAuthToken = (): string | undefined => {
  try {
    const token = window.localStorage.getItem(LS_AUTH_TOKEN);
    return typeof token === "string" && token.length ? JSON.parse(token) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const setAuthToken = (value: string): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, JSON.stringify(value));
  } catch (e) {}
};

export const clearAuthToken = (): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, "");
  } catch (e) {}
};
