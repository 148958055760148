import { Box, Text } from "grommet";
import { Inspect } from "grommet-icons";
import { getDateTimeString } from "utils";

type TimeEntryPingItemProps = {
  color?: string;
  pingLabel?: string;
  updatedAt: Date;
  code: string;
  name: string;
  task?: string;
  note: string;
  versionsIndicator?: boolean;
};
export const TimeEntryPingItem = (props: TimeEntryPingItemProps) => {
  return (
    <Box border={{ side: "bottom", color: "light-4" }}>
      <Box
        direction={"row"}
        pad={{ left: "small", vertical: "small" }}
        height={{ min: "xxsmall" }}
        align={"center"}
        gap={"small"}
        border={{
          color: props.color,
          size: "large",
          side: "left",
        }}
      >
        <Box width={"xsmall"}>
          <Text weight={"bold"} size={"small"} color={props.color}>
            {props.pingLabel ?? "-"}
          </Text>
        </Box>
        <Box width={"medium"}>
          <Text size={"small"} color={"dark-3"}>
            {getDateTimeString(props.updatedAt)}
          </Text>
        </Box>
        <Box width={"medium"}>
          <Text size={"small"} color={"dark-3"}>{`[${props.code}] ${props.name}`}</Text>
        </Box>

        <Box width={"small"}>
          <Text size={"small"} color={"dark-3"}>{`${props.task}`}</Text>
        </Box>

        <Box flex>
          <Text size={"small"}>{props.note}</Text>
        </Box>

        {props.versionsIndicator ? <Inspect color={"status-warning"} /> : null}
      </Box>
    </Box>
  );
};
