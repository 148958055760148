import { ThemeType } from "grommet";
import { deepFreeze } from "grommet/utils";

export const theme: ThemeType = deepFreeze({
  grommet: {
    // hide grommet focus indication - https://github.com/grommet/grommet/issues/3642
    extend: "* { outline: none !important; box-shadow: none !important; }",
  },
  global: {
    colors: {
      brand: "#ffe014",
      "dark-1": "#444349",
      active: "#4bbda6",
    },
    font: {
      size: "14px",
      family: "Roboto, sans-serif",
    },
  },
  button: {
    border: {
      radius: "4px",
    },
  },
  menu: {
    background: "brand",
  },
  formField: {
    border: false,
    content: {
      pad: "xsmall",
      margin: "xsmall",
    },
  },
  tabs: {
    header: {
      extend: "padding: 15px",
      border: {
        side: "top",
      },
      background: "light-1",
    },
    gap: "20px",
  },
  tab: {
    active: {
      color: "text",
    },
    color: "text",
    border: {
      color: "text",
      active: {
        color: "brand",
      },
    },
  },
  accordion: {
    border: false,
  },
});
