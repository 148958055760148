import { useState } from "react";
import { Button, RadioButtonGroup } from "grommet";
import moment from "moment";
import { useUpdateEffect } from "react-use";
import { MetricsTimeFrame } from "api";

type Props = {
  onChange: (val: MetricsTimeFrame) => void;
  defaultValue?: MetricsTimeFrame;
};

export const TimeFramePicker = ({ defaultValue = MetricsTimeFrame.ThirtyDays, onChange }: Props) => {
  const [value, setValue] = useState(defaultValue);
  const options = [MetricsTimeFrame.OneDay, MetricsTimeFrame.SevenDays, MetricsTimeFrame.ThirtyDays];

  const TimeFrameHumanReadable: Record<MetricsTimeFrame, string> = {
    [MetricsTimeFrame.OneDay]: moment().day() % 6 < 2 ? "Pátek" : "Včera",
    [MetricsTimeFrame.SevenDays]: "7 dní",
    [MetricsTimeFrame.ThirtyDays]: "30 dní",
  };

  useUpdateEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <RadioButtonGroup name="radio" direction="row" gap="xsmall" options={options} value={value}>
      {(option: MetricsTimeFrame, { checked, hover }: { checked: boolean; hover: boolean }) => {
        let background;
        if (checked) background = "brand";
        else if (hover) background = "light-4";
        else background = "light-2";
        return (
          <Button
            label={TimeFrameHumanReadable[option]}
            color={background}
            onClick={() => {
              setValue(option as MetricsTimeFrame);
            }}
          />
        );
      }}
    </RadioButtonGroup>
  );
};
