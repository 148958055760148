import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  users: UserConnection;
  me?: Maybe<User>;
  /** The ID of the object */
  taskTrackingProvider?: Maybe<TaskTrackingProvider>;
  taskTrackingProviders?: Maybe<TaskTrackingProviderConnection>;
  timeTrackingProvider?: Maybe<TimeTrackingProvider>;
  timeEntries?: Maybe<TimeEntryConnection>;
  timeEntryPings: TimeEntryPingConnection;
  tasks: TaskConnection;
  /** The ID of the object */
  project?: Maybe<Project>;
  projects?: Maybe<ProjectConnection>;
  /** The ID of the object */
  client?: Maybe<Client>;
  clients?: Maybe<ClientConnection>;
};


export type QueryUsersArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryTaskTrackingProviderArgs = {
  id: Scalars['ID'];
};


export type QueryTaskTrackingProvidersArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryTimeEntriesArgs = {
  project: Scalars['ID'];
  new: Maybe<Scalars['Boolean']>;
  fromDate: Maybe<Scalars['Date']>;
  tillDate: Maybe<Scalars['Date']>;
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryTimeEntryPingsArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryTasksArgs = {
  project: Maybe<Scalars['ID']>;
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  orderBy: Maybe<Scalars['String']>;
  parentTasksOnly: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  fromDate: Maybe<Scalars['Date']>;
  toDate: Maybe<Scalars['Date']>;
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectsArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryClientArgs = {
  id: Scalars['ID'];
};


export type QueryClientsArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type User = Node & {
  __typename?: 'User';
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  role?: Maybe<UserRole>;
  /** The ID of the object. */
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum UserRole {
  Admin = 'admin',
  Pm = 'pm',
  Biller = 'biller'
}

export type TaskTrackingProvider = Node & {
  __typename?: 'TaskTrackingProvider';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Name visible in application. */
  name: Scalars['String'];
  remoteProjects?: Maybe<RemoteProjectConnection>;
};


export type TaskTrackingProviderRemoteProjectsArgs = {
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type RemoteProjectConnection = {
  __typename?: 'RemoteProjectConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RemoteProjectEdge>>;
};

/** A Relay edge containing a `RemoteProject` and its cursor. */
export type RemoteProjectEdge = {
  __typename?: 'RemoteProjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RemoteProject>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type RemoteProject = {
  __typename?: 'RemoteProject';
  externalId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type TaskTrackingProviderConnection = {
  __typename?: 'TaskTrackingProviderConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskTrackingProviderEdge>>;
};

/** A Relay edge containing a `TaskTrackingProvider` and its cursor. */
export type TaskTrackingProviderEdge = {
  __typename?: 'TaskTrackingProviderEdge';
  /** The item at the end of the edge */
  node?: Maybe<TaskTrackingProvider>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TimeTrackingProvider = {
  __typename?: 'TimeTrackingProvider';
  remoteProjects?: Maybe<RemoteProjectConnection>;
};


export type TimeTrackingProviderRemoteProjectsArgs = {
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type TimeEntryConnection = {
  __typename?: 'TimeEntryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<TimeEntryEdge>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
  forApprovalCount: Scalars['Int'];
};

/** A Relay edge containing a `TimeEntry` and its cursor. */
export type TimeEntryEdge = {
  __typename?: 'TimeEntryEdge';
  /** The item at the end of the edge */
  node: TimeEntry;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TimeEntry = Node & {
  __typename?: 'TimeEntry';
  /** The ID of the object. */
  id: Scalars['ID'];
  project: Project;
  worker?: Maybe<Worker>;
  task: Scalars['String'];
  seconds: Scalars['Int'];
  secondsBillable?: Maybe<Scalars['Int']>;
  billable: Billable;
  notes?: Maybe<Scalars['String']>;
  spentAt: Scalars['Date'];
  externalId: Scalars['String'];
  /** Time entry manually approved by user. Overrides automatic check in case when notes don't contain issue code. */
  approved: Scalars['Boolean'];
  taskModel?: Maybe<Task>;
  permalink?: Maybe<Scalars['String']>;
  billableRate?: Maybe<Scalars['Float']>;
  /** Local timestamp. It's None when time entry is created, any subsequent update sets current timestamp. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Updated when any of tracked fields have changed. */
  changedAt?: Maybe<Scalars['DateTime']>;
  externalUpdatedAt?: Maybe<Scalars['DateTime']>;
  externalCreatedAt?: Maybe<Scalars['DateTime']>;
  billing?: Maybe<Billing>;
  forApproval?: Maybe<Scalars['Boolean']>;
  reportNotes?: Maybe<Scalars['String']>;
  versions?: Maybe<TimeEntryVersionConnection>;
  pings: TimeEntryPingConnection;
  canEdit: Scalars['Boolean'];
};


export type TimeEntryVersionsArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type TimeEntryPingsArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type Project = Node & {
  __typename?: 'Project';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Project's code in time tracking provider */
  code: Scalars['String'];
  projectManager?: Maybe<User>;
  type?: Maybe<ProjectType>;
  client?: Maybe<Client>;
  taskTrackingProvider?: Maybe<TaskTrackingProvider>;
  timeTrackingProviderProjectId?: Maybe<Scalars['String']>;
  taskTrackingProviderProjectId?: Maybe<Scalars['String']>;
  timeEntriesSyncedAt?: Maybe<Scalars['DateTime']>;
  tasksSyncedAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedTill?: Maybe<Scalars['DateTime']>;
  showPrices: Scalars['Boolean'];
  pmFee: Scalars['Boolean'];
  pmPercent?: Maybe<Scalars['Int']>;
  pmPrice?: Maybe<Scalars['Int']>;
  language?: Maybe<Language>;
  groupBy?: Maybe<GroupBy>;
  currency?: Maybe<Currency>;
  showVatInfo: Scalars['Boolean'];
  shareOfOperationalLimit?: Maybe<Scalars['Int']>;
  /** Time budget in seconds for Fixed Price[ Change Request] projects */
  timeAllocated: Scalars['Int'];
  /** States in which an issue is considered finished. Used to calculate Performance Index. */
  taskFinalStates: Array<Maybe<Scalars['String']>>;
  /** States in which an issue is considered `to be processed`. */
  taskBacklogStates: Array<Maybe<Scalars['String']>>;
  /** Budget Utilization metric setting. In hours a month. */
  budgetSpentMin?: Maybe<Scalars['Int']>;
  /** Budget Utilization metric setting. In hours a month. */
  budgetSpentMax?: Maybe<Scalars['Int']>;
  taskCodeRegex?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  metricsSet: MetricsConnection;
  tasks?: Maybe<TaskConnection>;
  timeEntries?: Maybe<TimeEntryConnection>;
  unbilledTimeEntries: TimeEntryConnection;
  taskTrackingProviderProjects: TaskTrackingProviderProjectConnection;
  forApproval: Scalars['Boolean'];
  metrics?: Maybe<MetricsConnection>;
  billing?: Maybe<Billing>;
  previousBillings?: Maybe<BillingConnection>;
};


export type ProjectMetricsSetArgs = {
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type ProjectTasksArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  orderBy: Maybe<Scalars['String']>;
  parentTasksOnly: Maybe<Scalars['Boolean']>;
  search: Maybe<Scalars['String']>;
  fromDate: Maybe<Scalars['Date']>;
  toDate: Maybe<Scalars['Date']>;
};


export type ProjectTimeEntriesArgs = {
  new: Maybe<Scalars['Boolean']>;
  fromDate: Maybe<Scalars['Date']>;
  tillDate: Maybe<Scalars['Date']>;
  includeUnbilled: Maybe<Scalars['Boolean']>;
  onlyUnbilled: Maybe<Scalars['Boolean']>;
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type ProjectUnbilledTimeEntriesArgs = {
  tillDate: Maybe<Scalars['Date']>;
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type ProjectTaskTrackingProviderProjectsArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type ProjectForApprovalArgs = {
  toDate: Maybe<Scalars['Date']>;
};


export type ProjectMetricsArgs = {
  timeFrame: MetricsTimeFrame;
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type ProjectBillingArgs = {
  year: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
};


export type ProjectPreviousBillingsArgs = {
  year: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ProjectType {
  FixedPrice = 'fixed_price',
  FixedPriceChangeRequest = 'fixed_price_change_request',
  TimeAndMaterial = 'time_and_material',
  Sla = 'sla',
  Internal = 'internal'
}

export type Client = Node & {
  __typename?: 'Client';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  projects?: Maybe<ProjectConnection>;
};


export type ClientProjectsArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<ProjectEdge>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Project` and its cursor. */
export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  /** The item at the end of the edge */
  node: Project;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};


/** An enumeration. */
export enum Language {
  Cs = 'cs',
  En = 'en'
}

/** An enumeration. */
export enum GroupBy {
  Task = 'task',
  Worker = 'worker'
}

/** An enumeration. */
export enum Currency {
  Eur = 'eur',
  Usd = 'usd',
  Czk = 'czk',
  Gbp = 'gbp'
}

export type MetricsConnection = {
  __typename?: 'MetricsConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MetricsEdge>>;
};

/** A Relay edge containing a `Metrics` and its cursor. */
export type MetricsEdge = {
  __typename?: 'MetricsEdge';
  /** The item at the end of the edge */
  node?: Maybe<Metrics>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Metrics = Node & {
  __typename?: 'Metrics';
  /** The ID of the object. */
  id: Scalars['ID'];
  project: Project;
  timeFrame: MetricsTimeFrame;
  fromDate: Scalars['Date'];
  toDate: Scalars['Date'];
  workload?: Maybe<Scalars['Float']>;
  workloadPercentageChange?: Maybe<Scalars['Float']>;
  performanceIndex?: Maybe<Scalars['Float']>;
  performanceIndexBudgetRatio?: Maybe<Scalars['Float']>;
  performanceIndexWorkRatio?: Maybe<Scalars['Float']>;
  shareOfOperational?: Maybe<Scalars['Float']>;
  shareOfOperationalPercentageChange?: Maybe<Scalars['Float']>;
  estimateIndex?: Maybe<Scalars['Float']>;
  estimateIndexPercentageChange?: Maybe<Scalars['Float']>;
  /** Min budget limit in seconds for the timespan. */
  budgetUtilizationMinBudget?: Maybe<Scalars['Float']>;
  /** Max budget limit in seconds for the timespan. */
  budgetUtilizationMaxBudget?: Maybe<Scalars['Float']>;
  /** Time spent in seconds in the timespan. */
  budgetUtilizationSpent: Scalars['Float'];
  calculatedAt: Scalars['DateTime'];
  invalid: Scalars['Boolean'];
};

/** An enumeration. */
export enum MetricsTimeFrame {
  OneDay = 'one_day',
  SevenDays = 'seven_days',
  ThirtyDays = 'thirty_days'
}


export type TaskConnection = {
  __typename?: 'TaskConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<TaskEdge>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Task` and its cursor. */
export type TaskEdge = {
  __typename?: 'TaskEdge';
  /** The item at the end of the edge */
  node: Task;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Task = Node & {
  __typename?: 'Task';
  /** The ID of the object. */
  id: Scalars['ID'];
  project: Project;
  title: Scalars['String'];
  code: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  estimate?: Maybe<Scalars['Int']>;
  externalId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  parent?: Maybe<Task>;
  externalCreatedAt: Scalars['DateTime'];
  uniqueIdentifier: Scalars['String'];
  taskTrackerProject?: Maybe<TaskTrackingProviderProject>;
  reportStatus: TaskStatus;
  /** Time spent on this task in seconds. */
  timeSpent?: Maybe<Scalars['Int']>;
  /** Time spent on this task and all subtasks in seconds. */
  timeSpentTotal?: Maybe<Scalars['Int']>;
  /** Cumulative estimate for all subtasks. */
  estimateTotal?: Maybe<Scalars['Int']>;
  /** Ratio time spent / estimate */
  divergence?: Maybe<Scalars['Int']>;
  subtasks?: Maybe<TaskConnection>;
};


export type TaskStateArgs = {
  on: Maybe<Scalars['Date']>;
};


export type TaskReportStatusArgs = {
  on: Maybe<Scalars['Date']>;
};


export type TaskTimeSpentArgs = {
  tillDate: Maybe<Scalars['Date']>;
  billable: Maybe<Scalars['Boolean']>;
};


export type TaskTimeSpentTotalArgs = {
  tillDate: Maybe<Scalars['Date']>;
  billable: Maybe<Scalars['Boolean']>;
};


export type TaskDivergenceArgs = {
  tillDate: Maybe<Scalars['Date']>;
  billable: Maybe<Scalars['Boolean']>;
};


export type TaskSubtasksArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type TaskTrackingProviderProject = Node & {
  __typename?: 'TaskTrackingProviderProject';
  /** The ID of the object. */
  id: Scalars['ID'];
  project: Project;
  taskTrackingProvider: TaskTrackingProvider;
  taskTrackingProviderProjectId: Scalars['String'];
  /** Project's code in task tracker */
  code?: Maybe<Scalars['String']>;
  /** Project's name in task tracker */
  name?: Maybe<Scalars['String']>;
};

/**
 * Simple task status for internal purpose to simplify dealing
 *    with all states from different task trackers. 
 */
export enum TaskStatus {
  Backlog = 'backlog',
  InProgress = 'in_progress',
  Done = 'done'
}

export type TaskTrackingProviderProjectConnection = {
  __typename?: 'TaskTrackingProviderProjectConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskTrackingProviderProjectEdge>>;
};

/** A Relay edge containing a `TaskTrackingProviderProject` and its cursor. */
export type TaskTrackingProviderProjectEdge = {
  __typename?: 'TaskTrackingProviderProjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<TaskTrackingProviderProject>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Billing = Node & {
  __typename?: 'Billing';
  /** The ID of the object. */
  id: Scalars['ID'];
  project: Project;
  state: BillingState;
  year: Scalars['Int'];
  month: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** List of available events state change triggers. */
  triggers: Array<Maybe<Triggers>>;
  timeEntries?: Maybe<TimeEntryConnection>;
  stateChanges?: Maybe<BillingStateChangeConnection>;
  timelineEvents: Array<Maybe<BillingTimelineEvent>>;
  /** Person who takes action. */
  responsiblePerson?: Maybe<Scalars['String']>;
};


export type BillingTimeEntriesArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type BillingStateChangesArgs = {
  skip: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum BillingState {
  New = 'new',
  Todo = 'todo',
  HarvestDone = 'harvest_done',
  HarvestEmpty = 'harvest_empty',
  ReportDone = 'report_done',
  ApBlocked = 'ap_blocked',
  ApAccepted = 'ap_accepted',
  NoInvoice = 'no_invoice',
  Invoice = 'invoice',
  InvoiceNextTime = 'invoice_next_time'
}

/** An enumeration. */
export enum Triggers {
  PrepareForBilling = 'PREPARE_FOR_BILLING',
  AutoHarvestEmpty = 'AUTO_HARVEST_EMPTY',
  AutoNoInvoice = 'AUTO_NO_INVOICE',
  AutoHarvestDone = 'AUTO_HARVEST_DONE',
  HarvestDone = 'HARVEST_DONE',
  AutoBackTodo = 'AUTO_BACK_TODO',
  ReportDone = 'REPORT_DONE',
  ApBlocked = 'AP_BLOCKED',
  ApAccepted = 'AP_ACCEPTED',
  Invoice = 'INVOICE',
  NoInvoice = 'NO_INVOICE',
  InvoiceNextTime = 'INVOICE_NEXT_TIME',
  Note = 'NOTE'
}

export type BillingStateChangeConnection = {
  __typename?: 'BillingStateChangeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingStateChangeEdge>>;
};

/** A Relay edge containing a `BillingStateChange` and its cursor. */
export type BillingStateChangeEdge = {
  __typename?: 'BillingStateChangeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BillingStateChange>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BillingStateChange = Node & {
  __typename?: 'BillingStateChange';
  /** The ID of the object. */
  id: Scalars['ID'];
  billing: Billing;
  fromState: BillingState;
  toState: BillingState;
  user?: Maybe<User>;
  note?: Maybe<Scalars['String']>;
  trigger: Triggers;
  createdAt: Scalars['DateTime'];
};

export type BillingTimelineEvent = {
  __typename?: 'BillingTimelineEvent';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  note?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  toState: BillingTimelineEventState;
};

/** An enumeration. */
export enum BillingTimelineEventState {
  New = 'new',
  Todo = 'todo',
  HarvestDone = 'harvest_done',
  HarvestEmpty = 'harvest_empty',
  ReportDone = 'report_done',
  ApBlocked = 'ap_blocked',
  ApAccepted = 'ap_accepted',
  NoInvoice = 'no_invoice',
  Invoice = 'invoice',
  InvoiceNextTime = 'invoice_next_time',
  Note = 'NOTE'
}

export type BillingConnection = {
  __typename?: 'BillingConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingEdge>>;
};

/** A Relay edge containing a `Billing` and its cursor. */
export type BillingEdge = {
  __typename?: 'BillingEdge';
  /** The item at the end of the edge */
  node?: Maybe<Billing>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Worker = Node & {
  __typename?: 'Worker';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  active: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  externalUpdatedAt?: Maybe<Scalars['DateTime']>;
};

/**  Time entry three state billing switch aka changeling.  */
export enum Billable {
  Yes = 'yes',
  No = 'no',
  NextTime = 'next_time'
}

export type TimeEntryVersionConnection = {
  __typename?: 'TimeEntryVersionConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TimeEntryVersionEdge>>;
};

/** A Relay edge containing a `TimeEntryVersion` and its cursor. */
export type TimeEntryVersionEdge = {
  __typename?: 'TimeEntryVersionEdge';
  /** The item at the end of the edge */
  node?: Maybe<TimeEntryVersion>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TimeEntryVersion = Node & {
  __typename?: 'TimeEntryVersion';
  /** The ID of the object. */
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  project?: Maybe<Scalars['String']>;
  task: Scalars['String'];
  seconds: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
};

export type TimeEntryPingConnection = {
  __typename?: 'TimeEntryPingConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<TimeEntryPingEdge>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TimeEntryPing` and its cursor. */
export type TimeEntryPingEdge = {
  __typename?: 'TimeEntryPingEdge';
  /** The item at the end of the edge */
  node: TimeEntryPing;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TimeEntryPing = Node & {
  __typename?: 'TimeEntryPing';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeEntry?: Maybe<TimeEntry>;
  invokedBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  /** Slack message ID */
  messageId?: Maybe<Scalars['String']>;
  /** Identifies a single Slack message together with message_id. */
  channelId?: Maybe<Scalars['String']>;
  state: TimeEntryPingState;
  updatedAt: Scalars['DateTime'];
  resolvedAt?: Maybe<Scalars['DateTime']>;
  /** Marked true when time entry changed after ping has been resolved. */
  teChangedAfterResolved: Scalars['Boolean'];
};

/** An enumeration. */
export enum TimeEntryPingState {
  New = 'new',
  Fixed = 'fixed',
  WontFix = 'wont_fix'
}

export type ClientConnection = {
  __typename?: 'ClientConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientEdge>>;
};

/** A Relay edge containing a `Client` and its cursor. */
export type ClientEdge = {
  __typename?: 'ClientEdge';
  /** The item at the end of the edge */
  node?: Maybe<Client>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebTokenPayload>;
  verifyToken?: Maybe<VerifyPayload>;
  refreshToken?: Maybe<RefreshPayload>;
  /**
   * Update local time entry with data from remote TimeTracker.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   */
  timeEntryResync: TimeEntryResyncPayload;
  /**
   * Mark time entry as approved.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  timeEntryApprove: TimeEntryApprovePayload;
  /**
   * Mark time entry as unapproved.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  timeEntryUnapprove: TimeEntryUnapprovePayload;
  /**
   * Update time entry.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  timeEntryUpdate: TimeEntryUpdatePayload;
  /**
   * Resets manual changes to time entries. Sets 'em as billable and clears overridden billable time.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  timeEntryReset: TimeEntryResetPayload;
  /**
   * Pings worker to check time entry.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `slack_client_error`: Something went wrong on communication layer
   *  `slack_error`: Slack responded with an error. See logs for more details.
   *  `permission_denied`: You do not have permission to perform this action
   */
  timeEntryPingCreate: TimeEntryPingCreatePayload;
  /**
   * Mark project as checked.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `unapproved_time_entries`: Approve all time entries first
   *  `invalid_date`: The date is not suitable here
   *  `permission_denied`: You do not have permission to perform this action
   */
  projectApprove: ProjectApprovePayload;
  /**
   * Create project.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   * @deprecated Projects are created automatically.
   */
  projectCreate: ProjectCreatePayload;
  /**
   * Update project.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   */
  projectUpdate: ProjectUpdatePayload;
  /**
   * Delete project and related tasks and time entries.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   * @deprecated Projects are deleted automatically when they get deactivated in Harvest.
   */
  projectDelete: ProjectDeletePayload;
  /**
   * Trigger change state of billing.
   * 
   * Defined errors:
   * 
   * `not_found`: Node not found
   *  `permission_denied`: You do not have permission to perform this action
   *  `harvest_not_ok`: There are some changes in time entries that haven't been approved yet.
   *  `not_all_harvest_done`: All projects must have Harvest check done.
   */
  billingTrigger: BillingTriggerPayload;
};


export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput;
};


export type MutationVerifyTokenArgs = {
  input: VerifyInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshInput;
};


export type MutationTimeEntryResyncArgs = {
  input: TimeEntryResyncInput;
};


export type MutationTimeEntryApproveArgs = {
  input: TimeEntryApproveInput;
};


export type MutationTimeEntryUnapproveArgs = {
  input: TimeEntryUnapproveInput;
};


export type MutationTimeEntryUpdateArgs = {
  input: TimeEntryUpdateInput;
};


export type MutationTimeEntryResetArgs = {
  input: TimeEntryResetInput;
};


export type MutationTimeEntryPingCreateArgs = {
  input: TimeEntryPingCreateInput;
};


export type MutationProjectApproveArgs = {
  input: ProjectApproveInput;
};


export type MutationProjectCreateArgs = {
  input: ProjectCreateInput;
};


export type MutationProjectUpdateArgs = {
  input: ProjectUpdateInput;
};


export type MutationProjectDeleteArgs = {
  input: ProjectDeleteInput;
};


export type MutationBillingTriggerArgs = {
  input: BillingTriggerInput;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebTokenPayload = {
  __typename?: 'ObtainJSONWebTokenPayload';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};


export type ObtainJsonWebTokenInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  password: Scalars['String'];
};

export type VerifyPayload = {
  __typename?: 'VerifyPayload';
  payload: Scalars['GenericScalar'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VerifyInput = {
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RefreshPayload = {
  __typename?: 'RefreshPayload';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type RefreshInput = {
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryResyncPayload = {
  __typename?: 'TimeEntryResyncPayload';
  timeEntry?: Maybe<TimeEntry>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryResyncInput = {
  timeEntry: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryApprovePayload = {
  __typename?: 'TimeEntryApprovePayload';
  timeEntry?: Maybe<TimeEntry>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryApproveInput = {
  timeEntry: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryUnapprovePayload = {
  __typename?: 'TimeEntryUnapprovePayload';
  timeEntry?: Maybe<TimeEntry>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryUnapproveInput = {
  timeEntry: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryUpdatePayload = {
  __typename?: 'TimeEntryUpdatePayload';
  timeEntries?: Maybe<Array<Maybe<TimeEntry>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryUpdateInput = {
  timeEntries: Array<Maybe<TimeEntryInput>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryInput = {
  timeEntry: Scalars['ID'];
  billable?: Maybe<Billable>;
  secondsBillable?: Maybe<Scalars['Int']>;
};

export type TimeEntryResetPayload = {
  __typename?: 'TimeEntryResetPayload';
  timeEntries?: Maybe<Array<Maybe<TimeEntry>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryResetInput = {
  timeEntries: Array<Maybe<Scalars['ID']>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryPingCreatePayload = {
  __typename?: 'TimeEntryPingCreatePayload';
  timeEntryPing?: Maybe<TimeEntryPing>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TimeEntryPingCreateInput = {
  timeEntry: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectApprovePayload = {
  __typename?: 'ProjectApprovePayload';
  project?: Maybe<Project>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectApproveInput = {
  project: Scalars['ID'];
  approvedTill?: Maybe<Scalars['Date']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectCreatePayload = {
  __typename?: 'ProjectCreatePayload';
  project?: Maybe<Project>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectCreateInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  projectManager: Scalars['ID'];
  type: ProjectType;
  timeTrackingProviderProjectId: Scalars['String'];
  taskTrackingProviderProjectId: Scalars['String'];
  pmFee: Scalars['Boolean'];
  pmPrice?: Maybe<Scalars['Int']>;
  pmPercent?: Maybe<Scalars['Int']>;
  showPrices?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectUpdatePayload = {
  __typename?: 'ProjectUpdatePayload';
  project?: Maybe<Project>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectUpdateInput = {
  project: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  projectManager?: Maybe<Scalars['ID']>;
  type?: Maybe<ProjectType>;
  timeTrackingProviderProjectId?: Maybe<Scalars['String']>;
  taskTrackingProviderProjectId?: Maybe<Scalars['String']>;
  pmFee?: Maybe<Scalars['Boolean']>;
  pmPrice?: Maybe<Scalars['Int']>;
  pmPercent?: Maybe<Scalars['Int']>;
  showPrices?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Language>;
  groupBy?: Maybe<GroupBy>;
  currency?: Maybe<Currency>;
  showVatInfo?: Maybe<Scalars['Boolean']>;
  taskCodeRegex?: Maybe<Scalars['String']>;
  taskTrackingProvider?: Maybe<Scalars['ID']>;
  shareOfOperationalLimit?: Maybe<Scalars['Int']>;
  timeAllocated?: Maybe<Scalars['Int']>;
  /** States in which an issue is considered finished. */
  taskFinalStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** States in which an issue is considered `to be processed`. */
  taskBacklogStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  budgetSpentMin?: Maybe<Scalars['Int']>;
  budgetSpentMax?: Maybe<Scalars['Int']>;
  taskTrackingProviderProjects?: Maybe<Array<Maybe<TaskTrackingProviderProjectInput>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TaskTrackingProviderProjectInput = {
  taskTrackingProvider: Scalars['ID'];
  taskTrackingProviderProjectId: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProjectDeletePayload = {
  __typename?: 'ProjectDeletePayload';
  deleted?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ProjectDeleteInput = {
  project: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BillingTriggerPayload = {
  __typename?: 'BillingTriggerPayload';
  billing?: Maybe<Billing>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BillingTriggerInput = {
  billing: Scalars['ID'];
  trigger: Triggers;
  note?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BillingStateChangeFieldsFragment = { __typename?: 'BillingStateChange', toState: BillingState, createdAt: any, note?: Maybe<string> };

export type PageInfoFieldsFragment = { __typename?: 'PageInfo', endCursor?: Maybe<string>, startCursor?: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean };

export type ProjectBillingFieldsFragment = { __typename?: 'Project', name: string, code: string, groupBy?: Maybe<GroupBy>, type?: Maybe<ProjectType>, showPrices: boolean, currency?: Maybe<Currency>, language?: Maybe<Language>, showVatInfo: boolean, pmPrice?: Maybe<number>, pmFee: boolean, pmPercent?: Maybe<number> };

export type ProviderProjectFieldsFragment = { __typename?: 'TaskTrackingProviderProject', code?: Maybe<string>, taskTrackingProviderProjectId: string, taskTrackingProvider: { __typename?: 'TaskTrackingProvider', id: string, name: string } };

export type TimeEntriesBillingFieldsFragment = { __typename?: 'TimeEntry', id: string, task: string, spentAt: any, reportNotes?: Maybe<string>, seconds: number, secondsBillable?: Maybe<number>, billableRate?: Maybe<number>, billable: Billable, worker?: Maybe<(
    { __typename?: 'Worker', id: string }
    & WorkerNameFieldsFragment
  )>, taskModel?: Maybe<{ __typename?: 'Task', id: string, code: string, type?: Maybe<string>, timeSpent?: Maybe<number>, timeSpentTotal?: Maybe<number>, estimateTotal?: Maybe<number>, title: string, parent?: Maybe<{ __typename?: 'Task', id: string, code: string, title: string, parent?: Maybe<{ __typename?: 'Task', id: string, code: string, title: string }> }> }> };

export type TimeEntriesCountInfoFieldsFragment = { __typename?: 'TimeEntryConnection', forApprovalCount: number, totalCount?: Maybe<number> };

export type TimeEntryApproveFieldsFragment = { __typename?: 'TimeEntry', approved: boolean, forApproval?: Maybe<boolean> };

export type UserNameFieldsFragment = { __typename?: 'User', firstName: string, lastName: string };

export type VersionFieldsFragment = { __typename?: 'TimeEntryVersion', createdAt: any, notes?: Maybe<string>, seconds: number, task: string };

export type WorkerNameFieldsFragment = { __typename?: 'Worker', firstName: string, lastName: string };

export type TriggerBillingMutationVariables = Exact<{
  input: BillingTriggerInput;
}>;


export type TriggerBillingMutation = { __typename?: 'Mutation', billingTrigger: { __typename?: 'BillingTriggerPayload', billing?: Maybe<{ __typename?: 'Billing', id: string, state: BillingState, triggers: Array<Maybe<Triggers>>, stateChanges?: Maybe<{ __typename?: 'BillingStateChangeConnection', edges: Array<Maybe<{ __typename?: 'BillingStateChangeEdge', node?: Maybe<(
            { __typename?: 'BillingStateChange', id: string, user?: Maybe<(
              { __typename?: 'User', id: string }
              & UserNameFieldsFragment
            )> }
            & BillingStateChangeFieldsFragment
          )> }>> }> }> } };

export type ApproveProjectMutationVariables = Exact<{
  input: ProjectApproveInput;
  tillDate: Scalars['Date'];
}>;


export type ApproveProjectMutation = { __typename?: 'Mutation', projectApprove: { __typename?: 'ProjectApprovePayload', project?: Maybe<{ __typename?: 'Project', id: string, name: string, code: string, forApproval: boolean, approvedAt?: Maybe<any>, approvedTill?: Maybe<any>, timeEntries?: Maybe<(
        { __typename?: 'TimeEntryConnection' }
        & TimeEntriesCountInfoFieldsFragment
      )> }> } };

export type UpdateProjectMutationVariables = Exact<{
  input: ProjectUpdateInput;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', projectUpdate: { __typename?: 'ProjectUpdatePayload', project?: Maybe<{ __typename?: 'Project', id: string, name: string, code: string, type?: Maybe<ProjectType>, showPrices: boolean, pmFee: boolean, pmPercent?: Maybe<number>, pmPrice?: Maybe<number>, taskCodeRegex?: Maybe<string>, language?: Maybe<Language>, showVatInfo: boolean, currency?: Maybe<Currency>, groupBy?: Maybe<GroupBy>, taskTrackingProviderProjectId?: Maybe<string>, timeAllocated: number, taskFinalStates: Array<Maybe<string>>, taskBacklogStates: Array<Maybe<string>>, shareOfOperationalLimit?: Maybe<number>, budgetSpentMin?: Maybe<number>, budgetSpentMax?: Maybe<number>, projectManager?: Maybe<(
        { __typename?: 'User', id: string }
        & UserNameFieldsFragment
      )> }> } };

export type ApproveTimeEntryMutationVariables = Exact<{
  input: TimeEntryApproveInput;
  tillDate: Maybe<Scalars['Date']>;
}>;


export type ApproveTimeEntryMutation = { __typename?: 'Mutation', timeEntryApprove: { __typename?: 'TimeEntryApprovePayload', timeEntry?: Maybe<(
      { __typename?: 'TimeEntry', id: string, project: { __typename?: 'Project', id: string, timeEntries?: Maybe<{ __typename?: 'TimeEntryConnection', forApprovalCount: number }> } }
      & TimeEntryApproveFieldsFragment
    )> } };

export type PingTimeEntryMutationVariables = Exact<{
  input: TimeEntryPingCreateInput;
}>;


export type PingTimeEntryMutation = { __typename?: 'Mutation', timeEntryPingCreate: { __typename?: 'TimeEntryPingCreatePayload', timeEntryPing?: Maybe<{ __typename?: 'TimeEntryPing', id: string, createdAt: any, updatedAt: any, state: TimeEntryPingState, timeEntry?: Maybe<{ __typename?: 'TimeEntry', id: string, pings: { __typename?: 'TimeEntryPingConnection', edges: Array<{ __typename?: 'TimeEntryPingEdge', node: { __typename?: 'TimeEntryPing', id: string, createdAt: any, updatedAt: any, state: TimeEntryPingState } }> } }> }> } };

export type ResetTimeEntryMutationVariables = Exact<{
  input: TimeEntryResetInput;
}>;


export type ResetTimeEntryMutation = { __typename?: 'Mutation', timeEntryReset: { __typename?: 'TimeEntryResetPayload', timeEntries?: Maybe<Array<Maybe<{ __typename?: 'TimeEntry', id: string, task: string, spentAt: any, notes?: Maybe<string>, seconds: number, secondsBillable?: Maybe<number>, billableRate?: Maybe<number>, billable: Billable, worker?: Maybe<(
        { __typename?: 'Worker', id: string }
        & WorkerNameFieldsFragment
      )>, taskModel?: Maybe<{ __typename?: 'Task', id: string, code: string, type?: Maybe<string>, timeSpent?: Maybe<number>, timeSpentTotal?: Maybe<number>, estimateTotal?: Maybe<number>, title: string, parent?: Maybe<{ __typename?: 'Task', id: string, code: string, title: string }> }> }>>> } };

export type ResyncTimeEntryMutationVariables = Exact<{
  input: TimeEntryResyncInput;
}>;


export type ResyncTimeEntryMutation = { __typename?: 'Mutation', timeEntryResync: { __typename?: 'TimeEntryResyncPayload', timeEntry?: Maybe<(
      { __typename?: 'TimeEntry', id: string, task: string, notes?: Maybe<string>, seconds: number, spentAt: any, updatedAt?: Maybe<any>, permalink?: Maybe<string>, worker?: Maybe<(
        { __typename?: 'Worker', id: string }
        & WorkerNameFieldsFragment
      )>, project: { __typename?: 'Project', id: string }, versions?: Maybe<{ __typename?: 'TimeEntryVersionConnection', edges: Array<Maybe<{ __typename?: 'TimeEntryVersionEdge', node?: Maybe<(
            { __typename?: 'TimeEntryVersion', id: string }
            & VersionFieldsFragment
          )> }>> }> }
      & TimeEntryApproveFieldsFragment
    )> } };

export type UnapproveTimeEntryMutationVariables = Exact<{
  input: TimeEntryUnapproveInput;
  tillDate: Maybe<Scalars['Date']>;
}>;


export type UnapproveTimeEntryMutation = { __typename?: 'Mutation', timeEntryUnapprove: { __typename?: 'TimeEntryUnapprovePayload', timeEntry?: Maybe<(
      { __typename?: 'TimeEntry', id: string, task: string, worker?: Maybe<(
        { __typename?: 'Worker', id: string }
        & WorkerNameFieldsFragment
      )>, project: { __typename?: 'Project', id: string, timeEntries?: Maybe<{ __typename?: 'TimeEntryConnection', forApprovalCount: number }> } }
      & TimeEntryApproveFieldsFragment
    )> } };

export type UpdateTimeEntryMutationVariables = Exact<{
  input: TimeEntryUpdateInput;
}>;


export type UpdateTimeEntryMutation = { __typename?: 'Mutation', timeEntryUpdate: { __typename?: 'TimeEntryUpdatePayload', timeEntries?: Maybe<Array<Maybe<{ __typename?: 'TimeEntry', id: string, task: string, spentAt: any, notes?: Maybe<string>, seconds: number, secondsBillable?: Maybe<number>, billableRate?: Maybe<number>, billable: Billable, worker?: Maybe<(
        { __typename?: 'Worker', id: string }
        & WorkerNameFieldsFragment
      )>, taskModel?: Maybe<{ __typename?: 'Task', id: string, code: string, type?: Maybe<string>, timeSpent?: Maybe<number>, timeSpentTotal?: Maybe<number>, estimateTotal?: Maybe<number>, title: string, parent?: Maybe<{ __typename?: 'Task', id: string, code: string, title: string }> }> }>>> } };

export type TokenAuthMutationVariables = Exact<{
  input: ObtainJsonWebTokenInput;
}>;


export type TokenAuthMutation = { __typename?: 'Mutation', tokenAuth?: Maybe<{ __typename?: 'ObtainJSONWebTokenPayload', token: string }> };

export type DataForTimeEntriesTableQueryVariables = Exact<{
  project: Scalars['ID'];
  tillDate: Scalars['Date'];
  first: Maybe<Scalars['Int']>;
  cursor: Maybe<Scalars['String']>;
}>;


export type DataForTimeEntriesTableQuery = { __typename?: 'Query', project?: Maybe<{ __typename?: 'Project', id: string, code: string, name: string, forApproval: boolean }>, timeEntries?: Maybe<{ __typename?: 'TimeEntryConnection', totalCount?: Maybe<number>, edges: Array<{ __typename?: 'TimeEntryEdge', cursor: string, node: (
        { __typename?: 'TimeEntry', id: string, externalId: string, externalUpdatedAt?: Maybe<any>, notes?: Maybe<string>, seconds: number, spentAt: any, task: string, updatedAt?: Maybe<any>, permalink?: Maybe<string>, worker?: Maybe<(
          { __typename?: 'Worker', id: string }
          & WorkerNameFieldsFragment
        )>, versions?: Maybe<{ __typename?: 'TimeEntryVersionConnection', edges: Array<Maybe<{ __typename?: 'TimeEntryVersionEdge', node?: Maybe<(
              { __typename?: 'TimeEntryVersion', id: string }
              & VersionFieldsFragment
            )> }>> }>, pings: { __typename?: 'TimeEntryPingConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'TimeEntryPingEdge', cursor: string, node: { __typename?: 'TimeEntryPing', id: string, state: TimeEntryPingState, createdAt: any, updatedAt: any, teChangedAfterResolved: boolean } }> } }
        & TimeEntryApproveFieldsFragment
      ) }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: Maybe<(
    { __typename?: 'User', id: string, userId: string, email: string, isActive: boolean, role?: Maybe<UserRole> }
    & UserNameFieldsFragment
  )> };

export type ProjectForBillingDetailQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type ProjectForBillingDetailQuery = { __typename?: 'Query', project?: Maybe<{ __typename?: 'Project', id: string, name: string, code: string, type?: Maybe<ProjectType>, client?: Maybe<{ __typename?: 'Client', id: string, name: string }>, billing?: Maybe<{ __typename?: 'Billing', id: string, state: BillingState, triggers: Array<Maybe<Triggers>>, stateChanges?: Maybe<{ __typename?: 'BillingStateChangeConnection', edges: Array<Maybe<{ __typename?: 'BillingStateChangeEdge', node?: Maybe<(
            { __typename?: 'BillingStateChange', id: string, user?: Maybe<(
              { __typename?: 'User', id: string }
              & UserNameFieldsFragment
            )> }
            & BillingStateChangeFieldsFragment
          )> }>> }>, timelineEvents: Array<Maybe<{ __typename?: 'BillingTimelineEvent', id: string, createdAt: any, toState: BillingTimelineEventState, note?: Maybe<string>, user?: Maybe<(
          { __typename?: 'User', id: string }
          & UserNameFieldsFragment
        )> }>> }>, previousBillings?: Maybe<{ __typename?: 'BillingConnection', edges: Array<Maybe<{ __typename?: 'BillingEdge', node?: Maybe<{ __typename?: 'Billing', id: string, state: BillingState, stateChanges?: Maybe<{ __typename?: 'BillingStateChangeConnection', edges: Array<Maybe<{ __typename?: 'BillingStateChangeEdge', node?: Maybe<(
                { __typename?: 'BillingStateChange', id: string, user?: Maybe<(
                  { __typename?: 'User', id: string }
                  & UserNameFieldsFragment
                )> }
                & BillingStateChangeFieldsFragment
              )> }>> }> }> }>> }> }> };

export type ProjectForBillingDetailReportQueryVariables = Exact<{
  id: Scalars['ID'];
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type ProjectForBillingDetailReportQuery = { __typename?: 'Query', project?: Maybe<(
    { __typename?: 'Project', id: string, billing?: Maybe<{ __typename?: 'Billing', id: string, timeEntries?: Maybe<{ __typename?: 'TimeEntryConnection', edges: Array<{ __typename?: 'TimeEntryEdge', node: (
            { __typename?: 'TimeEntry', id: string }
            & TimeEntriesBillingFieldsFragment
          ) }> }> }> }
    & ProjectBillingFieldsFragment
  )> };

export type ProjectForDeviationReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectForDeviationReportQuery = { __typename?: 'Query', project?: Maybe<{ __typename?: 'Project', id: string, language?: Maybe<Language> }> };

export type ProjectForInvoiceQueryVariables = Exact<{
  id: Scalars['ID'];
  fromDate: Scalars['Date'];
  tillDate: Scalars['Date'];
  unbilledTillDate: Maybe<Scalars['Date']>;
  onlyUnbilled: Maybe<Scalars['Boolean']>;
}>;


export type ProjectForInvoiceQuery = { __typename?: 'Query', project?: Maybe<(
    { __typename?: 'Project', id: string, timeEntries?: Maybe<{ __typename?: 'TimeEntryConnection', edges: Array<{ __typename?: 'TimeEntryEdge', node: (
          { __typename?: 'TimeEntry', id: string }
          & TimeEntriesBillingFieldsFragment
        ) }> }>, unbilledTimeEntries: { __typename?: 'TimeEntryConnection', totalCount?: Maybe<number>, edges: Array<{ __typename?: 'TimeEntryEdge', node: { __typename?: 'TimeEntry', spentAt: any } }> } }
    & ProjectBillingFieldsFragment
  )> };

export type ProjectForProjectSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectForProjectSettingsQuery = { __typename?: 'Query', project?: Maybe<{ __typename?: 'Project', id: string, name: string, code: string, type?: Maybe<ProjectType>, showPrices: boolean, pmFee: boolean, pmPercent?: Maybe<number>, pmPrice?: Maybe<number>, taskCodeRegex?: Maybe<string>, language?: Maybe<Language>, showVatInfo: boolean, currency?: Maybe<Currency>, groupBy?: Maybe<GroupBy>, taskTrackingProviderProjectId?: Maybe<string>, timeTrackingProviderProjectId?: Maybe<string>, timeAllocated: number, taskFinalStates: Array<Maybe<string>>, taskBacklogStates: Array<Maybe<string>>, shareOfOperationalLimit?: Maybe<number>, budgetSpentMin?: Maybe<number>, budgetSpentMax?: Maybe<number>, projectManager?: Maybe<(
      { __typename?: 'User', id: string }
      & UserNameFieldsFragment
    )>, taskTrackingProviderProjects: { __typename?: 'TaskTrackingProviderProjectConnection', edges: Array<Maybe<{ __typename?: 'TaskTrackingProviderProjectEdge', node?: Maybe<(
          { __typename?: 'TaskTrackingProviderProject', id: string }
          & ProviderProjectFieldsFragment
        )> }>> }, taskTrackingProvider?: Maybe<{ __typename?: 'TaskTrackingProvider', id: string, name: string }> }> };

export type ProjectTimeEntriesInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  tillDate: Scalars['Date'];
}>;


export type ProjectTimeEntriesInfoQuery = { __typename?: 'Query', project?: Maybe<{ __typename?: 'Project', id: string, forApproval: boolean, approvedAt?: Maybe<any>, approvedTill?: Maybe<any>, timeEntries?: Maybe<(
      { __typename?: 'TimeEntryConnection', edges: Array<{ __typename?: 'TimeEntryEdge', cursor: string, node: { __typename?: 'TimeEntry', id: string, pings: { __typename?: 'TimeEntryPingConnection', edges: Array<{ __typename?: 'TimeEntryPingEdge', cursor: string, node: { __typename?: 'TimeEntryPing', id: string, state: TimeEntryPingState, updatedAt: any, createdAt: any } }>, pageInfo: (
              { __typename?: 'PageInfo' }
              & PageInfoFieldsFragment
            ) } } }>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFieldsFragment
      ) }
      & TimeEntriesCountInfoFieldsFragment
    )> }> };

export type ProjectsForBillingsQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type ProjectsForBillingsQuery = { __typename?: 'Query', projects?: Maybe<{ __typename?: 'ProjectConnection', edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, code: string, type?: Maybe<ProjectType>, client?: Maybe<{ __typename?: 'Client', id: string, name: string }>, billing?: Maybe<{ __typename?: 'Billing', id: string, state: BillingState, responsiblePerson?: Maybe<string> }> } }> }> };

export type ProjectsForDashboardQueryVariables = Exact<{
  timeFrame: MetricsTimeFrame;
}>;


export type ProjectsForDashboardQuery = { __typename?: 'Query', projects?: Maybe<{ __typename?: 'ProjectConnection', edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, type?: Maybe<ProjectType>, name: string, code: string, shareOfOperationalLimit?: Maybe<number>, metrics?: Maybe<{ __typename?: 'MetricsConnection', edges: Array<Maybe<{ __typename?: 'MetricsEdge', node?: Maybe<{ __typename?: 'Metrics', id: string, workload?: Maybe<number>, workloadPercentageChange?: Maybe<number>, budgetUtilizationSpent: number, budgetUtilizationMinBudget?: Maybe<number>, budgetUtilizationMaxBudget?: Maybe<number>, estimateIndex?: Maybe<number>, estimateIndexPercentageChange?: Maybe<number>, performanceIndex?: Maybe<number>, performanceIndexBudgetRatio?: Maybe<number>, performanceIndexWorkRatio?: Maybe<number>, shareOfOperational?: Maybe<number>, fromDate: any, toDate: any, calculatedAt: any }> }>> }> } }> }> };

export type ProjectsForDeviationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsForDeviationsQuery = { __typename?: 'Query', projects?: Maybe<{ __typename?: 'ProjectConnection', edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, code: string, projectManager?: Maybe<(
          { __typename?: 'User', id: string }
          & UserNameFieldsFragment
        )> } }> }> };

export type ProjectsForProjectSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsForProjectSettingsQuery = { __typename?: 'Query', projects?: Maybe<{ __typename?: 'ProjectConnection', edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, code: string, forApproval: boolean, approvedAt?: Maybe<any>, type?: Maybe<ProjectType>, taskTrackingProviderProjectId?: Maybe<string>, timeTrackingProviderProjectId?: Maybe<string>, groupBy?: Maybe<GroupBy>, taskTrackingProviderProjects: { __typename?: 'TaskTrackingProviderProjectConnection', edges: Array<Maybe<{ __typename?: 'TaskTrackingProviderProjectEdge', node?: Maybe<(
              { __typename?: 'TaskTrackingProviderProject', id: string }
              & ProviderProjectFieldsFragment
            )> }>> }, projectManager?: Maybe<(
          { __typename?: 'User' }
          & UserNameFieldsFragment
        )> } }> }> };

export type ProjectsForReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsForReportsQuery = { __typename?: 'Query', projects?: Maybe<{ __typename?: 'ProjectConnection', edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, code: string, groupBy?: Maybe<GroupBy>, taskTrackingProviderProjectId?: Maybe<string>, projectManager?: Maybe<(
          { __typename?: 'User', id: string }
          & UserNameFieldsFragment
        )> } }> }> };

export type ProjectsForTimeEntriesQueryVariables = Exact<{
  tillDate: Scalars['Date'];
}>;


export type ProjectsForTimeEntriesQuery = { __typename?: 'Query', projects?: Maybe<{ __typename?: 'ProjectConnection', edges: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'Project', id: string, name: string, forApproval: boolean, approvedTill?: Maybe<any>, approvedAt?: Maybe<any>, projectManager?: Maybe<(
          { __typename?: 'User', id: string }
          & UserNameFieldsFragment
        )>, client?: Maybe<{ __typename?: 'Client', id: string, name: string }>, timeEntries?: Maybe<(
          { __typename?: 'TimeEntryConnection' }
          & TimeEntriesCountInfoFieldsFragment
        )> } }> }> };

export type TaskTrackingProviderForProjectsSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskTrackingProviderForProjectsSettingsQuery = { __typename?: 'Query', taskTrackingProvider?: Maybe<{ __typename?: 'TaskTrackingProvider', id: string, remoteProjects?: Maybe<{ __typename?: 'RemoteProjectConnection', edges: Array<Maybe<{ __typename?: 'RemoteProjectEdge', node?: Maybe<{ __typename?: 'RemoteProject', externalId?: Maybe<string>, name?: Maybe<string>, code?: Maybe<string> }> }>> }> }> };

export type TaskTrackingProvidersForProjectsSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TaskTrackingProvidersForProjectsSettingsQuery = { __typename?: 'Query', taskTrackingProviders?: Maybe<{ __typename?: 'TaskTrackingProviderConnection', edges: Array<Maybe<{ __typename?: 'TaskTrackingProviderEdge', node?: Maybe<{ __typename?: 'TaskTrackingProvider', id: string, name: string, remoteProjects?: Maybe<{ __typename?: 'RemoteProjectConnection', edges: Array<Maybe<{ __typename?: 'RemoteProjectEdge', node?: Maybe<{ __typename?: 'RemoteProject', externalId?: Maybe<string>, name?: Maybe<string>, code?: Maybe<string> }> }>> }> }> }>> }> };

export type TasksForDeviationsQueryVariables = Exact<{
  project: Maybe<Scalars['ID']>;
  searchQuery: Maybe<Scalars['String']>;
  orderBy: Maybe<Scalars['String']>;
  fromDate: Maybe<Scalars['Date']>;
  tillDate: Maybe<Scalars['Date']>;
  billable: Maybe<Scalars['Boolean']>;
  first: Maybe<Scalars['Int']>;
  cursor: Maybe<Scalars['String']>;
}>;


export type TasksForDeviationsQuery = { __typename?: 'Query', tasks: { __typename?: 'TaskConnection', edges: Array<{ __typename?: 'TaskEdge', cursor: string, node: { __typename?: 'Task', id: string, code: string, title: string, state?: Maybe<string>, estimateTotal?: Maybe<number>, assignee?: Maybe<string>, type?: Maybe<string>, timeSpentTotal?: Maybe<number>, divergence?: Maybe<number> } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) } };

export type TasksForProjectReportQueryVariables = Exact<{
  project: Maybe<Scalars['ID']>;
  fromDate: Maybe<Scalars['Date']>;
  tillDate: Maybe<Scalars['Date']>;
  billable: Maybe<Scalars['Boolean']>;
}>;


export type TasksForProjectReportQuery = { __typename?: 'Query', tasks: { __typename?: 'TaskConnection', edges: Array<{ __typename?: 'TaskEdge', cursor: string, node: { __typename?: 'Task', id: string, code: string, title: string, state?: Maybe<string>, estimateTotal?: Maybe<number>, assignee?: Maybe<string>, type?: Maybe<string>, timeSpentTotal?: Maybe<number>, divergence?: Maybe<number>, externalCreatedAt: any, reportStatus: TaskStatus } }>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFieldsFragment
    ) } };

export type TimeTrackingPingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeTrackingPingsQuery = { __typename?: 'Query', timeEntryPings: { __typename?: 'TimeEntryPingConnection', edges: Array<{ __typename?: 'TimeEntryPingEdge', node: { __typename?: 'TimeEntryPing', id: string, createdAt: any, updatedAt: any, state: TimeEntryPingState, teChangedAfterResolved: boolean, invokedBy?: Maybe<(
          { __typename?: 'User' }
          & UserNameFieldsFragment
        )>, timeEntry?: Maybe<{ __typename?: 'TimeEntry', id: string, notes?: Maybe<string>, task: string, seconds: number, project: { __typename?: 'Project', code: string, name: string }, worker?: Maybe<{ __typename?: 'Worker', id: string, firstName: string, lastName: string }> }> } }> } };

export type TimeTrackingProviderForProjectsSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeTrackingProviderForProjectsSettingsQuery = { __typename?: 'Query', timeTrackingProvider?: Maybe<{ __typename?: 'TimeTrackingProvider', remoteProjects?: Maybe<{ __typename?: 'RemoteProjectConnection', edges: Array<Maybe<{ __typename?: 'RemoteProjectEdge', node?: Maybe<{ __typename?: 'RemoteProject', externalId?: Maybe<string>, name?: Maybe<string>, code?: Maybe<string> }> }>> }> }> };

export type UsersForProjectSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersForProjectSettingsQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', edges: Array<Maybe<{ __typename?: 'UserEdge', node?: Maybe<(
        { __typename?: 'User', id: string, role?: Maybe<UserRole> }
        & UserNameFieldsFragment
      )> }>> } };

export const BillingStateChangeFieldsFragmentDoc = gql`
    fragment BillingStateChangeFields on BillingStateChange {
  toState
  createdAt
  note
}
    `;
export const PageInfoFieldsFragmentDoc = gql`
    fragment PageInfoFields on PageInfo {
  endCursor
  startCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const ProjectBillingFieldsFragmentDoc = gql`
    fragment ProjectBillingFields on Project {
  name
  code
  groupBy
  type
  showPrices
  currency
  language
  showVatInfo
  pmPrice
  pmFee
  pmPercent
}
    `;
export const ProviderProjectFieldsFragmentDoc = gql`
    fragment ProviderProjectFields on TaskTrackingProviderProject {
  code
  taskTrackingProvider {
    id
    name
  }
  taskTrackingProviderProjectId
}
    `;
export const WorkerNameFieldsFragmentDoc = gql`
    fragment WorkerNameFields on Worker {
  firstName
  lastName
}
    `;
export const TimeEntriesBillingFieldsFragmentDoc = gql`
    fragment TimeEntriesBillingFields on TimeEntry {
  id
  task
  spentAt
  reportNotes
  seconds
  secondsBillable
  billableRate
  billable
  worker {
    id
    ...WorkerNameFields
  }
  taskModel {
    id
    code
    type
    timeSpent
    timeSpentTotal
    estimateTotal
    title
    parent {
      id
      code
      title
      parent {
        id
        code
        title
      }
    }
  }
}
    ${WorkerNameFieldsFragmentDoc}`;
export const TimeEntriesCountInfoFieldsFragmentDoc = gql`
    fragment TimeEntriesCountInfoFields on TimeEntryConnection {
  forApprovalCount
  totalCount
}
    `;
export const TimeEntryApproveFieldsFragmentDoc = gql`
    fragment TimeEntryApproveFields on TimeEntry {
  approved
  forApproval
}
    `;
export const UserNameFieldsFragmentDoc = gql`
    fragment UserNameFields on User {
  firstName
  lastName
}
    `;
export const VersionFieldsFragmentDoc = gql`
    fragment VersionFields on TimeEntryVersion {
  createdAt
  notes
  seconds
  task
}
    `;
export const TriggerBillingDocument = gql`
    mutation TriggerBilling($input: BillingTriggerInput!) {
  billingTrigger(input: $input) {
    billing {
      id
      state
      triggers
      stateChanges {
        edges {
          node {
            id
            ...BillingStateChangeFields
            user {
              id
              ...UserNameFields
            }
          }
        }
      }
    }
  }
}
    ${BillingStateChangeFieldsFragmentDoc}
${UserNameFieldsFragmentDoc}`;

/**
 * __useTriggerBillingMutation__
 *
 * To run a mutation, you first call `useTriggerBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerBillingMutation, { data, loading, error }] = useTriggerBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerBillingMutation(baseOptions?: Apollo.MutationHookOptions<TriggerBillingMutation, TriggerBillingMutationVariables>) {
        return Apollo.useMutation<TriggerBillingMutation, TriggerBillingMutationVariables>(TriggerBillingDocument, baseOptions);
      }
export type TriggerBillingMutationHookResult = ReturnType<typeof useTriggerBillingMutation>;
export type TriggerBillingMutationResult = Apollo.MutationResult<TriggerBillingMutation>;
export type TriggerBillingMutationOptions = Apollo.BaseMutationOptions<TriggerBillingMutation, TriggerBillingMutationVariables>;
export const ApproveProjectDocument = gql`
    mutation ApproveProject($input: ProjectApproveInput!, $tillDate: Date!) {
  projectApprove(input: $input) {
    project {
      id
      name
      code
      forApproval(toDate: $tillDate)
      approvedAt
      approvedTill
      timeEntries(new: true, tillDate: $tillDate) {
        ...TimeEntriesCountInfoFields
      }
    }
  }
}
    ${TimeEntriesCountInfoFieldsFragmentDoc}`;

/**
 * __useApproveProjectMutation__
 *
 * To run a mutation, you first call `useApproveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveProjectMutation, { data, loading, error }] = useApproveProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      tillDate: // value for 'tillDate'
 *   },
 * });
 */
export function useApproveProjectMutation(baseOptions?: Apollo.MutationHookOptions<ApproveProjectMutation, ApproveProjectMutationVariables>) {
        return Apollo.useMutation<ApproveProjectMutation, ApproveProjectMutationVariables>(ApproveProjectDocument, baseOptions);
      }
export type ApproveProjectMutationHookResult = ReturnType<typeof useApproveProjectMutation>;
export type ApproveProjectMutationResult = Apollo.MutationResult<ApproveProjectMutation>;
export type ApproveProjectMutationOptions = Apollo.BaseMutationOptions<ApproveProjectMutation, ApproveProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($input: ProjectUpdateInput!) {
  projectUpdate(input: $input) {
    project {
      id
      name
      code
      type
      projectManager {
        id
        ...UserNameFields
      }
      showPrices
      pmFee
      pmPercent
      pmPrice
      taskCodeRegex
      language
      showVatInfo
      currency
      groupBy
      taskTrackingProviderProjectId
      timeAllocated
      taskFinalStates
      taskBacklogStates
      shareOfOperationalLimit
      budgetSpentMin
      budgetSpentMax
    }
  }
}
    ${UserNameFieldsFragmentDoc}`;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const ApproveTimeEntryDocument = gql`
    mutation ApproveTimeEntry($input: TimeEntryApproveInput!, $tillDate: Date) {
  timeEntryApprove(input: $input) {
    timeEntry {
      id
      ...TimeEntryApproveFields
      project {
        id
        timeEntries(new: true, tillDate: $tillDate, first: 0) {
          forApprovalCount
        }
      }
    }
  }
}
    ${TimeEntryApproveFieldsFragmentDoc}`;

/**
 * __useApproveTimeEntryMutation__
 *
 * To run a mutation, you first call `useApproveTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTimeEntryMutation, { data, loading, error }] = useApproveTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      tillDate: // value for 'tillDate'
 *   },
 * });
 */
export function useApproveTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<ApproveTimeEntryMutation, ApproveTimeEntryMutationVariables>) {
        return Apollo.useMutation<ApproveTimeEntryMutation, ApproveTimeEntryMutationVariables>(ApproveTimeEntryDocument, baseOptions);
      }
export type ApproveTimeEntryMutationHookResult = ReturnType<typeof useApproveTimeEntryMutation>;
export type ApproveTimeEntryMutationResult = Apollo.MutationResult<ApproveTimeEntryMutation>;
export type ApproveTimeEntryMutationOptions = Apollo.BaseMutationOptions<ApproveTimeEntryMutation, ApproveTimeEntryMutationVariables>;
export const PingTimeEntryDocument = gql`
    mutation PingTimeEntry($input: TimeEntryPingCreateInput!) {
  timeEntryPingCreate(input: $input) {
    timeEntryPing {
      id
      createdAt
      updatedAt
      state
      timeEntry {
        id
        pings {
          edges {
            node {
              id
              createdAt
              updatedAt
              state
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePingTimeEntryMutation__
 *
 * To run a mutation, you first call `usePingTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePingTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pingTimeEntryMutation, { data, loading, error }] = usePingTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePingTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<PingTimeEntryMutation, PingTimeEntryMutationVariables>) {
        return Apollo.useMutation<PingTimeEntryMutation, PingTimeEntryMutationVariables>(PingTimeEntryDocument, baseOptions);
      }
export type PingTimeEntryMutationHookResult = ReturnType<typeof usePingTimeEntryMutation>;
export type PingTimeEntryMutationResult = Apollo.MutationResult<PingTimeEntryMutation>;
export type PingTimeEntryMutationOptions = Apollo.BaseMutationOptions<PingTimeEntryMutation, PingTimeEntryMutationVariables>;
export const ResetTimeEntryDocument = gql`
    mutation ResetTimeEntry($input: TimeEntryResetInput!) {
  timeEntryReset(input: $input) {
    timeEntries {
      id
      task
      spentAt
      notes
      seconds
      secondsBillable
      billableRate
      billable
      worker {
        id
        ...WorkerNameFields
      }
      taskModel {
        id
        code
        type
        timeSpent
        timeSpentTotal
        estimateTotal
        title
        parent {
          id
          code
          title
        }
      }
    }
  }
}
    ${WorkerNameFieldsFragmentDoc}`;

/**
 * __useResetTimeEntryMutation__
 *
 * To run a mutation, you first call `useResetTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTimeEntryMutation, { data, loading, error }] = useResetTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<ResetTimeEntryMutation, ResetTimeEntryMutationVariables>) {
        return Apollo.useMutation<ResetTimeEntryMutation, ResetTimeEntryMutationVariables>(ResetTimeEntryDocument, baseOptions);
      }
export type ResetTimeEntryMutationHookResult = ReturnType<typeof useResetTimeEntryMutation>;
export type ResetTimeEntryMutationResult = Apollo.MutationResult<ResetTimeEntryMutation>;
export type ResetTimeEntryMutationOptions = Apollo.BaseMutationOptions<ResetTimeEntryMutation, ResetTimeEntryMutationVariables>;
export const ResyncTimeEntryDocument = gql`
    mutation ResyncTimeEntry($input: TimeEntryResyncInput!) {
  timeEntryResync(input: $input) {
    timeEntry {
      id
      task
      notes
      seconds
      spentAt
      updatedAt
      permalink
      ...TimeEntryApproveFields
      worker {
        id
        ...WorkerNameFields
      }
      project {
        id
      }
      versions {
        edges {
          node {
            id
            ...VersionFields
          }
        }
      }
    }
  }
}
    ${TimeEntryApproveFieldsFragmentDoc}
${WorkerNameFieldsFragmentDoc}
${VersionFieldsFragmentDoc}`;

/**
 * __useResyncTimeEntryMutation__
 *
 * To run a mutation, you first call `useResyncTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncTimeEntryMutation, { data, loading, error }] = useResyncTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResyncTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<ResyncTimeEntryMutation, ResyncTimeEntryMutationVariables>) {
        return Apollo.useMutation<ResyncTimeEntryMutation, ResyncTimeEntryMutationVariables>(ResyncTimeEntryDocument, baseOptions);
      }
export type ResyncTimeEntryMutationHookResult = ReturnType<typeof useResyncTimeEntryMutation>;
export type ResyncTimeEntryMutationResult = Apollo.MutationResult<ResyncTimeEntryMutation>;
export type ResyncTimeEntryMutationOptions = Apollo.BaseMutationOptions<ResyncTimeEntryMutation, ResyncTimeEntryMutationVariables>;
export const UnapproveTimeEntryDocument = gql`
    mutation UnapproveTimeEntry($input: TimeEntryUnapproveInput!, $tillDate: Date) {
  timeEntryUnapprove(input: $input) {
    timeEntry {
      id
      task
      ...TimeEntryApproveFields
      worker {
        id
        ...WorkerNameFields
      }
      project {
        id
        timeEntries(new: true, tillDate: $tillDate, first: 0) {
          forApprovalCount
        }
      }
    }
  }
}
    ${TimeEntryApproveFieldsFragmentDoc}
${WorkerNameFieldsFragmentDoc}`;

/**
 * __useUnapproveTimeEntryMutation__
 *
 * To run a mutation, you first call `useUnapproveTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnapproveTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unapproveTimeEntryMutation, { data, loading, error }] = useUnapproveTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      tillDate: // value for 'tillDate'
 *   },
 * });
 */
export function useUnapproveTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<UnapproveTimeEntryMutation, UnapproveTimeEntryMutationVariables>) {
        return Apollo.useMutation<UnapproveTimeEntryMutation, UnapproveTimeEntryMutationVariables>(UnapproveTimeEntryDocument, baseOptions);
      }
export type UnapproveTimeEntryMutationHookResult = ReturnType<typeof useUnapproveTimeEntryMutation>;
export type UnapproveTimeEntryMutationResult = Apollo.MutationResult<UnapproveTimeEntryMutation>;
export type UnapproveTimeEntryMutationOptions = Apollo.BaseMutationOptions<UnapproveTimeEntryMutation, UnapproveTimeEntryMutationVariables>;
export const UpdateTimeEntryDocument = gql`
    mutation UpdateTimeEntry($input: TimeEntryUpdateInput!) {
  timeEntryUpdate(input: $input) {
    timeEntries {
      id
      task
      spentAt
      notes
      seconds
      secondsBillable
      billableRate
      billable
      worker {
        id
        ...WorkerNameFields
      }
      taskModel {
        id
        code
        type
        timeSpent
        timeSpentTotal
        estimateTotal
        title
        parent {
          id
          code
          title
        }
      }
    }
  }
}
    ${WorkerNameFieldsFragmentDoc}`;

/**
 * __useUpdateTimeEntryMutation__
 *
 * To run a mutation, you first call `useUpdateTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeEntryMutation, { data, loading, error }] = useUpdateTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeEntryMutation, UpdateTimeEntryMutationVariables>) {
        return Apollo.useMutation<UpdateTimeEntryMutation, UpdateTimeEntryMutationVariables>(UpdateTimeEntryDocument, baseOptions);
      }
export type UpdateTimeEntryMutationHookResult = ReturnType<typeof useUpdateTimeEntryMutation>;
export type UpdateTimeEntryMutationResult = Apollo.MutationResult<UpdateTimeEntryMutation>;
export type UpdateTimeEntryMutationOptions = Apollo.BaseMutationOptions<UpdateTimeEntryMutation, UpdateTimeEntryMutationVariables>;
export const TokenAuthDocument = gql`
    mutation tokenAuth($input: ObtainJSONWebTokenInput!) {
  tokenAuth(input: $input) {
    token
  }
}
    `;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokenAuthMutation(baseOptions?: Apollo.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>) {
        return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(TokenAuthDocument, baseOptions);
      }
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<TokenAuthMutation, TokenAuthMutationVariables>;
export const DataForTimeEntriesTableDocument = gql`
    query DataForTimeEntriesTable($project: ID!, $tillDate: Date!, $first: Int, $cursor: String) {
  project(id: $project) {
    id
    code
    name
    forApproval(toDate: $tillDate)
  }
  timeEntries(
    new: true
    project: $project
    tillDate: $tillDate
    first: $first
    after: $cursor
  ) {
    totalCount
    edges {
      cursor
      node {
        id
        externalId
        externalUpdatedAt
        ...TimeEntryApproveFields
        notes
        seconds
        spentAt
        task
        updatedAt
        permalink
        worker {
          id
          ...WorkerNameFields
        }
        versions {
          edges {
            node {
              id
              ...VersionFields
            }
          }
        }
        pings {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              state
              createdAt
              updatedAt
              teChangedAfterResolved
            }
          }
        }
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${TimeEntryApproveFieldsFragmentDoc}
${WorkerNameFieldsFragmentDoc}
${VersionFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

/**
 * __useDataForTimeEntriesTableQuery__
 *
 * To run a query within a React component, call `useDataForTimeEntriesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataForTimeEntriesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataForTimeEntriesTableQuery({
 *   variables: {
 *      project: // value for 'project'
 *      tillDate: // value for 'tillDate'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDataForTimeEntriesTableQuery(baseOptions: Apollo.QueryHookOptions<DataForTimeEntriesTableQuery, DataForTimeEntriesTableQueryVariables>) {
        return Apollo.useQuery<DataForTimeEntriesTableQuery, DataForTimeEntriesTableQueryVariables>(DataForTimeEntriesTableDocument, baseOptions);
      }
export function useDataForTimeEntriesTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataForTimeEntriesTableQuery, DataForTimeEntriesTableQueryVariables>) {
          return Apollo.useLazyQuery<DataForTimeEntriesTableQuery, DataForTimeEntriesTableQueryVariables>(DataForTimeEntriesTableDocument, baseOptions);
        }
export type DataForTimeEntriesTableQueryHookResult = ReturnType<typeof useDataForTimeEntriesTableQuery>;
export type DataForTimeEntriesTableLazyQueryHookResult = ReturnType<typeof useDataForTimeEntriesTableLazyQuery>;
export type DataForTimeEntriesTableQueryResult = Apollo.QueryResult<DataForTimeEntriesTableQuery, DataForTimeEntriesTableQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    ...UserNameFields
    userId
    email
    isActive
    role
  }
}
    ${UserNameFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ProjectForBillingDetailDocument = gql`
    query ProjectForBillingDetail($id: ID!, $year: Int!, $month: Int!) {
  project(id: $id) {
    id
    client {
      id
      name
    }
    name
    code
    type
    billing(year: $year, month: $month) {
      id
      state
      triggers
      stateChanges {
        edges {
          node {
            id
            ...BillingStateChangeFields
            user {
              id
              ...UserNameFields
            }
          }
        }
      }
      timelineEvents {
        id
        createdAt
        toState
        note
        user {
          id
          ...UserNameFields
        }
      }
    }
    previousBillings(year: $year, month: $month, first: 3) {
      edges {
        node {
          id
          state
          stateChanges {
            edges {
              node {
                id
                ...BillingStateChangeFields
                user {
                  id
                  ...UserNameFields
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${BillingStateChangeFieldsFragmentDoc}
${UserNameFieldsFragmentDoc}`;

/**
 * __useProjectForBillingDetailQuery__
 *
 * To run a query within a React component, call `useProjectForBillingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectForBillingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectForBillingDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useProjectForBillingDetailQuery(baseOptions: Apollo.QueryHookOptions<ProjectForBillingDetailQuery, ProjectForBillingDetailQueryVariables>) {
        return Apollo.useQuery<ProjectForBillingDetailQuery, ProjectForBillingDetailQueryVariables>(ProjectForBillingDetailDocument, baseOptions);
      }
export function useProjectForBillingDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectForBillingDetailQuery, ProjectForBillingDetailQueryVariables>) {
          return Apollo.useLazyQuery<ProjectForBillingDetailQuery, ProjectForBillingDetailQueryVariables>(ProjectForBillingDetailDocument, baseOptions);
        }
export type ProjectForBillingDetailQueryHookResult = ReturnType<typeof useProjectForBillingDetailQuery>;
export type ProjectForBillingDetailLazyQueryHookResult = ReturnType<typeof useProjectForBillingDetailLazyQuery>;
export type ProjectForBillingDetailQueryResult = Apollo.QueryResult<ProjectForBillingDetailQuery, ProjectForBillingDetailQueryVariables>;
export const ProjectForBillingDetailReportDocument = gql`
    query ProjectForBillingDetailReport($id: ID!, $year: Int!, $month: Int!) {
  project(id: $id) {
    id
    ...ProjectBillingFields
    billing(year: $year, month: $month) {
      id
      timeEntries {
        edges {
          node {
            id
            ...TimeEntriesBillingFields
          }
        }
      }
    }
  }
}
    ${ProjectBillingFieldsFragmentDoc}
${TimeEntriesBillingFieldsFragmentDoc}`;

/**
 * __useProjectForBillingDetailReportQuery__
 *
 * To run a query within a React component, call `useProjectForBillingDetailReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectForBillingDetailReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectForBillingDetailReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useProjectForBillingDetailReportQuery(baseOptions: Apollo.QueryHookOptions<ProjectForBillingDetailReportQuery, ProjectForBillingDetailReportQueryVariables>) {
        return Apollo.useQuery<ProjectForBillingDetailReportQuery, ProjectForBillingDetailReportQueryVariables>(ProjectForBillingDetailReportDocument, baseOptions);
      }
export function useProjectForBillingDetailReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectForBillingDetailReportQuery, ProjectForBillingDetailReportQueryVariables>) {
          return Apollo.useLazyQuery<ProjectForBillingDetailReportQuery, ProjectForBillingDetailReportQueryVariables>(ProjectForBillingDetailReportDocument, baseOptions);
        }
export type ProjectForBillingDetailReportQueryHookResult = ReturnType<typeof useProjectForBillingDetailReportQuery>;
export type ProjectForBillingDetailReportLazyQueryHookResult = ReturnType<typeof useProjectForBillingDetailReportLazyQuery>;
export type ProjectForBillingDetailReportQueryResult = Apollo.QueryResult<ProjectForBillingDetailReportQuery, ProjectForBillingDetailReportQueryVariables>;
export const ProjectForDeviationReportDocument = gql`
    query ProjectForDeviationReport($id: ID!) {
  project(id: $id) {
    id
    language
  }
}
    `;

/**
 * __useProjectForDeviationReportQuery__
 *
 * To run a query within a React component, call `useProjectForDeviationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectForDeviationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectForDeviationReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectForDeviationReportQuery(baseOptions: Apollo.QueryHookOptions<ProjectForDeviationReportQuery, ProjectForDeviationReportQueryVariables>) {
        return Apollo.useQuery<ProjectForDeviationReportQuery, ProjectForDeviationReportQueryVariables>(ProjectForDeviationReportDocument, baseOptions);
      }
export function useProjectForDeviationReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectForDeviationReportQuery, ProjectForDeviationReportQueryVariables>) {
          return Apollo.useLazyQuery<ProjectForDeviationReportQuery, ProjectForDeviationReportQueryVariables>(ProjectForDeviationReportDocument, baseOptions);
        }
export type ProjectForDeviationReportQueryHookResult = ReturnType<typeof useProjectForDeviationReportQuery>;
export type ProjectForDeviationReportLazyQueryHookResult = ReturnType<typeof useProjectForDeviationReportLazyQuery>;
export type ProjectForDeviationReportQueryResult = Apollo.QueryResult<ProjectForDeviationReportQuery, ProjectForDeviationReportQueryVariables>;
export const ProjectForInvoiceDocument = gql`
    query ProjectForInvoice($id: ID!, $fromDate: Date!, $tillDate: Date!, $unbilledTillDate: Date, $onlyUnbilled: Boolean) {
  project(id: $id) {
    id
    ...ProjectBillingFields
    timeEntries(
      fromDate: $fromDate
      tillDate: $tillDate
      onlyUnbilled: $onlyUnbilled
    ) {
      edges {
        node {
          id
          ...TimeEntriesBillingFields
        }
      }
    }
    unbilledTimeEntries(first: 1, tillDate: $unbilledTillDate) {
      totalCount
      edges {
        node {
          spentAt
        }
      }
    }
  }
}
    ${ProjectBillingFieldsFragmentDoc}
${TimeEntriesBillingFieldsFragmentDoc}`;

/**
 * __useProjectForInvoiceQuery__
 *
 * To run a query within a React component, call `useProjectForInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectForInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectForInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fromDate: // value for 'fromDate'
 *      tillDate: // value for 'tillDate'
 *      unbilledTillDate: // value for 'unbilledTillDate'
 *      onlyUnbilled: // value for 'onlyUnbilled'
 *   },
 * });
 */
export function useProjectForInvoiceQuery(baseOptions: Apollo.QueryHookOptions<ProjectForInvoiceQuery, ProjectForInvoiceQueryVariables>) {
        return Apollo.useQuery<ProjectForInvoiceQuery, ProjectForInvoiceQueryVariables>(ProjectForInvoiceDocument, baseOptions);
      }
export function useProjectForInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectForInvoiceQuery, ProjectForInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<ProjectForInvoiceQuery, ProjectForInvoiceQueryVariables>(ProjectForInvoiceDocument, baseOptions);
        }
export type ProjectForInvoiceQueryHookResult = ReturnType<typeof useProjectForInvoiceQuery>;
export type ProjectForInvoiceLazyQueryHookResult = ReturnType<typeof useProjectForInvoiceLazyQuery>;
export type ProjectForInvoiceQueryResult = Apollo.QueryResult<ProjectForInvoiceQuery, ProjectForInvoiceQueryVariables>;
export const ProjectForProjectSettingsDocument = gql`
    query ProjectForProjectSettings($id: ID!) {
  project(id: $id) {
    id
    name
    code
    type
    projectManager {
      id
      ...UserNameFields
    }
    showPrices
    pmFee
    pmPercent
    pmPrice
    taskCodeRegex
    language
    showVatInfo
    currency
    groupBy
    taskTrackingProviderProjectId
    timeTrackingProviderProjectId
    taskTrackingProviderProjects {
      edges {
        node {
          id
          ...ProviderProjectFields
        }
      }
    }
    taskTrackingProvider {
      id
      name
    }
    timeAllocated
    taskFinalStates
    taskBacklogStates
    shareOfOperationalLimit
    budgetSpentMin
    budgetSpentMax
  }
}
    ${UserNameFieldsFragmentDoc}
${ProviderProjectFieldsFragmentDoc}`;

/**
 * __useProjectForProjectSettingsQuery__
 *
 * To run a query within a React component, call `useProjectForProjectSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectForProjectSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectForProjectSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectForProjectSettingsQuery(baseOptions: Apollo.QueryHookOptions<ProjectForProjectSettingsQuery, ProjectForProjectSettingsQueryVariables>) {
        return Apollo.useQuery<ProjectForProjectSettingsQuery, ProjectForProjectSettingsQueryVariables>(ProjectForProjectSettingsDocument, baseOptions);
      }
export function useProjectForProjectSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectForProjectSettingsQuery, ProjectForProjectSettingsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectForProjectSettingsQuery, ProjectForProjectSettingsQueryVariables>(ProjectForProjectSettingsDocument, baseOptions);
        }
export type ProjectForProjectSettingsQueryHookResult = ReturnType<typeof useProjectForProjectSettingsQuery>;
export type ProjectForProjectSettingsLazyQueryHookResult = ReturnType<typeof useProjectForProjectSettingsLazyQuery>;
export type ProjectForProjectSettingsQueryResult = Apollo.QueryResult<ProjectForProjectSettingsQuery, ProjectForProjectSettingsQueryVariables>;
export const ProjectTimeEntriesInfoDocument = gql`
    query ProjectTimeEntriesInfo($id: ID!, $tillDate: Date!) {
  project(id: $id) {
    id
    forApproval(toDate: $tillDate)
    approvedAt
    approvedTill
    timeEntries(new: true, tillDate: $tillDate) {
      ...TimeEntriesCountInfoFields
      edges {
        cursor
        node {
          id
          pings {
            edges {
              cursor
              node {
                id
                state
                updatedAt
                createdAt
              }
            }
            pageInfo {
              ...PageInfoFields
            }
          }
        }
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
}
    ${TimeEntriesCountInfoFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

/**
 * __useProjectTimeEntriesInfoQuery__
 *
 * To run a query within a React component, call `useProjectTimeEntriesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimeEntriesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimeEntriesInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tillDate: // value for 'tillDate'
 *   },
 * });
 */
export function useProjectTimeEntriesInfoQuery(baseOptions: Apollo.QueryHookOptions<ProjectTimeEntriesInfoQuery, ProjectTimeEntriesInfoQueryVariables>) {
        return Apollo.useQuery<ProjectTimeEntriesInfoQuery, ProjectTimeEntriesInfoQueryVariables>(ProjectTimeEntriesInfoDocument, baseOptions);
      }
export function useProjectTimeEntriesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTimeEntriesInfoQuery, ProjectTimeEntriesInfoQueryVariables>) {
          return Apollo.useLazyQuery<ProjectTimeEntriesInfoQuery, ProjectTimeEntriesInfoQueryVariables>(ProjectTimeEntriesInfoDocument, baseOptions);
        }
export type ProjectTimeEntriesInfoQueryHookResult = ReturnType<typeof useProjectTimeEntriesInfoQuery>;
export type ProjectTimeEntriesInfoLazyQueryHookResult = ReturnType<typeof useProjectTimeEntriesInfoLazyQuery>;
export type ProjectTimeEntriesInfoQueryResult = Apollo.QueryResult<ProjectTimeEntriesInfoQuery, ProjectTimeEntriesInfoQueryVariables>;
export const ProjectsForBillingsDocument = gql`
    query ProjectsForBillings($year: Int!, $month: Int!) {
  projects {
    edges {
      node {
        id
        name
        code
        client {
          id
          name
        }
        type
        billing(year: $year, month: $month) {
          id
          state
          responsiblePerson
        }
      }
    }
  }
}
    `;

/**
 * __useProjectsForBillingsQuery__
 *
 * To run a query within a React component, call `useProjectsForBillingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForBillingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForBillingsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useProjectsForBillingsQuery(baseOptions: Apollo.QueryHookOptions<ProjectsForBillingsQuery, ProjectsForBillingsQueryVariables>) {
        return Apollo.useQuery<ProjectsForBillingsQuery, ProjectsForBillingsQueryVariables>(ProjectsForBillingsDocument, baseOptions);
      }
export function useProjectsForBillingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsForBillingsQuery, ProjectsForBillingsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsForBillingsQuery, ProjectsForBillingsQueryVariables>(ProjectsForBillingsDocument, baseOptions);
        }
export type ProjectsForBillingsQueryHookResult = ReturnType<typeof useProjectsForBillingsQuery>;
export type ProjectsForBillingsLazyQueryHookResult = ReturnType<typeof useProjectsForBillingsLazyQuery>;
export type ProjectsForBillingsQueryResult = Apollo.QueryResult<ProjectsForBillingsQuery, ProjectsForBillingsQueryVariables>;
export const ProjectsForDashboardDocument = gql`
    query ProjectsForDashboard($timeFrame: MetricsTimeFrame!) {
  projects {
    edges {
      node {
        id
        type
        name
        code
        shareOfOperationalLimit
        metrics(first: 5, timeFrame: $timeFrame) {
          edges {
            node {
              id
              workload
              workloadPercentageChange
              budgetUtilizationSpent
              budgetUtilizationMinBudget
              budgetUtilizationMaxBudget
              estimateIndex
              estimateIndexPercentageChange
              performanceIndex
              performanceIndexBudgetRatio
              performanceIndexWorkRatio
              shareOfOperational
              fromDate
              toDate
              calculatedAt
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProjectsForDashboardQuery__
 *
 * To run a query within a React component, call `useProjectsForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForDashboardQuery({
 *   variables: {
 *      timeFrame: // value for 'timeFrame'
 *   },
 * });
 */
export function useProjectsForDashboardQuery(baseOptions: Apollo.QueryHookOptions<ProjectsForDashboardQuery, ProjectsForDashboardQueryVariables>) {
        return Apollo.useQuery<ProjectsForDashboardQuery, ProjectsForDashboardQueryVariables>(ProjectsForDashboardDocument, baseOptions);
      }
export function useProjectsForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsForDashboardQuery, ProjectsForDashboardQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsForDashboardQuery, ProjectsForDashboardQueryVariables>(ProjectsForDashboardDocument, baseOptions);
        }
export type ProjectsForDashboardQueryHookResult = ReturnType<typeof useProjectsForDashboardQuery>;
export type ProjectsForDashboardLazyQueryHookResult = ReturnType<typeof useProjectsForDashboardLazyQuery>;
export type ProjectsForDashboardQueryResult = Apollo.QueryResult<ProjectsForDashboardQuery, ProjectsForDashboardQueryVariables>;
export const ProjectsForDeviationsDocument = gql`
    query ProjectsForDeviations {
  projects {
    edges {
      node {
        id
        name
        code
        projectManager {
          id
          ...UserNameFields
        }
      }
    }
  }
}
    ${UserNameFieldsFragmentDoc}`;

/**
 * __useProjectsForDeviationsQuery__
 *
 * To run a query within a React component, call `useProjectsForDeviationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForDeviationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForDeviationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsForDeviationsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsForDeviationsQuery, ProjectsForDeviationsQueryVariables>) {
        return Apollo.useQuery<ProjectsForDeviationsQuery, ProjectsForDeviationsQueryVariables>(ProjectsForDeviationsDocument, baseOptions);
      }
export function useProjectsForDeviationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsForDeviationsQuery, ProjectsForDeviationsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsForDeviationsQuery, ProjectsForDeviationsQueryVariables>(ProjectsForDeviationsDocument, baseOptions);
        }
export type ProjectsForDeviationsQueryHookResult = ReturnType<typeof useProjectsForDeviationsQuery>;
export type ProjectsForDeviationsLazyQueryHookResult = ReturnType<typeof useProjectsForDeviationsLazyQuery>;
export type ProjectsForDeviationsQueryResult = Apollo.QueryResult<ProjectsForDeviationsQuery, ProjectsForDeviationsQueryVariables>;
export const ProjectsForProjectSettingsDocument = gql`
    query ProjectsForProjectSettings {
  projects {
    edges {
      node {
        id
        name
        code
        forApproval
        approvedAt
        type
        taskTrackingProviderProjectId
        timeTrackingProviderProjectId
        taskTrackingProviderProjects {
          edges {
            node {
              id
              ...ProviderProjectFields
            }
          }
        }
        groupBy
        projectManager {
          ...UserNameFields
        }
      }
    }
  }
}
    ${ProviderProjectFieldsFragmentDoc}
${UserNameFieldsFragmentDoc}`;

/**
 * __useProjectsForProjectSettingsQuery__
 *
 * To run a query within a React component, call `useProjectsForProjectSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForProjectSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForProjectSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsForProjectSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsForProjectSettingsQuery, ProjectsForProjectSettingsQueryVariables>) {
        return Apollo.useQuery<ProjectsForProjectSettingsQuery, ProjectsForProjectSettingsQueryVariables>(ProjectsForProjectSettingsDocument, baseOptions);
      }
export function useProjectsForProjectSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsForProjectSettingsQuery, ProjectsForProjectSettingsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsForProjectSettingsQuery, ProjectsForProjectSettingsQueryVariables>(ProjectsForProjectSettingsDocument, baseOptions);
        }
export type ProjectsForProjectSettingsQueryHookResult = ReturnType<typeof useProjectsForProjectSettingsQuery>;
export type ProjectsForProjectSettingsLazyQueryHookResult = ReturnType<typeof useProjectsForProjectSettingsLazyQuery>;
export type ProjectsForProjectSettingsQueryResult = Apollo.QueryResult<ProjectsForProjectSettingsQuery, ProjectsForProjectSettingsQueryVariables>;
export const ProjectsForReportsDocument = gql`
    query ProjectsForReports {
  projects {
    edges {
      node {
        id
        name
        code
        groupBy
        taskTrackingProviderProjectId
        projectManager {
          id
          ...UserNameFields
        }
      }
    }
  }
}
    ${UserNameFieldsFragmentDoc}`;

/**
 * __useProjectsForReportsQuery__
 *
 * To run a query within a React component, call `useProjectsForReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsForReportsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsForReportsQuery, ProjectsForReportsQueryVariables>) {
        return Apollo.useQuery<ProjectsForReportsQuery, ProjectsForReportsQueryVariables>(ProjectsForReportsDocument, baseOptions);
      }
export function useProjectsForReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsForReportsQuery, ProjectsForReportsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsForReportsQuery, ProjectsForReportsQueryVariables>(ProjectsForReportsDocument, baseOptions);
        }
export type ProjectsForReportsQueryHookResult = ReturnType<typeof useProjectsForReportsQuery>;
export type ProjectsForReportsLazyQueryHookResult = ReturnType<typeof useProjectsForReportsLazyQuery>;
export type ProjectsForReportsQueryResult = Apollo.QueryResult<ProjectsForReportsQuery, ProjectsForReportsQueryVariables>;
export const ProjectsForTimeEntriesDocument = gql`
    query ProjectsForTimeEntries($tillDate: Date!) {
  projects {
    edges {
      node {
        id
        name
        forApproval(toDate: $tillDate)
        approvedTill
        approvedAt
        projectManager {
          id
          ...UserNameFields
        }
        client {
          id
          name
        }
        timeEntries(new: true, tillDate: $tillDate, first: 0) {
          ...TimeEntriesCountInfoFields
        }
      }
    }
  }
}
    ${UserNameFieldsFragmentDoc}
${TimeEntriesCountInfoFieldsFragmentDoc}`;

/**
 * __useProjectsForTimeEntriesQuery__
 *
 * To run a query within a React component, call `useProjectsForTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForTimeEntriesQuery({
 *   variables: {
 *      tillDate: // value for 'tillDate'
 *   },
 * });
 */
export function useProjectsForTimeEntriesQuery(baseOptions: Apollo.QueryHookOptions<ProjectsForTimeEntriesQuery, ProjectsForTimeEntriesQueryVariables>) {
        return Apollo.useQuery<ProjectsForTimeEntriesQuery, ProjectsForTimeEntriesQueryVariables>(ProjectsForTimeEntriesDocument, baseOptions);
      }
export function useProjectsForTimeEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsForTimeEntriesQuery, ProjectsForTimeEntriesQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsForTimeEntriesQuery, ProjectsForTimeEntriesQueryVariables>(ProjectsForTimeEntriesDocument, baseOptions);
        }
export type ProjectsForTimeEntriesQueryHookResult = ReturnType<typeof useProjectsForTimeEntriesQuery>;
export type ProjectsForTimeEntriesLazyQueryHookResult = ReturnType<typeof useProjectsForTimeEntriesLazyQuery>;
export type ProjectsForTimeEntriesQueryResult = Apollo.QueryResult<ProjectsForTimeEntriesQuery, ProjectsForTimeEntriesQueryVariables>;
export const TaskTrackingProviderForProjectsSettingsDocument = gql`
    query TaskTrackingProviderForProjectsSettings($id: ID!) {
  taskTrackingProvider(id: $id) {
    id
    remoteProjects {
      edges {
        node {
          externalId
          name
          code
        }
      }
    }
  }
}
    `;

/**
 * __useTaskTrackingProviderForProjectsSettingsQuery__
 *
 * To run a query within a React component, call `useTaskTrackingProviderForProjectsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTrackingProviderForProjectsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTrackingProviderForProjectsSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskTrackingProviderForProjectsSettingsQuery(baseOptions: Apollo.QueryHookOptions<TaskTrackingProviderForProjectsSettingsQuery, TaskTrackingProviderForProjectsSettingsQueryVariables>) {
        return Apollo.useQuery<TaskTrackingProviderForProjectsSettingsQuery, TaskTrackingProviderForProjectsSettingsQueryVariables>(TaskTrackingProviderForProjectsSettingsDocument, baseOptions);
      }
export function useTaskTrackingProviderForProjectsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTrackingProviderForProjectsSettingsQuery, TaskTrackingProviderForProjectsSettingsQueryVariables>) {
          return Apollo.useLazyQuery<TaskTrackingProviderForProjectsSettingsQuery, TaskTrackingProviderForProjectsSettingsQueryVariables>(TaskTrackingProviderForProjectsSettingsDocument, baseOptions);
        }
export type TaskTrackingProviderForProjectsSettingsQueryHookResult = ReturnType<typeof useTaskTrackingProviderForProjectsSettingsQuery>;
export type TaskTrackingProviderForProjectsSettingsLazyQueryHookResult = ReturnType<typeof useTaskTrackingProviderForProjectsSettingsLazyQuery>;
export type TaskTrackingProviderForProjectsSettingsQueryResult = Apollo.QueryResult<TaskTrackingProviderForProjectsSettingsQuery, TaskTrackingProviderForProjectsSettingsQueryVariables>;
export const TaskTrackingProvidersForProjectsSettingsDocument = gql`
    query TaskTrackingProvidersForProjectsSettings {
  taskTrackingProviders {
    edges {
      node {
        id
        name
        remoteProjects {
          edges {
            node {
              externalId
              name
              code
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTaskTrackingProvidersForProjectsSettingsQuery__
 *
 * To run a query within a React component, call `useTaskTrackingProvidersForProjectsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTrackingProvidersForProjectsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTrackingProvidersForProjectsSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskTrackingProvidersForProjectsSettingsQuery(baseOptions?: Apollo.QueryHookOptions<TaskTrackingProvidersForProjectsSettingsQuery, TaskTrackingProvidersForProjectsSettingsQueryVariables>) {
        return Apollo.useQuery<TaskTrackingProvidersForProjectsSettingsQuery, TaskTrackingProvidersForProjectsSettingsQueryVariables>(TaskTrackingProvidersForProjectsSettingsDocument, baseOptions);
      }
export function useTaskTrackingProvidersForProjectsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTrackingProvidersForProjectsSettingsQuery, TaskTrackingProvidersForProjectsSettingsQueryVariables>) {
          return Apollo.useLazyQuery<TaskTrackingProvidersForProjectsSettingsQuery, TaskTrackingProvidersForProjectsSettingsQueryVariables>(TaskTrackingProvidersForProjectsSettingsDocument, baseOptions);
        }
export type TaskTrackingProvidersForProjectsSettingsQueryHookResult = ReturnType<typeof useTaskTrackingProvidersForProjectsSettingsQuery>;
export type TaskTrackingProvidersForProjectsSettingsLazyQueryHookResult = ReturnType<typeof useTaskTrackingProvidersForProjectsSettingsLazyQuery>;
export type TaskTrackingProvidersForProjectsSettingsQueryResult = Apollo.QueryResult<TaskTrackingProvidersForProjectsSettingsQuery, TaskTrackingProvidersForProjectsSettingsQueryVariables>;
export const TasksForDeviationsDocument = gql`
    query TasksForDeviations($project: ID, $searchQuery: String, $orderBy: String, $fromDate: Date, $tillDate: Date, $billable: Boolean, $first: Int, $cursor: String) {
  tasks(
    project: $project
    search: $searchQuery
    orderBy: $orderBy
    parentTasksOnly: false
    fromDate: $fromDate
    toDate: $tillDate
    first: $first
    after: $cursor
  ) {
    edges {
      cursor
      node {
        id
        code
        title
        state(on: $tillDate)
        estimateTotal
        assignee
        type
        timeSpentTotal(tillDate: $tillDate, billable: $billable)
        divergence(tillDate: $tillDate, billable: $billable)
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PageInfoFieldsFragmentDoc}`;

/**
 * __useTasksForDeviationsQuery__
 *
 * To run a query within a React component, call `useTasksForDeviationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksForDeviationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksForDeviationsQuery({
 *   variables: {
 *      project: // value for 'project'
 *      searchQuery: // value for 'searchQuery'
 *      orderBy: // value for 'orderBy'
 *      fromDate: // value for 'fromDate'
 *      tillDate: // value for 'tillDate'
 *      billable: // value for 'billable'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useTasksForDeviationsQuery(baseOptions?: Apollo.QueryHookOptions<TasksForDeviationsQuery, TasksForDeviationsQueryVariables>) {
        return Apollo.useQuery<TasksForDeviationsQuery, TasksForDeviationsQueryVariables>(TasksForDeviationsDocument, baseOptions);
      }
export function useTasksForDeviationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksForDeviationsQuery, TasksForDeviationsQueryVariables>) {
          return Apollo.useLazyQuery<TasksForDeviationsQuery, TasksForDeviationsQueryVariables>(TasksForDeviationsDocument, baseOptions);
        }
export type TasksForDeviationsQueryHookResult = ReturnType<typeof useTasksForDeviationsQuery>;
export type TasksForDeviationsLazyQueryHookResult = ReturnType<typeof useTasksForDeviationsLazyQuery>;
export type TasksForDeviationsQueryResult = Apollo.QueryResult<TasksForDeviationsQuery, TasksForDeviationsQueryVariables>;
export const TasksForProjectReportDocument = gql`
    query TasksForProjectReport($project: ID, $fromDate: Date, $tillDate: Date, $billable: Boolean) {
  tasks(
    project: $project
    search: ""
    parentTasksOnly: true
    fromDate: $fromDate
    toDate: $tillDate
    orderBy: "code"
  ) {
    edges {
      cursor
      node {
        id
        code
        title
        state(on: $tillDate)
        estimateTotal
        assignee
        type
        timeSpentTotal(tillDate: $tillDate, billable: $billable)
        divergence(tillDate: $tillDate, billable: $billable)
        externalCreatedAt
        reportStatus(on: $tillDate)
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${PageInfoFieldsFragmentDoc}`;

/**
 * __useTasksForProjectReportQuery__
 *
 * To run a query within a React component, call `useTasksForProjectReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksForProjectReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksForProjectReportQuery({
 *   variables: {
 *      project: // value for 'project'
 *      fromDate: // value for 'fromDate'
 *      tillDate: // value for 'tillDate'
 *      billable: // value for 'billable'
 *   },
 * });
 */
export function useTasksForProjectReportQuery(baseOptions?: Apollo.QueryHookOptions<TasksForProjectReportQuery, TasksForProjectReportQueryVariables>) {
        return Apollo.useQuery<TasksForProjectReportQuery, TasksForProjectReportQueryVariables>(TasksForProjectReportDocument, baseOptions);
      }
export function useTasksForProjectReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksForProjectReportQuery, TasksForProjectReportQueryVariables>) {
          return Apollo.useLazyQuery<TasksForProjectReportQuery, TasksForProjectReportQueryVariables>(TasksForProjectReportDocument, baseOptions);
        }
export type TasksForProjectReportQueryHookResult = ReturnType<typeof useTasksForProjectReportQuery>;
export type TasksForProjectReportLazyQueryHookResult = ReturnType<typeof useTasksForProjectReportLazyQuery>;
export type TasksForProjectReportQueryResult = Apollo.QueryResult<TasksForProjectReportQuery, TasksForProjectReportQueryVariables>;
export const TimeTrackingPingsDocument = gql`
    query TimeTrackingPings {
  timeEntryPings {
    edges {
      node {
        id
        createdAt
        updatedAt
        state
        teChangedAfterResolved
        invokedBy {
          ...UserNameFields
        }
        timeEntry {
          id
          notes
          task
          seconds
          project {
            code
            name
          }
          worker {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
}
    ${UserNameFieldsFragmentDoc}`;

/**
 * __useTimeTrackingPingsQuery__
 *
 * To run a query within a React component, call `useTimeTrackingPingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeTrackingPingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeTrackingPingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeTrackingPingsQuery(baseOptions?: Apollo.QueryHookOptions<TimeTrackingPingsQuery, TimeTrackingPingsQueryVariables>) {
        return Apollo.useQuery<TimeTrackingPingsQuery, TimeTrackingPingsQueryVariables>(TimeTrackingPingsDocument, baseOptions);
      }
export function useTimeTrackingPingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeTrackingPingsQuery, TimeTrackingPingsQueryVariables>) {
          return Apollo.useLazyQuery<TimeTrackingPingsQuery, TimeTrackingPingsQueryVariables>(TimeTrackingPingsDocument, baseOptions);
        }
export type TimeTrackingPingsQueryHookResult = ReturnType<typeof useTimeTrackingPingsQuery>;
export type TimeTrackingPingsLazyQueryHookResult = ReturnType<typeof useTimeTrackingPingsLazyQuery>;
export type TimeTrackingPingsQueryResult = Apollo.QueryResult<TimeTrackingPingsQuery, TimeTrackingPingsQueryVariables>;
export const TimeTrackingProviderForProjectsSettingsDocument = gql`
    query TimeTrackingProviderForProjectsSettings {
  timeTrackingProvider {
    remoteProjects {
      edges {
        node {
          externalId
          name
          code
        }
      }
    }
  }
}
    `;

/**
 * __useTimeTrackingProviderForProjectsSettingsQuery__
 *
 * To run a query within a React component, call `useTimeTrackingProviderForProjectsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeTrackingProviderForProjectsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeTrackingProviderForProjectsSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeTrackingProviderForProjectsSettingsQuery(baseOptions?: Apollo.QueryHookOptions<TimeTrackingProviderForProjectsSettingsQuery, TimeTrackingProviderForProjectsSettingsQueryVariables>) {
        return Apollo.useQuery<TimeTrackingProviderForProjectsSettingsQuery, TimeTrackingProviderForProjectsSettingsQueryVariables>(TimeTrackingProviderForProjectsSettingsDocument, baseOptions);
      }
export function useTimeTrackingProviderForProjectsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeTrackingProviderForProjectsSettingsQuery, TimeTrackingProviderForProjectsSettingsQueryVariables>) {
          return Apollo.useLazyQuery<TimeTrackingProviderForProjectsSettingsQuery, TimeTrackingProviderForProjectsSettingsQueryVariables>(TimeTrackingProviderForProjectsSettingsDocument, baseOptions);
        }
export type TimeTrackingProviderForProjectsSettingsQueryHookResult = ReturnType<typeof useTimeTrackingProviderForProjectsSettingsQuery>;
export type TimeTrackingProviderForProjectsSettingsLazyQueryHookResult = ReturnType<typeof useTimeTrackingProviderForProjectsSettingsLazyQuery>;
export type TimeTrackingProviderForProjectsSettingsQueryResult = Apollo.QueryResult<TimeTrackingProviderForProjectsSettingsQuery, TimeTrackingProviderForProjectsSettingsQueryVariables>;
export const UsersForProjectSettingsDocument = gql`
    query UsersForProjectSettings {
  users {
    edges {
      node {
        id
        role
        ...UserNameFields
      }
    }
  }
}
    ${UserNameFieldsFragmentDoc}`;

/**
 * __useUsersForProjectSettingsQuery__
 *
 * To run a query within a React component, call `useUsersForProjectSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersForProjectSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersForProjectSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersForProjectSettingsQuery(baseOptions?: Apollo.QueryHookOptions<UsersForProjectSettingsQuery, UsersForProjectSettingsQueryVariables>) {
        return Apollo.useQuery<UsersForProjectSettingsQuery, UsersForProjectSettingsQueryVariables>(UsersForProjectSettingsDocument, baseOptions);
      }
export function useUsersForProjectSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersForProjectSettingsQuery, UsersForProjectSettingsQueryVariables>) {
          return Apollo.useLazyQuery<UsersForProjectSettingsQuery, UsersForProjectSettingsQueryVariables>(UsersForProjectSettingsDocument, baseOptions);
        }
export type UsersForProjectSettingsQueryHookResult = ReturnType<typeof useUsersForProjectSettingsQuery>;
export type UsersForProjectSettingsLazyQueryHookResult = ReturnType<typeof useUsersForProjectSettingsLazyQuery>;
export type UsersForProjectSettingsQueryResult = Apollo.QueryResult<UsersForProjectSettingsQuery, UsersForProjectSettingsQueryVariables>;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "User",
      "TaskTrackingProvider",
      "TimeEntry",
      "Project",
      "Client",
      "Metrics",
      "Task",
      "TaskTrackingProviderProject",
      "Billing",
      "BillingStateChange",
      "Worker",
      "TimeEntryVersion",
      "TimeEntryPing"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "User",
      "TaskTrackingProvider",
      "TimeEntry",
      "Project",
      "Client",
      "Metrics",
      "Task",
      "TaskTrackingProviderProject",
      "Billing",
      "BillingStateChange",
      "Worker",
      "TimeEntryVersion",
      "TimeEntryPing"
    ]
  }
};
      export default result;
    