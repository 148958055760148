import * as React from "react";
import { Box, Text } from "grommet";
import { Alert as IconAlert } from "grommet-icons";

export const Warning: React.FC = ({ children }) => {
  return (
    <Box
      pad={"small"}
      background={{
        color: "status-warning",
        opacity: 0.1,
      }}
      border={{
        color: "status-warning",
        size: "small",
      }}
      round={"xsmall"}
      direction={"row"}
      align={"center"}
      justify={"center"}
    >
      <IconAlert color={"status-error"} />
      <Box pad={{ left: "small" }}>
        <Text>{children}</Text>
      </Box>
    </Box>
  );
};
