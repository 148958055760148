import { ProjectType } from "api";

// ProjectTypes mapping to human readable strings
export const projectTypeTransformMap: Record<ProjectType, string> = {
  fixed_price: "Fixed Price",
  fixed_price_change_request: "Fixed Price Change Request",
  internal: "Internal",
  time_and_material: "Time and Material",
  sla: "SLA",
};
// transform ProjectType value to human readable string
export const projectTypeToHumanReadable = (type: ProjectType) => projectTypeTransformMap[type];
// transform human readable string to ProjectType
export const humanReadableToProjectType = (type: string): ProjectType | null => {
  const entries = Object.entries(projectTypeTransformMap);
  for (let entry of entries) {
    if (entry[1] === type) {
      return entry[0] as ProjectType;
    }
  }
  return null;
};

// Transform enum into array
export const transformEnumToArray = (enumeration: any) => {
  return Object.keys(enumeration).map((key) => enumeration[key]);
};
