import { Box, Button, Layer, Text } from "grommet";
import { ProjectBillingFieldsFragment, ProjectType } from "api";
import { Spinner } from "components";
import { ProjectInvoiceReportView } from "containers";
import { toDateStr } from "utils";
import { useProjectBillingDetailReport } from "./useProjectBillingDetailReport";

type ProjectBillingReportProps = {
  onExit: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const ProjectBillingReport = (props: ProjectBillingReportProps) => {
  const { data, state } = useProjectBillingDetailReport();

  return (
    <Box width={"100%"}>
      <Layer onEsc={props.onExit} onClickOutside={props.onExit} full={true} margin={"xlarge"}>
        <Box pad={"small"} direction={"row"} justify={"between"} align={"center"} background={"light-2"}>
          <Text size={"large"}>Přehled položek k fakturaci</Text>
          <Button label="Zavřít" onClick={props.onExit} />
        </Box>

        <Box flex>
          {state.loading ? (
            <Spinner />
          ) : (
            <Box flex overflow="auto" pad={"large"}>
              <ProjectInvoiceReportView
                project={data.project as ProjectBillingFieldsFragment}
                timeEntries={data.projectEntries}
                tillDate={toDateStr(state.tillDate)}
                fromDate={toDateStr(state.fromDate)}
                preview={
                  // show preview entries only for FPCHR and TM projects
                  data.project?.type === ProjectType.FixedPriceChangeRequest ||
                  data.project?.type === ProjectType.TimeAndMaterial
                }
                editable={data.project?.type === ProjectType.FixedPriceChangeRequest} // editable only for FPCHR projects
                showControlButtons={
                  // show control bottons only for FPCHR and TM projects
                  data.project?.type === ProjectType.FixedPriceChangeRequest ||
                  data.project?.type === ProjectType.TimeAndMaterial
                }
                showDate={data.project?.type === ProjectType.TimeAndMaterial}
              />
            </Box>
          )}
        </Box>
        {props.onConfirm || props.onCancel ? (
          <Box pad={"small"} direction={"row"} justify={"around"} background={"light-2"}>
            {props.onCancel ? (
              <Button secondary label="Zrušit" onClick={props.onExit} disabled={state.triggerBillingLoading} />
            ) : null}
            {props.onConfirm ? (
              <Button primary label="Potvrdit" onClick={props.onConfirm} disabled={state.triggerBillingLoading} />
            ) : null}
            {state.triggerBillingLoading ? <Spinner /> : null}
          </Box>
        ) : null}
      </Layer>
    </Box>
  );
};
