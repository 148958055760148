import { ApolloProvider } from "@apollo/client";
import { Grommet } from "grommet";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { client } from "api/client";
import { Internal, Print } from "layouts";
import { Login } from "pages";
import { routes } from "routes";
import { theme } from "styles";

import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Redirect from={routes.root} to={routes.login} exact />

          <Route path={routes.print} component={Print} />

          {/* Routes wrapped by Grommet must be initialized on the end of the Switch */}
          <Grommet full theme={theme} themeMode={"light"}>
            <Route path={routes.login} component={Login} />

            <Route path={routes.internal} component={Internal} />
          </Grommet>
        </Switch>
      </Router>

      <ToastContainer />
    </ApolloProvider>
  );
}

export default App;
