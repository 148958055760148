import { Box, Text } from "grommet";
import { Spinner } from "components";
import { BudgetUtilization } from "components/Dashboard/BudgetUtilization/BudgetUtilization";
import { DashboardCard } from "components/Dashboard/DashboardCard";
import { EstimateIndex } from "components/Dashboard/EstimateIndex";
import { ShareOfOperational } from "components/Dashboard/ShareOfOperational";
import { Workload } from "components/Dashboard/Workload/Workload";
import { WorkloadTrend } from "components/Dashboard/Workload/WorkloadTrend";
import { getColorByBudgetUtilization } from "helpers/dashboard";
import { ProjectWithMetrics } from "pages/Dashboard";
import { MetricWrapper } from "./MetricWrapper";

type Props = {
  projectMetrics: ProjectWithMetrics[];
  loading?: boolean;
};
export const TimeAndMaterial = ({ projectMetrics, loading }: Props) => {
  return (
    <Box>
      <Text size={"medium"} margin={{ vertical: "medium" }}>
        Time and Material
      </Text>
      {loading && <Spinner />}
      {projectMetrics &&
        projectMetrics.map((project) => (
          <DashboardCard
            key={project.id}
            label={project.name}
            margin={{ bottom: "small" }}
            color={getColorByBudgetUtilization(
              project.budgetUtilization?.spent,
              project.budgetUtilization?.min,
              project.budgetUtilization?.max
            )}
          >
            <MetricWrapper label={"Workload"}>
              <Workload fte={project.workload?.index} deviation={project.workload?.percentageChange} />
            </MetricWrapper>
            <MetricWrapper label={"Workload Trend"} width={"medium"}>
              <WorkloadTrend data={project.workload?.trend} />
            </MetricWrapper>
            {(project.budgetUtilization?.min || project.budgetUtilization?.max) && (
              <MetricWrapper label={"Budget Utilization"} width={"medium"}>
                <BudgetUtilization
                  spent={project.budgetUtilization?.spent ?? 0}
                  min={project.budgetUtilization?.min}
                  max={project.budgetUtilization?.max}
                />
              </MetricWrapper>
            )}
            <MetricWrapper label={"Estimate Index"}>
              <EstimateIndex index={project.estimate?.index} deviation={project.estimate?.percentageChange} />
            </MetricWrapper>
            <MetricWrapper label={"Share of operational"}>
              <ShareOfOperational
                operationalShare={project.shareOfOperational?.share ?? 0}
                limit={project.shareOfOperational?.limit}
              />
            </MetricWrapper>
          </DashboardCard>
        ))}
    </Box>
  );
};
