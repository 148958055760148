import * as React from "react";
import { Avatar, Box } from "grommet";
import styled from "styled-components";

type IconPointerProps = {
  color: string;
  icon: React.ReactElement;
  size?: number;
};

export const IconPointer = ({ color, icon, size = 24 }: IconPointerProps) => (
  <Box style={{ position: "relative", right: `${size / 2}px` }}>
    <Avatar background={color} size={`${size}px`}>
      {React.cloneElement(icon, { color: "white", size: `${size - size / 2.5}px` })}
    </Avatar>
    <BottomTriangle size={size * 0.375} color={color} />
  </Box>
);

type BottomTriangleProps = {
  size: number;
  color: string;
  backgroundColor?: string;
};
const BottomTriangle = styled.div<BottomTriangleProps>`
  position: absolute;
  width: 0;
  height: 0;
  border-left: ${(props) => props.size}px solid transparent;
  border-right: ${(props) => props.size}px solid transparent;
  border-top: ${(props) => props.size}px solid ${(props) => props.color};
  bottom: -${(props) => props.size / 2}px;
  left: ${(props) => props.size / 3}px;
`;
