import { useMemo } from "react";
import { getEnv } from "utils";

export const useVersion = () => {
  const version = useMemo(() => getEnv("REACT_APP_VERSION"), []);
  const changelogUrl = useMemo(() => getEnv("REACT_APP_CHANGELOG_URL"), []);

  return {
    version,
    changelogUrl,
  };
};
