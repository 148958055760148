import * as React from "react";
import { Box, Text } from "grommet";
import styled from "styled-components";
import { IconPointer } from "components/index";
import { dashboardTheme } from "styles";

export type ProgressBarProps = {
  value: number;
  valueColor?: string;
  backgroundColor?: string;
  before?: React.ReactNode;
  right?: React.ReactNode;
  height?: number;
  goals?: {
    value: number;
    color?: string;
    label?: string;
  }[];
  indicators?: {
    icon: React.ReactElement;
    color: string;
    value: number;
  }[];
};

export const ProgressBar = (props: ProgressBarProps) => {
  const defaultColors = dashboardTheme.colors;
  // default props
  const { height = 15, valueColor = defaultColors.text, backgroundColor = defaultColors.backgroundContrast } = props;

  return (
    <Box direction={"row"} align={"center"} gap={"small"}>
      {props.before}
      <Box direction={"row"} fill={"horizontal"} align={"center"} gap={"small"}>
        <Box
          round={"xsmall"}
          background={backgroundColor}
          fill={"horizontal"}
          height={`${height}px`}
          style={{ position: "relative" }}
        >
          <Box round={{ size: "xsmall" }} background={valueColor} height={`${height}px`} width={`${props.value}%`} />
          {props.goals &&
            props.goals.map((goal, i) => (
              <React.Fragment key={i}>
                <Goal fromStart={goal.value} color={goal.color ?? defaultColors.text} height={height + 20} />
                {goal.label && (
                  <GoalDescription fromStart={goal.value} height={height + 8}>
                    <Text
                      size={"xsmall"}
                      color={defaultColors.text}
                      style={{ position: "relative", left: `-${goal.label.length * 4.5}px` }}
                    >
                      {goal.label}
                    </Text>
                  </GoalDescription>
                )}
              </React.Fragment>
            ))}
          {props.indicators &&
            props.indicators.map((indicator, i) => (
              <AbsoluteWrapper key={i} fromStart={indicator.value} size={height + 15}>
                <IconPointer color={indicator.color} icon={indicator.icon} size={height + 15} />
              </AbsoluteWrapper>
            ))}
        </Box>
        {props.right}
      </Box>
    </Box>
  );
};

type GoalProps = { fromStart: number; color: string; height: number };
const Goal = styled.div<GoalProps>`
  position: absolute;
  left: ${(props) => props.fromStart}%;
  top: -10px;
  height: ${(props) => props.height}px;
  width: 2px;
  background: ${(props) => props.color};
`;

type GoalDescriptionProps = { fromStart: number; height: number };
const GoalDescription = styled.div<GoalDescriptionProps>`
  position: absolute;
  left: ${(props) => props.fromStart}%;
  top: ${(props) => props.height}px;
`;

type AbsoluteWrapperProps = { fromStart: number; size: number };
const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
  position: absolute;
  left: ${(props) => props.fromStart}%;
  top: -${(props) => props.size}px;
`;
