import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  mapConnection,
  ProjectForBillingDetailDocument,
  resolveError,
  Triggers,
  useProjectForBillingDetailQuery,
  useTriggerBillingMutation,
} from "api";
import { useStateQueryParam } from "hooks";
import { BillingsProjectDetailParams } from "routes";
import { DateStr, dateStrToDate, isValidDate, toDateStr } from "utils";

export const useProjectBillingDetail = () => {
  const [period] = useStateQueryParam<DateStr>("period", toDateStr(new Date()), isValidDate);
  const { project: projectId } = useParams<BillingsProjectDetailParams>();

  const periodDate = useMemo(() => {
    const periodDateObj = dateStrToDate(period);

    return {
      year: periodDateObj.get("year"),
      month: periodDateObj.get("month"),
    };
  }, [period]);

  const { data, loading } = useProjectForBillingDetailQuery({
    variables: {
      id: projectId,
      year: periodDate.year,
      month: periodDate.month + 1, // BE works with 1-12 month number format -- we need to add +1 for JS months
    },
    fetchPolicy: "network-only",
    onError: resolveError,
  });

  const [triggerBilling, { loading: triggerBillingLoading }] = useTriggerBillingMutation({
    onError: resolveError,
    refetchQueries: [
      {
        query: ProjectForBillingDetailDocument,
        variables: {
          id: projectId,
          year: periodDate.year,
          month: periodDate.month + 1, // BE works with 1-12 month number format -- we need to add +1 for JS months
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const projectData = useMemo(() => data?.project, [data]);
  const projectBilling = useMemo(() => projectData?.billing, [projectData]);
  const projectBillingStatesChanges = useMemo(() => mapConnection(projectData?.billing?.stateChanges), [projectData]);
  const projectBillingTimelineEvents = useMemo(() => projectBilling?.timelineEvents, [projectBilling]);
  const projectPreviousBillingStatesChanges = useMemo(
    () =>
      mapConnection(projectData?.previousBillings).map((billing) => {
        const stateChanges = mapConnection(billing.stateChanges);
        if (stateChanges.length > 0) {
          return stateChanges[0];
        }
        return billing;
      }),
    [projectData]
  );
  const billingTriggers = useMemo(() => projectBilling?.triggers, [projectBilling]);

  const addBillingEntry = useCallback(
    (trigger: Triggers, note?: string) => {
      if (!projectBilling) {
        return;
      }

      triggerBilling({
        variables: {
          input: {
            billing: projectBilling.id,
            trigger,
            note,
          },
        },
      });
    },
    [projectBilling, triggerBilling]
  );

  return {
    data: {
      project: projectData,
      projectBilling,
      projectBillingStatesChanges,
      projectBillingTimelineEvents,
      projectPreviousBillingStatesChanges,
      billingTriggers,
    },
    state: {
      loading,
      period,
      triggerBillingLoading,
    },
    handlers: {
      addBillingEntry,
    },
  };
};
