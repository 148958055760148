import { Anchor, Box } from "grommet";
import { useVersion } from "./useVersion";

export const Version = () => {
  const { version, changelogUrl } = useVersion();
  return (
    <Box justify={"center"}>
      <Anchor size={"xsmall"} href={changelogUrl} color={"text"} label={`v${version}`} target={"_blank"} />
    </Box>
  );
};
