import { useMemo } from "react";
import { Box, Text } from "grommet";
import { dashboardTheme } from "styles";

type Props = {
  index?: number;
  deviation?: number;
};
export const EstimateIndex = ({ index, deviation }: Props) => {
  const { statusOk, statusWarning, statusDanger, statusNeutral } = dashboardTheme.colors;
  const color = useMemo(() => {
    return !index ? statusNeutral : index < 1 ? statusOk : index > 1.11 ? statusDanger : statusWarning;
  }, [index, statusDanger, statusNeutral, statusOk, statusWarning]);
  return (
    <Box gap={"xxsmall"} align={"center"}>
      <Text size={"xlarge"} color={color}>
        {index ? `${index.toFixed(2)}` : `N/A`}
      </Text>
      <Box round={"xsmall"} background={"light-2"} align={"center"} pad={"xsmall"}>
        <Text size={"small"}>{deviation ? `${deviation.toFixed(2)} %` : "N/A"}</Text>
      </Box>
    </Box>
  );
};
