import { Box, Text } from "grommet";
import { TimeEntry } from "api";

type Props = {
  versions: TimeEntry["versions"];
};

export const VersionDiffTable = ({ versions }: Props) =>
  !versions ? null : (
    <Box>
      <Box direction={"row"} gap={"small"} border={"bottom"}>
        <Box width={"small"}>
          <Text size={"xsmall"}>Vytvořeno</Text>
        </Box>
        <Box width={"small"}>
          <Text size={"xsmall"}>Typ</Text>
        </Box>
        <Box width={"medium"} justify={"start"}>
          <Text size={"xsmall"}>Popis</Text>
        </Box>
        <Box width={"small"} align={"center"}>
          <Text size={"xsmall"}>Čas</Text>
        </Box>
      </Box>
      {versions.edges.map(
        (version) =>
          version &&
          version.node && (
            <Box direction={"row"} gap={"small"} key={version.node.id}>
              <Box width={"small"}>
                <Text size={"small"}>{`${new Date(version.node.createdAt).toLocaleDateString()} ${new Date(
                  version.node?.createdAt
                ).toLocaleTimeString()}`}</Text>
              </Box>
              <Box width={"small"}>
                <Text size={"small"}>{version.node.task}</Text>
              </Box>
              <Box width={"medium"} justify={"start"}>
                <Text size={"small"}>{version.node.notes}</Text>
              </Box>
              <Box width={"small"} align={"center"}>
                <Text size={"small"}>{version.node.seconds / 3600} h</Text>
              </Box>
            </Box>
          )
      )}
    </Box>
  );
