import queryString from "query-string";
import { DateStr } from "utils";

const base = {
  root: "/",
  login: "/login",
  internal: "/app",
  print: "/print",
  error: "/error/:error",
};

const internal = {
  dashboard: base.internal + "/dashboard",

  timeEntries: base.internal + "/time",
  timeEntriesProject: base.internal + "/time/:project",

  reports: base.internal + "/reports",
  reportsProject: base.internal + "/reports/:project",

  deviations: base.internal + "/deviations",
  deviationsProject: base.internal + "/deviations/:project",

  projectsSettings: base.internal + "/projectsSettings",

  pingOverview: base.internal + "/ping",

  billings: base.internal + "/billings",
  billingsProjectDetail: base.internal + "/billings/:project",
  billingsProjectDetailReport: base.internal + "/billings/:project/report",
};

const print = {
  projectReport: base.print + "/project-report/:project",
  projectInvoiceReport: base.print + "/project-invoice-report/:project",
};

type Params = { [key: string]: string | undefined };
type Queries = { [key: string]: string | boolean | undefined };

export type TimeEntriesParams = { project?: string };
export type TimeEntriesQueries = { tillDate?: DateStr };

export type TimeEntriesProjectParams = Required<TimeEntriesParams>;
export type TimeEntriesProjectQueries = TimeEntriesQueries;

export type DeviationsParams = { project?: string };
export type DeviationsQueries = {
  billable?: boolean;
  fromDate?: DateStr;
  tillDate?: DateStr;
};

export type DeviationsProjectParams = Required<DeviationsParams>;
export type DeviationsProjectQueries = DeviationsQueries;

export type ReportsParams = { project?: string };
export type ReportsQueries = {
  fromDate?: DateStr;
  tillDate?: DateStr;
  onlyUnbilled?: boolean;
};
export type ReportsProjectParams = Required<ReportsParams>;
export type ReportsProjectQueries = ReportsQueries;

export type BillingsProjectDetailParams = { project: string };
export type BillingsProjectDetailReportParams = { project: string };
export type BillingsQueries = {
  period?: DateStr;
};

/* printable */
export type ProjectReportParams = { project: string };
export type ProjectReportQueries = {
  billable?: boolean;
  fromDate?: DateStr;
  tillDate?: DateStr;
};
/* printable */
export type ProjectInvoiceReportParams = { project: string };
export type ProjectInvoiceReportQueries = {
  fromDate?: DateStr;
  tillDate?: DateStr;
};

export const routes = {
  ...base,
  ...internal,
  ...print,
};

export const setRouteParams = <P extends Params>(route: string, params: P): string => {
  let url = route;
  for (let [key, value] of Object.entries(params)) {
    if (value) {
      url = url.replace(`:${key}`, value.toString());
    }
  }
  return url;
};

export const setRouteQueries = <Q extends Queries>(route: string, queries: Q): string => {
  const parsed = queryString.parseUrl(route);

  const currentQueries = parsed.query;
  // preserve existing queries, set new or update existing queries
  const updatedQueries = { ...currentQueries, ...queries };

  return queryString.stringifyUrl({
    url: parsed.url,
    query: updatedQueries,
  });
};

export const setRoute = <P extends Params, Q extends Queries>(route: string, params: P, queries?: Q) => {
  const url = setRouteParams(route, params);
  return queries ? setRouteQueries(url, queries) : url;
};

const r = routes;
const s = setRoute;

export const getRoute = {
  root: () => r.root,
  login: () => r.login,
  internal: () => r.internal,
  error: () => r.error,

  dashboard: () => r.dashboard,
  timeEntries: (queries?: TimeEntriesQueries) => s(r.timeEntries, {}, queries),
  timeEntriesProject: (params: TimeEntriesProjectParams, queries?: TimeEntriesProjectQueries) =>
    s(r.timeEntriesProject, params, queries),
  projectsSettings: () => r.projectsSettings,
  reports: (queries?: ReportsQueries) => s(r.reports, {}, queries),
  reportsProject: (params: ReportsProjectParams, queries?: ReportsProjectQueries) =>
    s(r.reportsProject, params, queries),
  deviations: (queries?: DeviationsQueries) => s(r.deviations, {}, queries),
  deviationsProject: (params: DeviationsProjectParams, queries?: DeviationsProjectQueries) =>
    s(r.deviationsProject, params, queries),
  pingOverview: () => r.pingOverview,

  billings: (queries?: BillingsQueries) => s(r.billings, {}, queries),
  billingsProjectDetail: (params: BillingsProjectDetailParams, queries: BillingsQueries) =>
    s(r.billingsProjectDetail, params, queries),
  billingsProjectDetailReport: (params: BillingsProjectDetailReportParams, queries: BillingsQueries) =>
    s(r.billingsProjectDetailReport, params, queries),

  /* printable */
  projectReport: (params: ProjectReportParams, queries: ProjectReportQueries) => s(r.projectReport, params, queries),
  projectInvoiceReport: (params: ProjectInvoiceReportParams, queries: ProjectInvoiceReportQueries) =>
    s(r.projectInvoiceReport, params, queries),
};
