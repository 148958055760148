import { ReactNode, useCallback, useMemo } from "react";
import * as React from "react";
import {
  Box,
  Button,
  CheckBox,
  DataTable,
  DataTableProps,
  DateInput,
  RadioButtonGroup,
  RadioButtonGroupProps,
  Select,
  Stack,
  Text,
  TextInput,
} from "grommet";
import { Alarm, Clear, Search, StatusInfo } from "grommet-icons";
import { RouteComponentProps } from "react-router-dom";
import { useTitle } from "react-use";
import { MainContent, Spinner, TableSortingHeader } from "components";
import { dateInputGlobalProps, getSpendTimeColor, getSpentTimeStatusByPercents } from "helpers";
import { DeviationsParams } from "routes";
import { dataTableBorder } from "styles";
import { secondsToHoursAndMinutes } from "utils";
import { DateRange, OrderTasksBy, useDeviations } from "./useDeviations";

interface Props extends RouteComponentProps<DeviationsParams> {}

export const Deviations = ({ match }: Props) => {
  const selectedProjectId = useMemo<string | undefined>(() => match.params.project, [match.params.project]);
  const { state, data, handlers } = useDeviations(selectedProjectId);
  useTitle("Odchylky");

  type TaskNode = NonNullable<typeof data.tasks>[0];
  const tableColumns = useMemo<DataTableProps["columns"]>(() => {
    return [
      {
        property: "code",
        primary: true,
        header: (
          <TableSortingHeader text={"Jira ID"} registerProperty={OrderTasksBy.CODE} handler={handlers.sortHandler} />
        ),
        align: "start",
        render: (row: TaskNode) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }}>
            {row.code}
          </Text>
        ),
      },
      {
        property: "type",
        header: <TableSortingHeader text={"Typ"} registerProperty={OrderTasksBy.TYPE} handler={handlers.sortHandler} />,
        align: "start",
        render: (row: TaskNode) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }}>
            {row.type}
          </Text>
        ),
      },
      {
        property: "title",
        header: (
          <TableSortingHeader
            text={"Název issue"}
            registerProperty={OrderTasksBy.TITLE}
            handler={handlers.sortHandler}
          />
        ),
        align: "start",
        render: (row: TaskNode) => <Text size={"small"}>{row.title}</Text>,
      },
      {
        property: "state",
        header: (
          <TableSortingHeader text={"Stav"} registerProperty={OrderTasksBy.STATE} handler={handlers.sortHandler} />
        ),
        align: "start",
        render: (row: TaskNode) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }}>
            {row.state}
          </Text>
        ),
      },
      {
        property: "assignee",
        header: (
          <TableSortingHeader
            text={"Vývojář"}
            registerProperty={OrderTasksBy.ASSIGNEE}
            handler={handlers.sortHandler}
          />
        ),
        align: "start",
        render: (row: TaskNode) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }}>
            {row.assignee ?? "-"}
          </Text>
        ),
      },
      {
        property: "timeEstimate",
        header: (
          <TableSortingHeader text={"Odhad"} registerProperty={OrderTasksBy.ESTIMATE} handler={handlers.sortHandler} />
        ),
        align: "end",
        render: (row: TaskNode) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }}>
            {row.estimateTotal ? secondsToHoursAndMinutes(row.estimateTotal) : "-"}
          </Text>
        ),
      },
      {
        property: "timeSpent",
        header: (
          <TableSortingHeader
            text={"Odpracováno"}
            registerProperty={OrderTasksBy.TIME_SPENT}
            handler={handlers.sortHandler}
          />
        ),
        align: "end",
        render: (row: TaskNode) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }}>
            {row.timeSpentTotal ? secondsToHoursAndMinutes(row.timeSpentTotal) : "-"}
          </Text>
        ),
      },
      {
        property: "deviation",
        header: (
          <TableSortingHeader
            text={"Odchylka"}
            registerProperty={OrderTasksBy.DIVERGENCE}
            handler={handlers.sortHandler}
          />
        ),
        align: "end",
        render: (row: TaskNode) => {
          if (!row.divergence) return "-";

          const status = getSpentTimeStatusByPercents(row.divergence);

          return (
            <Text size={"small"} color={getSpendTimeColor(status)} style={{ whiteSpace: "nowrap" }}>
              {row.divergence} % {row.divergence > 110 && <Alarm size={"small"} />}
            </Text>
          );
        },
      },
    ];
  }, [handlers]);

  type DateRangeOption = { value: DateRange; label: string };
  const dateRangeOptions = useMemo<DateRangeOption[]>(
    () => [
      { value: DateRange.Enabled, label: "Od-do" },
      { value: DateRange.FromDate, label: "Od" },
      { value: DateRange.TillDate, label: "Do" },
      { value: DateRange.Disabled, label: "Datum mě nezajímá" },
    ],
    []
  );

  const handleChangeSelect = useCallback(
    (event) => {
      const value = event?.value?.value;
      typeof value === "string" && handlers.setSelectedProject(value);
    },
    [handlers]
  );
  const handleChangeSearchTaskInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => handlers.searchTask(event.target.value),
    [handlers]
  );

  const handleChangeDateRangeSettings: RadioButtonGroupProps["onChange"] = useCallback(
    (event) => handlers.handleChangeDateRangeSettings(event.target.value),
    [handlers]
  );

  return (
    <MainContent>
      {/* Action Area */}
      <Box gap={"small"} pad={{ vertical: "xsmall" }}>
        <Box fill direction={"row-responsive"} gap={"small"} align={"center"} height={{ min: "auto" }}>
          {/* Project Select and Task Search */}
          <Box fill direction={"row-responsive"} align={"center"} gap={"small"} height={{ min: "auto" }}>
            <Box fill>
              <Select
                options={state.selectOptions}
                valueKey={"value"}
                labelKey={"label"}
                value={{ value: selectedProjectId || "" }} // empty string set default value
                onSearch={handlers.handleSearchSelect}
                onChange={handleChangeSelect}
                onClose={handlers.handleCloseSelect}
                icon={state.loadingProjects ? <Spinner /> : undefined}
              />
            </Box>

            <Box fill>
              <Stack anchor="right">
                <TextInput
                  placeholder="Hledat podle názvu ticketu..."
                  value={state.searchTaskQueryValue}
                  onChange={handleChangeSearchTaskInput}
                  icon={<Search />}
                />
                {state.searchTaskQueryValue && (
                  <Button icon={<Clear size={"small"} />} onClick={handlers.resetSearchTaskQuery} />
                )}
              </Stack>
            </Box>
          </Box>

          {/* Billable and submit */}
          <Box
            fill
            direction={"row-responsive"}
            align={"center"}
            gap={"small"}
            justify={"end"}
            width={{ max: "40%" }}
            height={{ min: "auto" }}
          >
            <CheckBox
              toggle
              checked={state.billable}
              onChange={handlers.handleClickCheckboxBillable}
              label={<div>Fakturovatelný pohled</div>}
            />

            <Button
              label={"Uložit report odchylek do PDF"}
              onClick={handlers.handleClickPrintReport}
              disabled={!selectedProjectId}
              size={"small"}
              primary
            />
          </Box>
        </Box>

        <Box direction={"row-responsive"} fill gap={"small"} align={"center"}>
          <Box direction={"row-responsive"} gap={"xsmall"}>
            <Box>
              <DateInput
                {...dateInputGlobalProps}
                value={state.fromDate}
                onChange={handlers.handleChangeInputFromDate}
                buttonProps={{
                  label: `Od ${state.localizedFromDate}`,
                  disabled: !state.enableFromDate,
                }}
              />
              <FormFieldInfoText>ovlivňuje výpis dokončených úkolů</FormFieldInfoText>
            </Box>
            <Box>
              <DateInput
                {...dateInputGlobalProps}
                value={state.tillDate}
                onChange={handlers.handleChangeInputTillDate}
                buttonProps={{
                  label: `Do: ${state.localizedTillDate}`,
                  disabled: !state.enableTillDate,
                }}
              />
              <FormFieldInfoText>ovlivňuje natočený čas a stav úkolů</FormFieldInfoText>
            </Box>
          </Box>
          <RadioButtonGroup
            direction={"row"}
            gap={"small"}
            name="dates-setting"
            options={dateRangeOptions}
            value={state.dateRangeSettings}
            onChange={handleChangeDateRangeSettings}
          />
        </Box>
      </Box>

      {/* Data Table */}
      <Box overflow={"auto"} flex>
        {/* Spinner */}
        {state.loadingTasks && (
          <Box flex={"grow"} pad={"medium"} align={"center"} justify={"center"}>
            <Spinner />
          </Box>
        )}

        {!state.loadingTasks && data.tasks.length > 0 && (
          <Box flex={"grow"}>
            <DataTable<TaskNode>
              columns={tableColumns}
              data={data.tasks}
              border={dataTableBorder}
              primaryKey={"id"}
              step={50}
              onMore={handlers.handleLoadMoreTasks}
              // without "replace" prop onMore callback is not correctly called
              // possible issue: https://github.com/grommet/grommet/issues/4392
              replace
            />
            {state.loadingMoreTasks && (
              <Box pad={"medium"} align={"center"}>
                <Spinner />
              </Box>
            )}
          </Box>
        )}

        {/* No table data */}
        {!state.loadingTasks && data.tasks.length < 1 && (
          <Box pad={"medium"} align={"center"}>
            Žádné úkoly
          </Box>
        )}
      </Box>
    </MainContent>
  );
};

/*
 * Components used only at Deviations page
 * */
const FormFieldInfoText = ({ children }: { children: ReactNode }) => (
  <Box direction={"row"} gap={"xxsmall"} align={"center"}>
    <StatusInfo size={"small"} />
    <Text size={"xsmall"} color={"dark-6"}>
      {children}
    </Text>
  </Box>
);
