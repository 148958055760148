import moment from "moment";

/**
 * Check if input string is valid date
 * */
export const isValidDate = (str: string): boolean => {
  return moment(str, undefined, true).isValid();
};

/**
 * Get first day of month of given date
 * @param date
 */
export const getFirstDayOfMonth = (date: Date = new Date()) => new Date(date.getFullYear(), date.getMonth(), 1);
/**
 * Get last day of month of given date
 * @param date
 */
export const getLastDayOfMonth = (date: Date = new Date()) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

/**
 * Check if date is first day of the month
 * @param date
 * */
export const isFirstDayOfMonth = (date: Date): boolean =>
  date.toDateString() === getFirstDayOfMonth(date).toDateString();

export const localizeDate = (date: Date): string => date.toLocaleDateString("cs");
