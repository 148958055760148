import { useMemo } from "react";
import { Box } from "grommet";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { dashboardTheme } from "styles";

type Props = {
  operationalShare: number;
  limit?: number;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (color: string = "white") => ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return index !== 0 ? null : (
    <text x={x} y={y} fill={color} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" fontSize={8}>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const ShareOfOperational = ({ operationalShare, limit }: Props) => {
  const { colors } = dashboardTheme;
  const color = useMemo(() => {
    const operationalShareOfLimit = limit ? (operationalShare / limit) * 100 : 0;
    return !limit
      ? colors.statusNeutral
      : operationalShareOfLimit < 91
      ? colors.statusOk
      : operationalShareOfLimit > 110
      ? colors.statusDanger
      : colors.statusWarning;
  }, [colors, limit, operationalShare]);
  return (
    <Box fill={true}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey={"value"}
            data={[{ value: operationalShare, label: "operational" }, { value: 100 - operationalShare }]}
            nameKey={"name"}
            outerRadius={"85%"}
            fill={colors.statusSecondaryNeutral}
            labelLine={false}
            label={renderCustomizedLabel()}
            paddingAngle={5}
          >
            <Cell fill={color} />
          </Pie>
          <Pie
            dataKey={"value"}
            nameKey={"name"}
            data={limit ? [{ value: limit }, { value: 100 - limit }] : [{ value: 100 }]}
            innerRadius={"85%"}
            outerRadius={"100%"}
            fill={colors.statusSecondaryNeutral}
            labelLine={false}
            label={limit ? renderCustomizedLabel(colors.text) : false}
            paddingAngle={5}
          >
            {limit && <Cell fill={colors.statusSecondaryOk} />}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
