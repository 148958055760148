import { StrictMode } from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import { ServiceWorkerProvider } from "hooks/useServiceWorker";
import { getEnv } from "utils";
import App from "./App";
// eslint-disable-next-line import/order
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: getEnv("REACT_APP_SENTRY_DSN"),
  release: getEnv("REACT_APP_SENTRY_RELEASE"),
  enabled: getEnv("NODE_ENV") === "production",
});

ReactDOM.render(
  <StrictMode>
    <ServiceWorkerProvider>
      <App />
    </ServiceWorkerProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
