import { useCallback, useMemo, useState } from "react";
import { DataForTimeEntriesTableQuery, mapConnection, resolveError, useDataForTimeEntriesTableQuery } from "api";
import { DateStr, getUnique } from "utils";

type Project = DataForTimeEntriesTableQuery["project"];
type TimeEntryNode = NonNullable<DataForTimeEntriesTableQuery["timeEntries"]>["edges"][0]["node"];

export const useTimeEntriesTable = (projectId: string, tillDate: DateStr) => {
  const { data, loading, fetchMore } = useDataForTimeEntriesTableQuery({
    variables: { project: projectId, tillDate: tillDate, cursor: null, first: 50 },
    skip: !projectId,
    onError: resolveError,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const project = useMemo<Project>(() => data?.project, [data]);
  const timeEntries = useMemo<TimeEntryNode[]>(() => mapConnection(data?.timeEntries), [data]);

  const trackedDates = useMemo<string[]>(() => getUnique(timeEntries.map((item) => item.spentAt)), [timeEntries]);

  const hasNextPage: boolean = useMemo(() => !!data?.timeEntries?.pageInfo.hasNextPage, [data]);
  const totalTimeEntries = useMemo(() => data?.timeEntries?.totalCount, [data]);
  const loadedTimeEntries = useMemo(() => timeEntries.length, [timeEntries.length]);
  const endCursor = useMemo(() => data?.timeEntries?.pageInfo.endCursor, [data]);

  const [loadingMoreTasks, setLoadingMoreTasks] = useState(false);

  /* Grouped Time Entries by spentAt value */
  const timeEntriesMap = useMemo<Map<string, TimeEntryNode[]>>(() => {
    const result = new Map();

    for (let timeEntry of timeEntries) {
      const actualState = result.get(timeEntry.spentAt);
      result.set(timeEntry.spentAt, actualState ? [...actualState, timeEntry] : [timeEntry]);
    }

    return result;
  }, [timeEntries]);

  const handleLoadMoreTimeEntries = useCallback(async () => {
    if (!hasNextPage) return;

    setLoadingMoreTasks(true);
    try {
      await fetchMore({
        variables: {
          cursor: endCursor,
        },
      });
    } catch {
      // handled by Query onError
    }
    setLoadingMoreTasks(false);
  }, [endCursor, fetchMore, hasNextPage]);

  return {
    state: {
      loading,
      loadingMoreTasks,
    },
    data: {
      project,
      timeEntries,

      trackedDates,
      timeEntriesMap,

      hasNextPage,
      totalTimeEntries,
      loadedTimeEntries,
    },
    handlers: {
      handleLoadMoreTimeEntries,
    },
  };
};
