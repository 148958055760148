import { useMemo } from "react";
import * as React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { mapConnection, resolveError, useProjectForInvoiceQuery } from "api";
import { useStateQueryParam } from "hooks";
import { ProjectInvoiceReportParams } from "routes";
import { DateStr, getFirstDayOfMonth, getLastDayOfMonth, isValidDate, toDateStr } from "utils";

const defaultFromDate: Date = getFirstDayOfMonth();
const defaultTillDate: Date = getLastDayOfMonth();

export const useProjectInvoiceReport = () => {
  /*  Variables from router */
  const { project: projectId } = useParams<ProjectInvoiceReportParams>();

  const [fromDate] = useStateQueryParam<DateStr>("fromDate", toDateStr(defaultFromDate), isValidDate);
  const [tillDate] = useStateQueryParam<DateStr>("tillDate", toDateStr(defaultTillDate), isValidDate);
  const unbilledTillDate = toDateStr(moment(fromDate).subtract(1, "days"));
  const [onlyUnbilled] = useStateQueryParam<boolean>("onlyUnbilled", false);

  const { data: dataProject, loading: loadingProject } = useProjectForInvoiceQuery({
    fetchPolicy: "network-only",
    skip: !projectId || !fromDate || !tillDate,
    variables: {
      id: projectId,
      fromDate,
      tillDate,
      unbilledTillDate,
      onlyUnbilled,
    },
    onError: resolveError,
  });

  const project = useMemo(() => dataProject?.project, [dataProject]);
  const entries = React.useMemo(() => mapConnection(project?.timeEntries), [project]);
  const unbilledEntries = React.useMemo(() => mapConnection(project?.unbilledTimeEntries), [project]);

  return {
    data: {
      project,
      entries,
      unbilledEntries,
    },
    state: {
      loading: loadingProject,
      tillDate,
      fromDate,
    },
  };
};
