const PERCENTS_TROUBLE_LIMIT_FOR_SPEND_TIME: number = 110;

export enum SpentTimeStatus {
  NEUTRAL = "neutral",
  OK = "ok",
  WARNING = "warning",
  TROUBLE = "trouble",
}

export const getSpentTimeStatusByPercents = (percents: number): SpentTimeStatus => {
  // nice
  if (percents < 100) return SpentTimeStatus.OK;
  // warning
  if (percents > 100 && percents < PERCENTS_TROUBLE_LIMIT_FOR_SPEND_TIME) return SpentTimeStatus.WARNING;
  // error
  if (percents >= PERCENTS_TROUBLE_LIMIT_FOR_SPEND_TIME) return SpentTimeStatus.TROUBLE;
  // default
  return SpentTimeStatus.NEUTRAL;
};

export const getSpendTimeColor = (status: SpentTimeStatus): string => {
  // nice
  if (status === SpentTimeStatus.OK) return "status-ok";
  // warning
  if (status === SpentTimeStatus.WARNING) return "status-warning";
  // error
  if (status === SpentTimeStatus.TROUBLE) return "status-error";
  // default
  return "";
};
