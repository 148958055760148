import * as React from "react";
import { Box, TextInput } from "grommet";
import { TableBodyCell, TableBodyCellProps } from "./InvoiceReportTemplate";

type TableEditableCellProps = TableBodyCellProps & {
  children: string;
  onEdit: (editedValue: string) => void;
};
export const TableEditableCell = ({ children, onEdit, ...rest }: TableEditableCellProps) => {
  const [editing, setEditing] = React.useState(false);
  const [editingValue, setEditingValue] = React.useState(children);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (editing) {
      setEditingValue(children);
    }
  }, [children, editing]);

  const handleBlur = React.useCallback(() => {
    setEditing(false);
    if (error) {
      return;
    }
    if (editingValue !== children) {
      onEdit(editingValue);
    }
  }, [children, editingValue, error, onEdit]);

  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" || event.key === "Escape") {
        handleBlur();
      }
    },
    [handleBlur]
  );

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEditingValue(value);
    setError(!isValid(value));
  }, []);

  const isValid = (value: string) => /^\d+([,.]?\d)*$/.test(value);

  return editing ? (
    <Box border={{ color: error ? "status-error" : "status-unknown" }} round={"xsmall"}>
      <TextInput
        plain
        value={editingValue}
        autoFocus={true}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    </Box>
  ) : (
    <TableBodyCell onClick={() => setEditing(true)} cursorPointer {...rest}>
      {children}
    </TableBodyCell>
  );
};
