import * as React from "react";
import { Box, Text } from "grommet";
import type { BoxProps, TextProps } from "grommet";
import styled from "styled-components";

type Props = BoxProps & {
  label: string;
  color?: string;
  children?: React.ReactNode;
  labelWrapperProps?: BoxProps;
  labelProps?: TextProps;
};

export const DashboardCard = ({
  color = "status-unknown",
  label,
  children,
  labelWrapperProps,
  labelProps,
  ...rest
}: Props) => {
  return (
    <Card
      direction={"row"}
      border={{ color, side: "left", size: "large" }}
      height={"xsmall"}
      align={"center"}
      pad={{ horizontal: "small", vertical: "small" }}
      gap={"medium"}
      round={"small"}
      {...rest}
    >
      <Box width={"small"} {...labelWrapperProps}>
        <Text {...labelProps}>{label}</Text>
      </Box>
      {children}
    </Card>
  );
};

const Card = styled(Box)`
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5) !important;
`;
