import { useState } from "react";
import { Box, Button, Text } from "grommet";
import { Next, Previous } from "grommet-icons";
import moment from "moment";

type MonthYearPickerProps = {
  onChange: (newPeriod: Date) => void;
  defaultPeriod?: Date;
};
/**
 * Month and Year period picker - works with Date objects
 *
 * getting month out of date objects returns month number 0 - 11,
 * ! be aware that BE works with 1 - 12 month formats when communicating with BE !
 *
 * @param onChange
 * @param defaultPeriod
 * @constructor
 */
export const MonthYearPicker = ({ onChange, defaultPeriod }: MonthYearPickerProps) => {
  const [period, setPeriod] = useState<Date>(defaultPeriod ?? new Date());

  const handleNext = () => {
    const newDate = moment(period).add(1, "month").toDate();
    setPeriod(newDate);
    onChange(newDate);
  };
  const handlePrevious = () => {
    const newDate = moment(period).subtract(1, "month").toDate();
    setPeriod(newDate);
    onChange(newDate);
  };

  return (
    <Box direction={"row"} align={"center"} gap={"small"} border={"all"}>
      <Button icon={<Previous color={"brand"} />} onClick={handlePrevious} />
      <Box pad={"small"} border={{ side: "vertical" }}>
        <Text>{`${moment(period).get("month") + 1} / ${moment(period).get("year")}`}</Text>
      </Box>
      <Button icon={<Next color={"brand"} />} onClick={handleNext} />
    </Box>
  );
};
