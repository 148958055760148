import { useCallback } from "react";
import { useDebouncedValue } from "hooks/useDebouncedValue";

export const useSearch = (defaultValue = "") => {
  const { set, reset, debounced } = useDebouncedValue(defaultValue);

  const search = useCallback(
    (searchValue: typeof defaultValue) => {
      set(searchValue);
    },
    [set]
  );

  const clear = useCallback(() => reset(), [reset]);

  return {
    query: debounced,
    search,
    clear,
  };
};
