import * as React from "react";
import { Box } from "grommet";
import { StatusUnknown } from "grommet-icons";
import { Tooltip } from "components";

type Props = {
  label: string;
  tooltip: string | React.ReactNode;
};

export const LabelWithTooltip = ({ label, tooltip }: Props) => {
  return (
    <Box flex={true} direction={"row"}>
      {label}
      <Tooltip margin={{ left: "xsmall" }} content={tooltip} active={true}>
        <StatusUnknown />
      </Tooltip>
    </Box>
  );
};
