// PROJECT INVOICE TRANSLATIONS
import { BillingState, BillingTimelineEventState, GroupBy, Language, Triggers } from "api";

export type ProjectInvoiceTranslations = {
  [key in Language]: {
    project: {
      title: string;
      operational: string;
      operational_without_pm: string;
      vatInfo: string;
      hours: string;
      rate: string;
      price: string;
      unassigned: string;
    };
    section: {
      [key in GroupBy]: {
        workload: {
          title: string;
          tableSummary: string;
          pmFee: string;
          totalPmIncluded: string;
          hoursUnitText: string;
        };
        breakdown: {
          title: string;
          tableSummary: string;
        };
      };
    };
  };
};

export const projectInvoiceTranslations: ProjectInvoiceTranslations = {
  [Language.Cs]: {
    project: {
      title: "Fakturované práce",
      vatInfo: "Všechny ceny v tomto reportu jsou uvedeny v Kč bez DPH.",
      hours: "Hod.",
      price: "Cena",
      rate: "Hod. sazba",
      operational: "Projektový management, operativní práce týmu",
      operational_without_pm: "Operativní práce týmu",
      unassigned: "NEPŘIŘAZENO",
    },
    section: {
      [GroupBy.Task]: {
        workload: {
          title: "Cenový rozpad podle změnových požadavků",
          tableSummary: "Celkem za projekt",
          pmFee: "Project Management",
          totalPmIncluded: "Celkem za projekt vč. projektového managementu",
          hoursUnitText: "h",
        },
        breakdown: {
          title: "Rozpis prací podle změnových požadavků",
          tableSummary: "Celkem za změnový požadavek",
        },
      },
      [GroupBy.Worker]: {
        workload: {
          title: "Cenový rozpad podle konzultantů",
          tableSummary: "Celkem za projekt",
          pmFee: "Project Management",
          totalPmIncluded: "Celkem za projekt vč. projektového managementu",
          hoursUnitText: "h",
        },
        breakdown: {
          title: "Rozpis prací podle konzultantů",
          tableSummary: "Celkem za konzultanta",
        },
      },
    },
  },
  [Language.En]: {
    project: {
      title: "Work Report",
      vatInfo: "All prices in this report are without VAT.",
      hours: "Hours",
      price: "Price",
      rate: "Hourly Rate",
      operational: "Project management, operational team work",
      operational_without_pm: "Operational team work",
      unassigned: "Unassigned",
    },
    section: {
      [GroupBy.Task]: {
        workload: {
          title: "Time breakdown by tasks",
          tableSummary: "Total",
          pmFee: "Project Management",
          totalPmIncluded: "Total incl. project management",
          hoursUnitText: "hrs",
        },
        breakdown: {
          title: "Work report",
          tableSummary: "Total",
        },
      },
      [GroupBy.Worker]: {
        workload: {
          title: "Time breakdown by workers",
          tableSummary: "Total",
          pmFee: "Project Management",
          totalPmIncluded: "Total incl. project management",
          hoursUnitText: "hrs",
        },
        breakdown: {
          title: "Work report",
          tableSummary: "Total",
        },
      },
    },
  },
};

export const billingTriggersTranslations: Record<Triggers, string> = {
  AP_ACCEPTED: "AP Accepted",
  AP_BLOCKED: "AP Blocked",
  AUTO_BACK_TODO: "Auto To TODO",
  AUTO_HARVEST_DONE: "Auto To Harvest Done",
  HARVEST_DONE: "Harvest Done",
  AUTO_HARVEST_EMPTY: "Auto To Harvest Empty",
  AUTO_NO_INVOICE: "Auto To No Invoice",
  INVOICE: "Invoice",
  INVOICE_NEXT_TIME: "Invoice Next Time",
  NO_INVOICE: "No Invoice",
  PREPARE_FOR_BILLING: "Prepare For Billing",
  REPORT_DONE: "Report Done",
  NOTE: "Note",
};

export const billingStatesTranslations: Record<BillingState | BillingTimelineEventState, string> = {
  ap_accepted: "AP Accepted",
  ap_blocked: "AP Blocked",
  harvest_done: "Harvest Done",
  harvest_empty: "Harvest Empty",
  invoice: "Invoice",
  invoice_next_time: "Invoice Next Time",
  new: "New",
  no_invoice: "No Invoice",
  report_done: "Report Done",
  todo: "Todo",
  NOTE: "Note",
};

export const projectReportTranslations = {
  [Language.Cs]: {
    title: "Přehled odhadu a realizované práce",
    inProgressTitle: "Rozpracované požadavky",
    todoTitle: "Požadavky ke zpracování",
    doneTitle: "Dokončené požadavky",
    column: {
      taskId: "Jira ID",
      taskTitle: "Název",
      estimate: "Odhad",
      timeSpent: "Odpracováno",
      difference: "Odchylka",
      state: "Stav",
      type: "Typ",
    },
    total: "Celkem",
    hoursUnitText: "h",
  },
  [Language.En]: {
    title: "Estimates and time spent",
    inProgressTitle: "Requests In Progress",
    todoTitle: "Requests In Backlog",
    doneTitle: "Completed Requests",
    column: {
      taskId: "Jira ID",
      taskTitle: "Name",
      estimate: "Estimate",
      timeSpent: "Time Spent",
      difference: "Divergence",
      state: "State",
      type: "Type",
    },
    total: "Total",
    hoursUnitText: "hrs",
  },
};
