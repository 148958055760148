import { Box, Text } from "grommet";
import { useTitle } from "react-use";
import { ProjectType } from "api";
import { TimeFramePicker } from "components/Dashboard/TimeFramePicker";
import { FixedPrice } from "containers/DashboardContainers/FixedPrice";
import { FixedPriceCHR } from "containers/DashboardContainers/FixedPriceCHR";
import { Internal } from "containers/DashboardContainers/Internal";
import { SLA } from "containers/DashboardContainers/SLA";
import { TimeAndMaterial } from "containers/DashboardContainers/TimeAndMaterial";
import { Unassigned } from "containers/DashboardContainers/Unassigned";
import { useDashboard } from "pages/Dashboard/useDashboard";

export const Dashboard = () => {
  const {
    state,
    data: { projectsMetrics },
  } = useDashboard();
  useTitle("Přehled");

  return (
    <div style={{ padding: 20 }}>
      <Box justify={"between"} direction={"row"} border={"bottom"} pad={"small"} align={"center"}>
        <Text size={"large"}>Dashboard</Text>
        <TimeFramePicker onChange={state.setTimeFrame} />
      </Box>

      <Internal projectMetrics={projectsMetrics[ProjectType.Internal]} loading={state.isLoading} />

      <FixedPrice projectMetrics={projectsMetrics[ProjectType.FixedPrice]} loading={state.isLoading} />

      <FixedPriceCHR projectMetrics={projectsMetrics[ProjectType.FixedPriceChangeRequest]} loading={state.isLoading} />

      <TimeAndMaterial projectMetrics={projectsMetrics[ProjectType.TimeAndMaterial]} loading={state.isLoading} />

      <SLA projectMetrics={projectsMetrics[ProjectType.Sla]} loading={state.isLoading} />

      <Unassigned projectMetrics={projectsMetrics["unassigned"]} loading={state.isLoading} />
    </div>
  );
};
