import { Box, Button, Form, FormField, Image, Main, Text, TextInput } from "grommet";
import { useTitle } from "react-use";
import logo from "assets/argo22.png";
import { Spinner } from "components";
import { useLogin } from "./useLogin";

export const Login = () => {
  const { inputs, state, handlers } = useLogin();
  useTitle("Přihlášení");

  return (
    <Main align={"center"} fill={true} pad={"medium"} background={"dark-1"} justify={"center"}>
      <Image src={logo} />

      <Box width={"medium"} margin={{ top: "large" }}>
        <Form
          value={state.value}
          validate={"blur"}
          messages={{ required: "Povinné pole" }}
          onChange={handlers.handleChange}
          onSubmit={handlers.handleSubmit}
        >
          <FormField name={inputs.email} label={"Přihlašovací jméno: "} required={true}>
            <TextInput name={inputs.email} disabled={state.loading} />
          </FormField>

          <FormField name={inputs.password} label={"Heslo: "} required={true}>
            <TextInput name={inputs.password} type="password" disabled={state.loading} />
          </FormField>

          {state.error && (
            <Box margin={{ top: "small" }}>
              <Text size={"medium"} color={"status-critical"}>
                Přihlášení se nezdařilo. Zkontrolujte své přihlašovací údaje.
              </Text>
            </Box>
          )}

          <Box direction={"row"} justify={"center"} gap={"small"} margin={{ top: "large" }}>
            <Button type="submit" primary={true} label={"Vstoupit"} disabled={state.loading} />
            {state.loading && <Spinner />}
          </Box>
        </Form>
      </Box>
    </Main>
  );
};
