import { useMemo } from "react";
import {
  Currency,
  GroupBy,
  Language,
  mapConnection,
  ProjectType,
  resolveError,
  UserRole,
  useTaskTrackingProvidersForProjectsSettingsQuery,
  useTimeTrackingProviderForProjectsSettingsQuery,
  useUsersForProjectSettingsQuery,
} from "api";

export const useFormSettingsOptions = () => {
  const { data: usersData, loading: usersLoading } = useUsersForProjectSettingsQuery({ onError: resolveError });

  const {
    data: timeTrackingProviderDetailData,
    loading: timeTrackingProviderLoading,
  } = useTimeTrackingProviderForProjectsSettingsQuery({
    onError: resolveError,
  });

  const {
    data: taskTrackingProvidersData,
    loading: taskTrackingProvidersLoading,
  } = useTaskTrackingProvidersForProjectsSettingsQuery({
    onError: resolveError,
  });

  const timeTrackingProjects = useMemo(
    () => mapConnection(timeTrackingProviderDetailData?.timeTrackingProvider?.remoteProjects),
    [timeTrackingProviderDetailData]
  );

  const taskTrackingProviders = useMemo(() => mapConnection(taskTrackingProvidersData?.taskTrackingProviders), [
    taskTrackingProvidersData,
  ]);

  const taskTrackingProjects = useMemo(
    () => taskTrackingProviders.flatMap((provider) => mapConnection(provider.remoteProjects)),
    [taskTrackingProviders]
  );

  const groupedTaskTrackingProjects = useMemo(() => {
    let group: { [providerId: string]: any } = {};
    taskTrackingProviders.forEach((provider) => {
      if (!group.hasOwnProperty(provider.id)) {
        group = {
          ...group,
          [provider.id]: [],
        };
      }
      group[provider.id] = [...group[provider.id], ...mapConnection(provider.remoteProjects)];
    });
    return group;
  }, [taskTrackingProviders]);

  const groupByOptions = useMemo(() => Object.values(GroupBy), []);

  const currencies = useMemo(() => Object.values(Currency), []);

  const languages = useMemo(() => Object.values(Language), []);

  const projectTypes = useMemo((): Array<string> => Object.values(ProjectType), []);

  const projectManagers = useMemo(() => {
    const users = mapConnection(usersData?.users);
    return users.filter((usr) => usr.role === UserRole.Pm);
  }, [usersData]);

  const loading = useMemo(
    () =>
      usersLoading ||
      timeTrackingProviderLoading ||
      taskTrackingProvidersLoading ||
      !taskTrackingProjects ||
      !groupedTaskTrackingProjects,
    [
      groupedTaskTrackingProjects,
      taskTrackingProjects,
      taskTrackingProvidersLoading,
      timeTrackingProviderLoading,
      usersLoading,
    ]
  );

  return {
    data: {
      projectManagers,
      projectTypes,
      groupByOptions,
      currencies,
      languages,
      taskTrackingProviders,
      taskTrackingProjects,
      timeTrackingProjects,
      groupedTaskTrackingProjects,
    },
    state: {
      loading,
    },
  };
};
