import { useMemo } from "react";
import { Text } from "grommet";
import { ProgressBar } from "components/ProgressBar";
import { getColorByBudgetUtilization } from "helpers/dashboard";
import { dashboardTheme } from "styles";

/**
 * Budget Utilization Pointer
 *
 * expects props to be provided as ratio (0.35) instead of 35
 */

type Props = {
  min?: number | null;
  max?: number | null;
  spent: number;
};

export const BudgetUtilization = ({ min, max, spent }: Props) => {
  const maxRange = Math.max(min ?? 0, max ?? 0, spent);
  const goals = useMemo(() => {
    return [
      ...(min === 0 || min ? [{ value: (min / maxRange) * 100, label: "MIN" }] : []),
      ...(max === 0 || max ? [{ value: (max / maxRange) * 100, label: "MAX" }] : []),
    ];
  }, [maxRange, min, max]);
  let baseBudget = min;
  if (max && (!min || spent > (baseBudget ?? 0))) {
    baseBudget = max;
  }
  const budgetUtilization = +((spent / (baseBudget ?? 0)) * 100).toFixed(0);
  const color = useMemo(() => getColorByBudgetUtilization(spent, min, max), [max, min, spent]);
  return !goals.length ? null : (
    <ProgressBar
      before={
        <Text color={color} style={{ whiteSpace: "nowrap" }}>
          {isFinite(budgetUtilization) ? `${budgetUtilization} %` : "N/A"}
        </Text>
      }
      valueColor={color}
      value={budgetUtilization ?? 0}
      backgroundColor={dashboardTheme.colors.statusNeutral}
      goals={goals}
    />
  );
};
