/*
 * Remove duplicity from array
 *
 * use best performance solution
 * source: https://blog.usejournal.com/performance-of-javascript-array-ops-2690aed47a50
 * */
export const getUnique = <T>(array: T[]): T[] => {
  return array.filter((elem, pos, arr) => {
    return array.indexOf(elem) === pos;
  });
};
