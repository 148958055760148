import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import { toast } from "react-toastify";
import { Maybe } from "api";
import { reportToSentry, Severity } from "utils";

type Error = GraphQLError & {
  code: string;
  errorType: string;
};

type ErrorHandler = () => any;

export const resolveError = (
  error: ApolloError,
  handlers?: { [code: string]: ErrorHandler },
  defaultErrorHandler?: ErrorHandler
): void => {
  showErrorNotification(error);

  if (!error.graphQLErrors || !error.graphQLErrors.length) return;

  const firstError = error.graphQLErrors[0] as Error;
  const handler = handlers && handlers[firstError.message];

  if (handler) {
    handler();
    return;
  }

  if (!defaultErrorHandler) {
    handleDefaultError(firstError);
    return;
  }

  defaultErrorHandler();
};

export const handleDefaultError = (e: Error) => {
  reportToSentry(`API error caught by handleDefaultError: ${e.message || "undefined"}`, Severity.Error);
};

export const mapConnection = <T>(
  connection?: Maybe<{
    edges?: Maybe<{ cursor?: string; node?: Maybe<T> }>[];
  }>
): (T & { cursor: string })[] => {
  return connection?.edges?.flatMap((e) => (e?.node ? [{ cursor: e?.cursor || "", ...e?.node }] : [])) || [];
};

const showErrorNotification = (error: ApolloError) => {
  toast.error(error.message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: error.message, // prevent duplicity
  });
};
