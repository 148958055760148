import { useContext, useMemo } from "react";
import {
  Box,
  Button,
  CheckBox,
  FormField,
  Heading,
  RadioButtonGroup,
  ResponsiveContext,
  Select,
  TextInput,
} from "grommet";
import { Analytics, Clipboard } from "grommet-icons";
import { Controller, useForm } from "react-hook-form";
import { useUpdateEffect } from "react-use";
import { ProjectType } from "api";
import { LabelWithTooltip } from "components";
import { FormErrorText } from "components/FormErrorText";
import { TaskTrackingMultiSelector } from "containers/ProjectSettingsForm/TaskTrackingMultiSelector";
import { useFormSettingsOptions } from "containers/ProjectSettingsForm/useFormSettingsOptions";
import type { ProjectSettingsFormData } from "containers/ProjectSettingsForm/useProjectSettingsFormDataTransformer";
import { projectTypeToHumanReadable } from "helpers";
import { useSearch } from "hooks/useSearch";
import { numberValidationObj, percentValidationObj } from "utils";

type Props = {
  onSubmit: (data: ProjectSettingsFormData) => void;
  onCancel: () => void;
  data: ProjectSettingsFormData;
  options: ReturnType<typeof useFormSettingsOptions>["data"];
};

export const ProjectSettingsForm = (props: Props) => {
  const { register, control, handleSubmit, watch, errors, reset } = useForm({
    defaultValues: props.data,
  });
  // realtime form values of specified names
  const dataWatcher = watch(["pmFee", "type"]);
  // timeTrackingProvider search params
  const { search, query, clear } = useSearch();
  //set defaultValues when data changes
  useUpdateEffect(() => {
    reset(props.data);
  }, [props.data]);

  const size = useContext(ResponsiveContext);

  const responsiveFormFieldWidth = useMemo(() => {
    return size === "small" ? "100%" : "49%";
  }, [size]);

  const formTitle = useMemo(() => (props.data.code ? `[${props.data.code}] ` : "") + props.data.name, [
    props.data.code,
    props.data.name,
  ]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)} onReset={props.onCancel}>
      <Box
        background={{ color: "brand", opacity: "medium" }}
        pad={{ horizontal: "small" }}
        margin={{ bottom: "small" }}
        align={"center"}
      >
        <Heading level={3}>{formTitle}</Heading>
      </Box>
      <Box direction={"row-responsive"} wrap={true} pad={{ bottom: "large" }} justify={"between"}>
        <input ref={register} name={"project"} hidden />
        <FormField label="Název projektu" width={"100%"} required={true}>
          <TextInput name="name" ref={register} />
        </FormField>
        <FormField label="Projektový manažer" width={responsiveFormFieldWidth}>
          <Controller
            control={control}
            name={"projectManager"}
            defaultValue={""}
            render={({ value, onChange }) => {
              const user = props.options.projectManagers.find((user) => user.id === value);
              return (
                <Select
                  value={user}
                  onChange={(e) => onChange(e.value.id)}
                  options={props.options.projectManagers}
                  labelKey={(item) => {
                    return `${item?.firstName} ${item?.lastName}`;
                  }}
                />
              );
            }}
          />
        </FormField>
        <FormField label="Druh Projektu" width={responsiveFormFieldWidth}>
          <Controller
            control={control}
            name={"type"}
            defaultValue={""}
            render={({ value, onChange }) => (
              <Select
                value={value}
                onChange={(e) => onChange(e.value)}
                options={props.options.projectTypes}
                labelKey={projectTypeToHumanReadable}
              />
            )}
          />
        </FormField>

        <FormField label={"Vlastní JIRA kód"} width={responsiveFormFieldWidth}>
          <TextInput ref={register} name="taskCodeRegex" placeholder={"zadej vlastní JIRA kód..."} />
        </FormField>
        <FormField label="HARVEST Projekt" width={responsiveFormFieldWidth}>
          <Controller
            control={control}
            defaultValue={""}
            name={"timeTrackingProviderProjectId"}
            render={({ value, onChange }) => {
              const timeTrackingProvider = props.options.timeTrackingProjects.find(
                (provider) => provider.externalId === value
              );
              return (
                <Select
                  value={timeTrackingProvider}
                  onChange={(e) => onChange(e.value.externalId)}
                  options={props.options.timeTrackingProjects.filter(
                    (proj) => proj.code?.toLowerCase().includes(query) || proj.name?.toLowerCase().includes(query)
                  )}
                  labelKey={(item) => `[${item.code}] ${item.name}`}
                  onSearch={search}
                  onClose={clear}
                />
              );
            }}
          />
        </FormField>
        <FormField
          label={
            <LabelWithTooltip
              label={"Stavy dokončených issues"}
              tooltip={"Vyplňte stavy, ve kterých je issue považováno za dokončené. Jednotlivé stavy oddělte čárkou."}
            />
          }
          width={responsiveFormFieldWidth}
        >
          <TextInput ref={register} name={"taskFinalStates"} />
        </FormField>
        <FormField
          label={
            <LabelWithTooltip
              label={"Stavy issues ke zpracování"}
              tooltip={
                "Vyplňte stavy, ve kterých je issue považováno za 'ke zpracování'. Jednotlivé stavy oddělte čárkou."
              }
            />
          }
          width={responsiveFormFieldWidth}
        >
          <TextInput ref={register} name={"taskBacklogStates"} />
        </FormField>
        <TaskTrackingMultiSelector
          control={control}
          groupedTrackingProvidersProjects={props.options.groupedTaskTrackingProjects}
          taskTrackingProviders={props.options.taskTrackingProviders}
          taskTrackingProjects={props.options.taskTrackingProjects}
        />
        {errors?.taskTrackingProviderProjects && (
          <FormErrorText>Nejsou vyplnené všechny pole Task tracking projektu</FormErrorText>
        )}
      </Box>

      {/* Report Section */}
      <Box
        background={{ color: "brand", opacity: "weak" }}
        border={{ color: "brand" }}
        pad={{ horizontal: "xsmall" }}
        margin={{ bottom: "xsmall" }}
        align={"center"}
        justify={"center"}
        direction={"row"}
      >
        <Clipboard color={"text"} />
        <Heading level={4} margin={{ left: "small" }}>
          Report
        </Heading>
      </Box>
      <Box direction={"row-responsive"} wrap={true} justify={"between"} pad={{ top: "small" }}>
        <FormField name="groupBy" label="Dělit dle" width={responsiveFormFieldWidth}>
          <Controller
            control={control}
            defaultValue={""}
            name={"groupBy"}
            render={({ value, onChange }) => (
              <Select value={value} onChange={(e) => onChange(e.value)} options={props.options.groupByOptions} />
            )}
          />
        </FormField>
        <FormField name="currency" label="Měna" width={responsiveFormFieldWidth}>
          <Controller
            control={control}
            defaultValue={""}
            name="currency"
            render={({ value, onChange }) => (
              <Select value={value} onChange={(e) => onChange(e.value)} options={props.options.currencies} />
            )}
          />
        </FormField>
        <FormField width={responsiveFormFieldWidth} label="Informace o DPH/VAT">
          <Controller
            control={control}
            name="showVatInfo"
            render={({ value, onChange }) => (
              <CheckBox checked={value} onChange={(e) => onChange(e.target.checked)} toggle />
            )}
          />
        </FormField>
        <FormField label={"jazyk"} width={responsiveFormFieldWidth}>
          <Controller
            control={control}
            defaultValue={""}
            name="language"
            render={({ value, onChange }) => (
              <RadioButtonGroup
                direction={"row"}
                name={"language"}
                options={props.options.languages}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </FormField>
        <FormField width={responsiveFormFieldWidth} label="PM poplatek">
          <Controller
            control={control}
            name="pmFee"
            render={({ value, onChange }) => (
              <CheckBox checked={value} onChange={(e) => onChange(e.target.checked)} toggle />
            )}
          />
        </FormField>
        <FormField width={responsiveFormFieldWidth} label="Zobrazovat Ceny">
          <Controller
            control={control}
            name="showPrices"
            render={({ value, onChange }) => (
              <CheckBox checked={value} onChange={(e) => onChange(e.target.checked)} toggle />
            )}
          />
        </FormField>
        <FormField validate={percentValidationObj} label="PM procenta" width={responsiveFormFieldWidth}>
          <TextInput ref={register({ required: !!dataWatcher.pmFee })} name="pmPercent" disabled={!dataWatcher.pmFee} />
          {errors?.pmFee && <FormErrorText>Pole je povinné</FormErrorText>}
        </FormField>
        <FormField validate={numberValidationObj} label="PM Cena" width={responsiveFormFieldWidth}>
          <TextInput ref={register({ required: !!dataWatcher.pmFee })} name="pmPrice" disabled={!dataWatcher.pmFee} />
          {errors?.pmPrice && <FormErrorText>Pole je povinné</FormErrorText>}
        </FormField>
      </Box>

      {/* KPI Section */}
      <Box
        background={{ color: "brand", opacity: "weak" }}
        border={{ color: "brand" }}
        pad={{ horizontal: "xsmall" }}
        margin={{ bottom: "xsmall" }}
        justify={"center"}
        align={"center"}
        direction={"row"}
      >
        <Analytics color={"text"} />
        <Heading level={4} margin={{ left: "small" }}>
          KPI
        </Heading>
      </Box>
      <Box direction={"row-responsive"} wrap={true} justify={"between"} pad={{ top: "small" }}>
        <FormField
          label={
            <LabelWithTooltip
              label={"Počet hodin alokovaných na projekt"}
              tooltip={
                "Pouze pro Fixed Price projekty. Nastavení metriky Performance Index vyjadřující podíl mezi čerpáním časového budgetu a dokončeností projektu."
              }
            />
          }
          disabled={dataWatcher.type !== ProjectType.FixedPrice}
          width={responsiveFormFieldWidth}
        >
          <TextInput
            ref={register({ min: 0 })}
            name="timeAllocated"
            type={"number"}
            disabled={dataWatcher.type !== ProjectType.FixedPrice}
          />
          {errors?.timeAllocated && <FormErrorText>Pouze kladná čísla</FormErrorText>}
        </FormField>
        <FormField
          label={
            <LabelWithTooltip
              label={"Limit na operational [%]"}
              tooltip={
                "Pouze pro Time & Material projekty. Nastavení metriky Share of Operational vyjadřující podíl operational času na celkovém času odpracovaném na projektu."
              }
            />
          }
          disabled={dataWatcher.type !== ProjectType.TimeAndMaterial}
          width={responsiveFormFieldWidth}
        >
          <TextInput
            ref={register({ min: 0 })}
            type={"number"}
            name="shareOfOperationalLimit"
            disabled={dataWatcher.type !== ProjectType.TimeAndMaterial}
          />
          {errors?.shareOfOperationalLimit && <FormErrorText>Pouze kladná čísla</FormErrorText>}
        </FormField>
        <FormField
          label={
            <LabelWithTooltip
              label={"Čerpání budgetu MIN [h/měsíc]"}
              tooltip={
                "Pro Time & Material projekty. Nastavení metriky Budget Utilization zobrazující průběžné čerpání časového budgetu."
              }
            />
          }
          width={responsiveFormFieldWidth}
        >
          <TextInput
            ref={register({ min: 0 })}
            name="budgetSpentMin"
            type={"number"}
            disabled={dataWatcher.type !== ProjectType.TimeAndMaterial}
          />
          {errors?.budgetSpentMin && <FormErrorText>Pouze kladná čísla</FormErrorText>}
        </FormField>
        <FormField
          label={
            <LabelWithTooltip
              label={"Čerpání budgetu MAX [h/měsíc]"}
              tooltip={
                "Pro Time & Material a SLA projekty. Nastavení metriky Budget Utilization zobrazující průběžné čerpání časového budgetu."
              }
            />
          }
          disabled={![ProjectType.TimeAndMaterial, ProjectType.Sla].some((val) => val === dataWatcher.type)}
          width={responsiveFormFieldWidth}
        >
          <TextInput
            ref={register({
              required: [ProjectType.Sla].some((val) => val === dataWatcher.type),
              min: 0,
            })}
            type={"number"}
            name="budgetSpentMax"
            disabled={![ProjectType.TimeAndMaterial, ProjectType.Sla].some((val) => val === dataWatcher.type)}
          />
          {errors?.budgetSpentMax && <FormErrorText>Pole je povinné a jsou povoleny jen kladné hodnoty</FormErrorText>}
        </FormField>
      </Box>

      <Box direction="row-responsive" gap="medium" pad={{ top: "medium" }} justify={"between"}>
        <Box direction="row" gap="large">
          <Button type="submit" primary label="Uložit" />
          <Button type="reset" label="Zrušit" />
        </Box>
      </Box>
    </form>
  );
};
