import { useMemo } from "react";
import { useAuth, useMeQuery } from "api";

export const useMe = () => {
  const { isLoggedIn } = useAuth();

  const { data, loading } = useMeQuery({
    skip: !isLoggedIn(),
  });

  const me = useMemo(() => data?.me, [data]);

  return {
    me,
    loading,
  };
};
