import { useCallback, useMemo, useState } from "react";
import { Box, Button, DataTable, DataTableProps, Text } from "grommet";
import { Edit } from "grommet-icons";
import { useTitle } from "react-use";
import { MainContent, Spinner, TableSortingHeader } from "components";
import { ProjectSettingsDataProvider } from "containers/ProjectSettingsForm/ProjectSettingsDataProvider";
import { projectTypeToHumanReadable } from "helpers";
import { dataTableBorder } from "styles";
import { useProjectsSettings } from "./useProjectsSettings";

export const ProjectSettings = () => {
  const { data, state, handlers } = useProjectsSettings();
  useTitle("Nastavení Projektů");

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState();

  const isRowActive = useCallback((id) => activeProjectId === id && isFormVisible, [activeProjectId, isFormVisible]);

  const tableColumns = useMemo<DataTableProps["columns"]>(() => {
    return [
      {
        property: "code",
        header: <TableSortingHeader text={"Kód"} registerProperty={"code"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }} weight={isRowActive(row.id) ? "bold" : "normal"}>
            {row.code}
          </Text>
        ),
      },
      {
        property: "name",
        header: <TableSortingHeader text={"Projekt"} registerProperty={"name"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text size={"small"} weight={isRowActive(row.id) ? "bold" : "normal"}>
            {row.name}
          </Text>
        ),
      },
      {
        property: "projectManager",
        header: <TableSortingHeader text={"Pm"} registerProperty={"projectManager"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }} weight={isRowActive(row.id) ? "bold" : "normal"}>
            {row.projectManager ? `${row.projectManager.firstName} ${row.projectManager.lastName}` : "-"}
          </Text>
        ),
      },
      {
        property: "type",
        header: <TableSortingHeader text={"Druh Projektu"} registerProperty={"type"} handler={handlers.sortHandler} />,
        align: "start",
        render: (row) => (
          <Text size={"small"} style={{ whiteSpace: "nowrap" }} weight={isRowActive(row.id) ? "bold" : "normal"}>
            {row.type ? projectTypeToHumanReadable(row.type) : "-"}
          </Text>
        ),
      },
      {
        property: "actionEdit",
        header: "",
        align: "center",
        render: (row) => (
          <Button
            onClick={() => {
              setActiveProjectId(row.id);
              setIsFormVisible(true);
            }}
            icon={<Edit size={"20px"} color={isRowActive(row.id) ? "text" : undefined} />}
          />
        ),
      },
    ];
  }, [handlers, isRowActive]);
  return (
    <MainContent>
      <Box direction={"row-responsive"} gap={"small"} fill>
        <Box width={"full"} height={"100%"} overflow={"auto"}>
          {state.loading ? (
            <Spinner />
          ) : (
            <DataTable
              columns={tableColumns}
              primaryKey={"code"}
              data={[...data.sortedProjects]}
              border={dataTableBorder}
              step={99999}
              pad={"1px"}
            />
          )}
        </Box>
        {isFormVisible && !!activeProjectId && (
          <Box width={"full"} pad={{ horizontal: "medium" }} height={"100%"} overflow={"auto"}>
            <ProjectSettingsDataProvider
              projectId={activeProjectId!}
              onCancel={() => {
                setIsFormVisible(false);
                setActiveProjectId(undefined);
              }}
              onSubmit={handlers.updateProject}
            />
          </Box>
        )}
      </Box>
    </MainContent>
  );
};
