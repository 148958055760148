import { useMemo } from "react";
import * as React from "react";
import { Box, Button, CheckBox, DateInput, Select, Text } from "grommet";
import { RouteComponentProps } from "react-router-dom";
import { useTitle } from "react-use";
import styled from "styled-components";
import { ProjectBillingFieldsFragment } from "api";
import { MainContent, Spinner, Warning } from "components";
import { ProjectInvoiceReportView } from "containers";
import { dateInputGlobalProps } from "helpers";
import { ReportsParams } from "routes";
import { formatDate } from "utils";
import { useReports } from "./useReports";

interface Props extends RouteComponentProps<ReportsParams> {}

export const Reports = ({ match }: Props) => {
  const selectedProjectId = useMemo<string | undefined>(() => match.params.project, [match.params.project]);
  const { data, state, handlers } = useReports(selectedProjectId);
  useTitle("Reporty");

  const handleChangeProject = React.useCallback(
    (event) => {
      const value = event?.value?.value;
      typeof value === "string" && handlers.setSelectedProject(value);
    },
    [handlers]
  );

  const canGenerateReport = React.useMemo<boolean>(
    () => !!(!state.projectInvoiceLoading && data.project?.id && state.fromDate && state.tillDate),
    [data.project, state.fromDate, state.projectInvoiceLoading, state.tillDate]
  );

  return (
    <MainContent>
      <Box direction={"row-responsive"} gap={"small"} pad={{ vertical: "xsmall" }} height={{ min: "auto" }}>
        <Box flex={"grow"} width={{ max: "large" }}>
          <Select
            placeholder={"Vyber projekt..."}
            options={state.selectOptions}
            valueKey={"value"}
            labelKey={"label"}
            disabledKey={"disabled"}
            value={{ value: selectedProjectId }}
            onSearch={handlers.handleSearchSelect}
            onChange={handleChangeProject}
            onClose={handlers.handleCloseSelect}
            icon={state.projectsLoading ? <Spinner /> : undefined}
          />
        </Box>
        <Box width={"150"}>
          <DateInput
            {...dateInputGlobalProps}
            value={state.fromDate}
            onChange={handlers.handleChangeInputFromDate}
            buttonProps={{
              label: `Od: ${state.localizedFromDate}`,
            }}
          />
        </Box>
        <Box width={"150"}>
          <DateInput
            {...dateInputGlobalProps}
            value={state.tillDate}
            onChange={handlers.handleChangeInputTillDate}
            buttonProps={{
              label: `Do: ${state.localizedTillDate}`,
            }}
          />
        </Box>
        <Box alignSelf={"center"}>
          <CheckBox
            checked={state.onlyUnbilled}
            label={"pouze nevyfakturované"}
            onChange={handlers.handleChangeInputOnlyUnbilled}
          />
        </Box>
        <Box alignSelf={"center"}>
          <Button
            size={"small"}
            secondary
            disabled={!canGenerateReport}
            onClick={handlers.handleClickPrintReport}
            label={"Vygenerovat Report"}
          />
        </Box>
      </Box>

      <Box
        margin={{
          top: "medium",
        }}
      >
        {state.projectInvoiceLoading && <Spinner />}
        {canGenerateReport && (
          <div style={{ paddingTop: 20 }}>
            {!!data.project?.unbilledTimeEntries.totalCount && (
              <Box align={"center"} pad={{ bottom: "small" }}>
                <Warning>
                  Pozor! V minulosti jsou nevyfaturované záznamy. Jejich počet je{" "}
                  {data.project?.unbilledTimeEntries.totalCount} a nejstarší z nich je ze dne{" "}
                  {formatDate({ date: data.unbilledProjectEntries[0].spentAt })}
                </Warning>
              </Box>
            )}
            <Paper>
              <ProjectInvoiceReportView
                fromDate={state.fromDate}
                tillDate={state.tillDate}
                project={data.project as ProjectBillingFieldsFragment}
                timeEntries={data.projectEntries}
                preview={true}
                editable={true}
                showControlButtons={true}
              />
            </Paper>
          </div>
        )}
        {!selectedProjectId && (
          <Box align={"center"}>
            <Text>Vyber projekt a období k vygenerování náhledu</Text>
          </Box>
        )}
      </Box>
    </MainContent>
  );
};

const Paper = styled.div`
  background: white;
  display: block;
  margin: 0 auto;
  -webkit-print-color-adjust: exact;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5) !important;
  /* simulate print margin */
  padding: 1.5cm 1.2cm;
  /* A4 size */
  width: 25cm;
  min-height: 29.7cm;
`;
