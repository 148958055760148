import { Box, Text } from "grommet";
import { Spinner } from "components";
import { DashboardCard } from "components/Dashboard/DashboardCard";
import { PerformanceIndex, PerformanceIndexTrend } from "components/Dashboard/PerformanceIndex";
import { Workload } from "components/Dashboard/Workload/Workload";
import { WorkloadTrend } from "components/Dashboard/Workload/WorkloadTrend";
import { getColorByPerformanceIndex } from "helpers/dashboard";
import { ProjectWithMetrics } from "pages/Dashboard";
import { MetricWrapper } from "./MetricWrapper";

type Props = {
  projectMetrics: ProjectWithMetrics[];
  loading?: boolean;
};
export const FixedPrice = ({ projectMetrics, loading }: Props) => {
  return (
    <Box>
      <Text size={"medium"} margin={{ vertical: "medium" }}>
        Fixed Price
      </Text>
      {loading && <Spinner />}
      {projectMetrics &&
        projectMetrics.map((project) => (
          <DashboardCard
            key={project.id}
            label={project.name}
            margin={{ bottom: "small" }}
            color={getColorByPerformanceIndex(project.performance?.index)}
          >
            <MetricWrapper label={"Workload"}>
              <Workload fte={project.workload?.index} deviation={project.workload?.percentageChange} />
            </MetricWrapper>
            <MetricWrapper label={"Workload Trend"} width={"medium"}>
              <WorkloadTrend data={project.workload?.trend} />
            </MetricWrapper>
            <MetricWrapper label={"Performance Index"} width={"medium"} justify={"end"} pad={{ bottom: "small" }}>
              <PerformanceIndex
                index={project.performance?.index}
                budgetRatio={project.performance?.budgetRatio}
                workRatio={project.performance?.workRatio}
              />
            </MetricWrapper>
            <MetricWrapper label={"Performance Index Trend"} width={"medium"}>
              <PerformanceIndexTrend data={project.performance?.trend} />
            </MetricWrapper>
          </DashboardCard>
        ))}
    </Box>
  );
};
