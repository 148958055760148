import { DateInputProps } from "grommet/components/DateInput";
import { isValidDate } from "utils";

export const dateInputGlobalProps: DateInputProps = {
  // Don't use format by default cause it broke external set value for the date input
  // format: "dd. mm. yyyy",
  calendarProps: {
    firstDayOfWeek: 1,
    locale: "cs-CZ",
    animate: false,
  },
};

/**
 * event has same type as input at DateInputProps["onChange"]
 * */
export const getDateFromDateInputChangeEvent = (event: { target: { value: string | string[] } }): Date | undefined => {
  // TODO: Grommet has wrong TS annotation for the onChange event
  // issue: https://github.com/grommet/grommet/issues/4738
  // @ts-ignore
  const value = event.value;

  if (typeof value !== "string") {
    console.log("DateInput value is not string.", value);
    return;
  }

  if (!isValidDate(value)) return;

  return new Date(value);
};
