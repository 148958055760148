import { Box } from "grommet";
import { MainMenu } from "./MainMenu";
import { UserMenu } from "./UserMenu";

export const Navigation = () => {
  return (
    <Box fill justify={"between"} direction={"row"} align={"center"} pad={{ right: "small" }}>
      <Box flex={"shrink"}>
        <MainMenu />
      </Box>

      <Box flex={"shrink"}>
        <UserMenu />
      </Box>
    </Box>
  );
};
