import { useCallback, useMemo } from "react";
import { mapConnection, TimeEntryPingState, useTimeTrackingPingsQuery } from "api";

export const usePingOverview = () => {
  const { data, loading, error } = useTimeTrackingPingsQuery();
  const Pings = useMemo(() => mapConnection(data?.timeEntryPings), [data]);

  const GroupedPings = useMemo(() => {
    if (!data || !Pings) {
      return;
    }

    let grouped: { [worker: string]: { [timeEntry: string]: typeof Pings } } = {
      unassigned: {
        unassigned: [],
      },
    };

    let monthAgo = new Date().setMonth(new Date().getMonth() - 1);

    // group Pings by theirs timeEntries
    Pings.forEach((ping) => {
      const worker = `${ping.timeEntry?.worker?.firstName} ${ping.timeEntry?.worker?.lastName}`;
      const timeEntryId = ping.timeEntry?.id;

      // skip resolved pings older than 1 month
      if (ping.state !== TimeEntryPingState.New && monthAgo > new Date(ping.updatedAt).getTime()) {
        return;
      }
      if (!timeEntryId) {
        grouped["unassigned"]["unassigned"].push(ping);
        return;
      }
      if (!grouped.hasOwnProperty(worker)) {
        grouped = {
          ...grouped,
          [worker]: {},
        };
      }

      if (!grouped[worker].hasOwnProperty(timeEntryId)) {
        grouped = {
          ...grouped,
          [worker]: {
            ...grouped[worker],
            [timeEntryId]: [],
          },
        };
      }
      grouped[worker][timeEntryId].push(ping);
    });
    // if there's no unassigned timeEntries, delete this property
    if (!grouped.unassigned.length) {
      delete grouped.unassigned;
    }

    return grouped;
  }, [Pings, data]);

  const getTimeEntryById = useCallback(
    (id: string) => {
      if (!data) {
        return;
      }
      const pings = mapConnection(data.timeEntryPings);
      const issue = pings.find((ping) => ping.timeEntry?.id === id);

      return issue?.timeEntry;
    },
    [data]
  );

  const getMostRecentPing = useCallback((pings: typeof Pings) => {
    const sortedPings = pings.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
    return sortedPings.pop();
  }, []);

  return {
    data: {
      raw: data,
      GroupedPings,
    },
    state: {
      loading,
      error,
    },
    handlers: {
      getTimeEntryById,
      getMostRecentPing,
    },
  };
};
