import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  mapConnection,
  ProjectForBillingDetailDocument,
  resolveError,
  useProjectForBillingDetailReportQuery,
  useTriggerBillingMutation,
} from "api";
import { useStateQueryParam } from "hooks";
import { BillingsProjectDetailReportParams } from "routes";
import { DateStr, dateStrToDate, getFirstDayOfMonth, getLastDayOfMonth, isValidDate, toDateStr } from "utils";

export const useProjectBillingDetailReport = () => {
  const [period] = useStateQueryParam<DateStr>("period", toDateStr(new Date()), isValidDate);
  const { project: projectId } = useParams<BillingsProjectDetailReportParams>();

  const periodDate = useMemo(() => {
    const periodDateObj = dateStrToDate(period);

    return {
      year: periodDateObj.get("year"),
      month: periodDateObj.get("month"),
    };
  }, [period]);

  const { data, loading } = useProjectForBillingDetailReportQuery({
    variables: {
      id: projectId,
      year: periodDate.year,
      month: periodDate.month + 1,
    },
    fetchPolicy: "network-only",
    onError: resolveError,
  });

  const [triggerBilling, { loading: triggerBillingLoading }] = useTriggerBillingMutation({
    onError: resolveError,
    refetchQueries: [{ query: ProjectForBillingDetailDocument }],
    awaitRefetchQueries: true,
  });

  const project = useMemo(() => data?.project, [data]);
  const projectEntries = useMemo(() => mapConnection(project?.billing?.timeEntries), [project]);
  const fromDate = getFirstDayOfMonth(dateStrToDate(period).toDate());
  const tillDate = getLastDayOfMonth(dateStrToDate(period).toDate());

  return {
    data: {
      project,
      projectEntries,
    },
    state: { loading, fromDate, tillDate, triggerBillingLoading },
    handlers: {
      triggerBilling,
    },
  };
};
