import { useRef, useState } from "react";
import * as React from "react";
import { Box, BoxTypes, Drop } from "grommet";

type TooltipsProps = BoxTypes & {
  content: React.ReactNode;
  children: React.ReactNode;
  active: boolean;
};

export const Tooltip = ({ content, children, active, ...rest }: TooltipsProps) => {
  const [over, setOver] = useState(false);
  const ref = useRef<any>();

  const show = React.useCallback(() => active && setOver(true), [active]);
  const hide = React.useCallback(() => active && setOver(false), [active]);

  return (
    <Box>
      <Box ref={ref} onMouseOver={show} onMouseLeave={hide} {...rest}>
        {children}
      </Box>
      {active && ref.current && over && (
        <Drop align={{ bottom: "top" }} target={ref.current} plain trapFocus={false}>
          <Box pad="small" background={"light-3"}>
            {content}
          </Box>
        </Drop>
      )}
    </Box>
  );
};
