import { useCallback, useEffect, useMemo, useState } from "react";
import {
  mapConnection,
  resolveError,
  TasksForProjectReportQuery,
  TaskStatus,
  useProjectForDeviationReportQuery,
  useTasksForProjectReportQuery,
} from "api";
import { getTasksStats } from "helpers";
import { useStateQueryParam } from "hooks";
import { DateStr, isValidDate } from "utils";

export type TaskForProjectReportNode = NonNullable<TasksForProjectReportQuery["tasks"]>["edges"][0]["node"];

export const useProjectReport = (projectId?: string) => {
  const [fromDate] = useStateQueryParam<DateStr>("fromDate", undefined, (v) => (v ? isValidDate(v) : false));
  const [tillDate] = useStateQueryParam<DateStr>("tillDate", undefined, (v) => (v ? isValidDate(v) : false));
  const [billable, setBillable] = useStateQueryParam<boolean>("billable", true);

  const { data: project, loading: projectLoading } = useProjectForDeviationReportQuery({
    variables: {
      id: projectId || "",
    },
    skip: !projectId,
  });

  /*
   * TASKS
   * */
  const { data: tasksData, loading: tasksLoading } = useTasksForProjectReportQuery({
    variables: {
      project: projectId || null,
      fromDate: fromDate,
      tillDate: tillDate,
      billable,
    },
    onError: resolveError,
    fetchPolicy: "network-only",
  });

  const tasks = useMemo<TaskForProjectReportNode[]>(() => mapConnection(tasksData?.tasks), [tasksData]);

  const [tasksInProgress, setTasksInProgress] = useState<TaskForProjectReportNode[]>([]);
  const [tasksPending, setTasksPending] = useState<TaskForProjectReportNode[]>([]);
  const [tasksDone, setTasksDone] = useState<TaskForProjectReportNode[]>([]);

  /*
   * Split tasks to the groups
   *
   * */
  useEffect(() => {
    const inProgress: TaskForProjectReportNode[] = [];
    const pending: TaskForProjectReportNode[] = [];
    const done: TaskForProjectReportNode[] = [];

    for (let task of tasks) {
      if (task.reportStatus === TaskStatus.Backlog) {
        pending.push(task);
        continue;
      }

      if (task.reportStatus === TaskStatus.Done) {
        done.push(task);
        continue;
      }

      if (task.reportStatus === TaskStatus.InProgress) {
        inProgress.push(task);
        continue;
      }

      throw new Error(`Task ${task.code} doesn't fit in any category in deviations report. Something went wrong.`);
    }

    setTasksInProgress(inProgress);
    setTasksPending(pending);
    setTasksDone(done);
  }, [fromDate, tasks]);

  const statsInProgress = useMemo(() => getTasksStats(tasksInProgress), [tasksInProgress]);
  const statsPending = useMemo(() => getTasksStats(tasksPending), [tasksPending]);
  const statsDone = useMemo(() => getTasksStats(tasksDone), [tasksDone]);

  const handleClickCheckboxBillable = useCallback(() => setBillable(!billable), [billable, setBillable]);

  return {
    state: {
      loading: projectLoading || tasksLoading,
      billable,
    },
    data: {
      tasksInProgress,
      tasksPending,
      tasksDone,

      statsInProgress,
      statsPending,
      statsDone,

      project: project?.project,
    },
    handlers: {
      handleClickCheckboxBillable,
    },
  };
};
