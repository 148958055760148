import { DashboardStatus } from "pages/Dashboard";

export const dashboardTheme = {
  colors: {
    background: "#F2F2F2",
    backgroundContrast: "#DADADA",
    text: "#777777",
    statusOk: "#00C781",
    statusWarning: "#FFAA15",
    statusDanger: "#FF4040",
    statusNeutral: "#CCCCCC",
    statusSecondaryOk: "rgba(0,199,129,0.5)",
    statusSecondaryWarning: "rgba(255,170,21,0.5)",
    statusSecondaryDanger: "rgba(255,64,64,0.5)",
    statusSecondaryNeutral: "rgba(204,204,204,0.5)",
  },
};

/**
 * Helpers
 */
const { colors } = dashboardTheme;
export const DashboardColor: Record<DashboardStatus, string> = {
  [DashboardStatus.OK]: colors.statusOk,
  [DashboardStatus.WARNING]: colors.statusWarning,
  [DashboardStatus.DANGER]: colors.statusDanger,
  [DashboardStatus.NEUTRAL]: colors.statusNeutral,
};

export const DashboardSupportColor: Record<DashboardStatus, string> = {
  [DashboardStatus.OK]: colors.statusSecondaryOk,
  [DashboardStatus.WARNING]: colors.statusSecondaryWarning,
  [DashboardStatus.DANGER]: colors.statusSecondaryDanger,
  [DashboardStatus.NEUTRAL]: colors.statusSecondaryNeutral,
};
