import { ReactNode } from "react";
import { createPortal } from "react-dom";

type ModalProps = {
  children: ReactNode;
};

export const Modal = (props: ModalProps) => {
  const modalContainer = document.getElementById("modal_root");
  return modalContainer ? createPortal(props.children, modalContainer) : null;
};
