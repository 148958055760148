import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import introspectionResult from "api/generated";

export const cache = new InMemoryCache({
  /*
   * possibleTypes are generated by fragment-matcher
   * doc: https://graphql-code-generator.com/docs/plugins/fragment-matcher#usage-with-apollo-client-3
   * */
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        tasks: relayStylePagination(["project", "search", "parentTasksOnly", "fromDate", "toDate", "orderBy"]),
        timeEntries: relayStylePagination(["project", "new", "tillDate"]),
      },
    },
    Project: {
      fields: {
        /*
         * merge timeEntries data when timeEntry has no ID
         *
         * typically when you call:
         * timeEntries(new: true) {
         *     forApprovalCount
         *     totalCount
         * }
         * */
        timeEntries: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
});
