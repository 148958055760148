import * as React from "react";
import { Box } from "grommet";

type Props = {
  size?: "small" | "default";
};

export const Spinner: React.FC<Props> = ({ size = "default" }) => (
  <Box align={"center"} justify={"center"}>
    <svg version="1.1" viewBox="0 0 32 32" width={SpinnerSize[size]} height={SpinnerSize[size]} fill="#ffe014">
      <path opacity=".25" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4" />
      <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
        <animateTransform
          attributeName="transform"
          data-testid="transform"
          type="rotate"
          from="0 16 16"
          to="360 16 16"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </Box>
);

type TSpinnerSize = {
  [key in NonNullable<Props["size"]>]: string | number;
};

const SpinnerSize: TSpinnerSize = {
  small: "14px",
  default: "28px",
};
