import { useMemo, useRef, useEffect } from "react";
import { mapConnection, TimeEntry } from "api";

export const useTimeEntryVersions = (timeEntryVersions: TimeEntry["versions"]) => {
  const versions = useMemo(() => mapConnection(timeEntryVersions), [timeEntryVersions]);
  const hasDiff = useMemo(() => versions.length > 0, [versions]);

  let notesDiff = useRef(false);
  let taskDiff = useRef(false);
  let timeDiff = useRef(false);

  useEffect(() => {
    if (!hasDiff) {
      return;
    }

    for (let versionA of versions) {
      for (let versionB of versions) {
        if (versionA.notes !== versionB.notes) {
          notesDiff.current = true;
        }
        if (versionA.task !== versionB.task) {
          taskDiff.current = true;
        }
        if (versionA.seconds !== versionB.seconds) {
          timeDiff.current = true;
        }
      }
    }
  }, [hasDiff, versions]);

  return {
    diff: {
      notes: notesDiff.current,
      task: taskDiff.current,
      time: timeDiff.current,
    },
    hasDiff,
  };
};
