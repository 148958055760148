import { ComponentType, createElement } from "react";
import { Box, Button, DropButton } from "grommet";
import { CaretDown, Checkmark, Close, Schedule } from "grommet-icons";
import { IconProps } from "grommet-icons";
import { Billable } from "api";
import { transformEnumToArray } from "helpers";

type BillableIndicatorProps = {
  state: Billable;
  onChange: (newState: Billable) => void;
  disabled?: boolean;
};

const BillableIconMapper: Record<Billable, { des: string; icon: ComponentType<IconProps>; nextState: Billable }> = {
  [Billable.Yes]: { icon: Checkmark, des: "Bude fakturováno", nextState: Billable.NextTime },
  [Billable.No]: { icon: Close, des: "Nebude nikdy fakturováno", nextState: Billable.Yes },
  [Billable.NextTime]: { icon: Schedule, des: "Bude fakturováno odloženě", nextState: Billable.No },
};

export const BillableIndicator = ({ state, onChange, disabled }: BillableIndicatorProps) => {
  const handleClick = () => {
    onChange(BillableIconMapper[state].nextState);
  };

  return (
    <Box direction="row">
      <Button
        icon={createElement(BillableIconMapper[state].icon, { size: "small" })}
        a11yTitle={BillableIconMapper[state].des}
        onClick={handleClick}
        plain={false}
        disabled={disabled}
      />
    </Box>
  );
};

type BillableSelectorProps = {
  onChange: (newState: Billable) => void;
  disabled?: boolean;
};
export const BillableSelector = ({ onChange, disabled }: BillableSelectorProps) => {
  return (
    <Box direction="row">
      <DropButton
        plain={false}
        icon={<CaretDown size={"small"} />}
        dropAlign={{ top: "bottom" }}
        disabled={disabled}
        dropContent={
          <Box background={"light-2"} pad={"small"}>
            {transformEnumToArray(Billable).map((billableState) => (
              <BillableIndicator key={billableState} state={billableState} onChange={() => onChange(billableState)} />
            ))}
          </Box>
        }
      />
    </Box>
  );
};
