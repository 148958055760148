import { useMemo } from "react";
import { Box, Text } from "grommet";
import { Alert as IconAlert, StatusGood as IconApproved, Radial as IconAwaitsApproval, IconProps } from "grommet-icons";

interface Props {
  forApproval: number;
  isApproved?: boolean;
  showZeros?: boolean;
  iconSize?: IconProps["size"];
}

export const ProjectStatusIndicator = ({ forApproval, iconSize, showZeros, isApproved }: Props) => {
  const icon = useMemo(
    () =>
      forApproval > 0 ? (
        <IconAlert size={iconSize} color={"status-warning"} />
      ) : isApproved ? (
        <IconApproved size={iconSize} color={"status-ok"} />
      ) : (
        <IconAwaitsApproval size={iconSize} color={"status-unknown"} />
      ),
    [isApproved, iconSize, forApproval]
  );

  return (
    <Box direction={"row"} gap={"xsmall"} justify={"center"} alignContent={"center"}>
      <Box justify={"center"} alignContent={"center"} margin={{ right: "small" }}>
        <Text size={"small"}>{forApproval === 0 && !showZeros ? null : forApproval}</Text>
      </Box>
      <Box justify={"center"} alignContent={"center"}>
        {icon}
      </Box>
    </Box>
  );
};
