import { useMemo } from "react";
import { Currency, GroupBy, Language, ProjectBillingFieldsFragment, TimeEntriesBillingFieldsFragment } from "api";
import { Spinner } from "components";
import { InvoiceReportTemplate } from "containers/InvoiceReportTemplate";
import { useProjectInvoiceData } from "hooks";
import { DateStr, toDateStr } from "utils";

type Props = {
  project: ProjectBillingFieldsFragment;
  timeEntries: TimeEntriesBillingFieldsFragment[];
  fromDate?: DateStr;
  tillDate?: DateStr;
  preview?: boolean; // if false, shows only billable entries
  editable?: boolean; // if there can be changes made
  showControlButtons?: boolean; // whether or not show buttons for editing
  showDate?: boolean;
};
export const ProjectInvoiceReportView = (props: Props) => {
  const { data, handlers, helpers } = useProjectInvoiceData({
    project: props.project,
    projectTimeEntries: props.timeEntries,
  });

  const oldestTimeEntryDate = helpers.getOldestTimeEntryDate();
  const { editable = true, showDate = true } = props; //default values

  const fromDate = useMemo(() => {
    const date = props.fromDate ?? toDateStr(new Date());
    if (!oldestTimeEntryDate) {
      return date;
    }

    return date < oldestTimeEntryDate ? date : oldestTimeEntryDate;
  }, [oldestTimeEntryDate, props.fromDate]);

  return !props.project ? (
    <Spinner />
  ) : (
    <InvoiceReportTemplate
      data={props.preview ? data.InvoicePreviewData : data.InvoiceData}
      groupedBy={props.project.groupBy ?? GroupBy.Task}
      language={props.project.language ?? Language.Cs}
      showPrices={props.project.showPrices}
      showVatInfo={props.project.showVatInfo}
      projectName={props.project.name}
      fromDate={fromDate}
      tillDate={props.tillDate}
      pmPercent={props.project.pmPercent ?? 0}
      currency={props.project.currency ?? Currency.Czk}
      editable={editable}
      showControlButtons={props.showControlButtons}
      showDate={showDate}
      onTimeEntryUpdate={handlers.updateTimeEntry}
      onTimeEntriesUpdate={handlers.updateTimeEntries}
      onTimeEntryReset={handlers.resetTimeEntry}
      onTimeEntriesReset={handlers.resetTimeEntries}
    />
  );
};
