import { onError } from "@apollo/client/link/error";
import * as storage from "../storage";

const ERROR_DECODING_SIGNATURE = "Error decoding signature";

export const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.message !== ERROR_DECODING_SIGNATURE) continue;

      logout();
    }
  }
});

const logout = () => storage.clearAuthToken();
