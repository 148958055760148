import { BillingState, BillingTimelineEventState } from "api";

export const getBillingStateColor = (
  billingState?: BillingState | BillingTimelineEventState,
  defaultColor?: string
) => {
  if (billingState === BillingState.Invoice) {
    return "status-ok";
  }
  if (billingState === BillingState.NoInvoice) {
    return "status-error";
  }
  if (billingState === BillingState.InvoiceNextTime) {
    return "status-warning";
  }

  return defaultColor ?? "brand";
};
